<template>
  <div class="mt-10">
    <template v-if="billing.hasSubscription()">
      <p
        v-html="$trans('You are currently subscribed to <strong>{title} {type}</strong>.', {
          title: $app.appTitle,
          type: billingInfo.subscriptionType,
        })"
      ></p>
      <v-simple-table>
        <tbody>
          <tr>
            <th><span v-html="$trans('Subscription start date')"></span></th>
            <th><span v-html="$trans('Subscription end date')"></span></th>
          </tr>
          <tr v-for="(item, i) in billing.items" :key="i">
            <td><p class="mb-0" v-html="item.started"></p></td>
            <td><p class="mb-0" v-html="item.expired"></p></td>
          </tr>
        </tbody>
      </v-simple-table>
      <portal-target name="profile:stacked"></portal-target>
    </template>
    <template v-else>
      <billing-overview-tab-item-empty-state></billing-overview-tab-item-empty-state>
    </template>
  </div>
</template>

<script>
import Billing from '@/modules/Billing/resources/Billing';

export default {
  name: 'BillingOverviewTabItem',

  data: () => ({
    billing: new Billing,
  }),

  computed: {
    billingInfo () {
      return this.billing.meta.billingInfo;
    },
  },

  created () {
    this.billing.listBillingInfo();
  },
};
</script>
