import DataResource from '@core/resources/DataResource';
import buckets from '@/modules/Image/config/buckets';
import META_FILTERS, { items } from '@/modules/Search/config/filters';
import { SEARCH_IMAGES_URL, FIND_IMAGE_URL } from '@/modules/Image/api/images';
import { supportedQuery } from '@/modules/Image/config/queries';
import {
  values, pickBy, isEmpty,
  kebabCase, includes,
  groupBy, flatten,
  isNil, uniq, sortBy,
} from 'lodash';

export default class Image extends DataResource {
  constructor (options = {}) {
    super(options);

    this.setOptions({
      itemsPerPage: 300,
      ...options.options,
    });

    this.setMeta({
      buckets,
      supportedQuery,
      itemsPerRow: 6,
      showSelect: true,
      mobileBreakpoint: 0,
      hideDefaultFooter: true,
      ...options.meta,
    });
  }

  async listMetaBuckets () {
    const params = this.getMetaBucketQueryString();
    const { data } = await this.axios.get(SEARCH_IMAGES_URL, { params });

    this.setMetaBuckets(data.data?.aggregations?.img_tag_histo?.buckets);
    this.saveMetaBucketsToStore();
  }

  async listInitial () {
    this.unsetActiveBucket();
    this.setQueryString({ ...this.query, ...this.options });

    const params = this.getInitialQueryString();
    const { data } = await this.axios.get(SEARCH_IMAGES_URL, { params });

    this.setItems(data.data.search_results);

    this.setMetaBuckets(data.data?.aggregations?.img_tag_histo?.buckets);
    this.saveMetaBucketsToStore();
    this.selectDefaultMetaBucket();

    this.setPagination({
      total: data.data.total_results,
      perPage: this.options.itemsPerPage,
      itemsPerPage: this.options.itemsPerPage,
    });
  }

  async list () {
    this.startLoading();
    this.unsetItems();

    await this.listMetaBuckets();

    if (this.isSearching()) {
      this.unsetActiveBucket();
      this.setActiveBucketToQueryString(this.getCurrentOrDefaultBucket());
    }

    this.setActiveBucket(this.getCurrentOrDefaultBucket());

    const params = this.getDefaultQueryString();
    const { data } = await this.axios.get(SEARCH_IMAGES_URL, { params, ...this.getCancelToken() });

    this.setItems(data.data.search_results);

    this.setPagination({
      total: data.data.total_results,
      perPage: this.options.itemsPerPage,
      itemsPerPage: this.options.itemsPerPage,
    });

    this.stopLoading();
  }

  async listByActiveBucket () {
    this.unsetItems();
    this.startLoading();

    const params = this.getDefaultQueryString();
    const { data } = await this.axios.get(SEARCH_IMAGES_URL, { params, ...this.getCancelToken() });

    this.setItems(data.data.search_results);

    this.setPagination({
      total: data.data.total_results,
    });

    this.stopLoading();
  }

  transformQueryString (query) {
    if (query == null) {
      return query;
    }
    let updatedQuery = '';
    // reparse the query object to do the following
    // every word in the searchbar query except AND/OR will have doc_text
    // example: Figure AND gradient => doc_text:Figure AND doc_text:gradient
    const queryArr = query.split(' ');
    if ((query.indexOf('\'') === -1 && query.indexOf('"') === -1)) {
      queryArr.forEach(item => {
        if (item === 'AND' || item === 'OR') {
          updatedQuery += `${item} `;
        } else {
          updatedQuery += `doc_text:${item} `;
        }
      });
    } else {
      if((query.indexOf(" AND ") > -1 || query.indexOf(" OR ") > -1)){
        let count = 1;
        queryArr.forEach(item => {
          if (item === 'AND' || item === 'OR') {
            updatedQuery += `${item} `;
            count = 1;
          } else {
            if (count == 1){
              updatedQuery += `doc_text_wostem:${item} `;
              count -= 1;
            }else{
              updatedQuery += `${item} `;
              count += 1;
            }
          }
        });
      }else{
        updatedQuery = `doc_text_wostem:${query}`;
      }

    }
    return updatedQuery;
  }

  getInitialQueryString () {
    return this.makeImageSearchParameters({
      q: this.buildQuerySearchParameters({ search: this.query.q }),
      project_id: this.getProjectIds(),
      img_tag_1: this.route.query.bucket,
    });
  }

  getMetaBucketQueryString () {
    const updatedQuery = this.transformQueryString(this.query.q);

    return this.makeImageSearchParameters({
      q: this.query.q ? `(${updatedQuery})` : null,
      project_id: this.getProjectIds(),
    }, false);
  }

  getDefaultQueryString () {
    return this.makeImageSearchParameters({
      q: this.buildQuerySearchParameters({ q: this.query.q }),
      project_id: this.getProjectIds(),
      img_tag_1: this.meta.buckets.getActiveProperty('text'),
    });
  }

  setMetaBuckets (items = []) {
    this.meta.buckets.setItems(items
      .filter(item => this.isSupportedBucket(item.key))
      .map(item => ({
        id: kebabCase(item.key),
        text: item.key,
        value: item.doc_count,
      })));
  }

  setMetaBucketToDefault () {
    return this.setActiveBucket(this.getCurrentOrDefaultBucket());
  }

  saveMetaBucketsToStore () {
    this.store.dispatch('image/buckets', this.meta.buckets.items);
  }

  setActiveBucketToQueryString (bucket) {
    return this.setActiveBucket(bucket).setQueryString({ bucket: bucket?.id });
  }

  setActiveBucket (bucket) {
    this.meta.buckets.setActive(bucket);

    return this;
  }

  getCurrentOrDefaultBucket () {
    return this.meta.buckets.find(this.query.bucket)
      ?? this.meta.buckets.getActive()
      ?? this.meta.buckets.getFirstBucket();
  }

  getActiveBucket () {
    return this.meta.buckets.getActive();
  }

  async listMore () {
    this.startLoading();

    const perPage = parseInt(this.pagination.perPage, 10);
    const pageFrom = parseInt(this.pagination.itemsPerPage, 10) + perPage;

    this.setQueryString({ page_from: pageFrom });
    await this.pushRouteQuery();

    const params = {
      ...this.makeImageSearchParameters({
        q: this.query.q,
        project_id: this.getProjectIds(),
        img_tag_1: this.getMetaBucketText(),
      }),
      page_from: pageFrom,
    };

    const { data } = await this.axios.get(SEARCH_IMAGES_URL, { params, ...this.getCancelToken() });

    this.items.concat(data.data.search_results);

    this.setPagination({
      total: data.data.total_results,
      itemsPerPage: pageFrom,
    });

    this.stopLoading();
  }

  async listRelatedImages () {
    this.meta.loading = true;

    const params = this.makeImageSearchParameters({
      q: this.query.q || this.route.query.q,
      project_id: this.getProjectIds(),
      img_tag_1: this.getMetaBucketText(),
    });

    const { data } = await this.axios.get(SEARCH_IMAGES_URL, { params });

    this.setRelatedImages(data.data.search_results);

    this.setPagination({
      total: data.data.total_results,
      perPage: this.options.itemsPerPage,
      itemsPerPage: this.options.itemsPerPage,
    });

    this.meta.loading = false;
  }

  async listMoreRelatedImages () {
    this.meta.loading = true;

    const perPage = parseInt(this.pagination.perPage, 10) || 10;
    const pageFrom = parseInt(this.pagination.itemsPerPage, 10) + perPage;

    const params = {
      ...this.makeImageSearchParameters({
        q: this.query.q,
        project_id: this.getProjectIds(),
        img_tag_1: this.getMetaBucketText(),
      }),
      page_from: pageFrom,
    };

    const { data } = await this.axios.get(SEARCH_IMAGES_URL, { params });

    this.meta.relatedImages.concat(data.data.search_results);

    this.setPagination({
      total: data.data.total_results,
      itemsPerPage: pageFrom,
    });

    this.meta.loading = false;
  }

  async initialByDocRef () {
    this.startLoading();

    const { docRef } = this.meta;
    const params = this.makeImageSearchParameters({
      doc_ref: docRef,
      img_tag_1: this.getMetaBucketText(),
    });

    const { data } = await this.axios.get(SEARCH_IMAGES_URL, { params });

    this.setItems(data.data.search_results);
    this.mergeOptions({ total: data.data.total_results });
    this.setMetaBuckets(data.data?.aggregations?.img_tag_histo?.buckets);
    this.saveMetaBucketsToStore();

    this.setPagination({
      total: data.data.total_results,
      perPage: this.options.itemsPerPage,
      itemsPerPage: this.options.itemsPerPage,
    });

    this.stopLoading();
  }

  async initialByDocRefWithoutImgTag () {
    this.startLoading();

    const { docRef } = this.meta;
    const params = this.makeImageSearchParameters({
      doc_ref: docRef,
      img_tag_1: null,
    });

    const { data } = await this.axios.get(SEARCH_IMAGES_URL, { params });

    this.setItems(data.data.search_results);
    this.mergeOptions({ total: data.data.total_results });
    this.setMetaBuckets(data.data?.aggregations?.img_tag_histo?.buckets);
    this.saveMetaBucketsToStore();

    this.setPagination({
      total: data.data.total_results,
      perPage: this.options.itemsPerPage,
      itemsPerPage: this.options.itemsPerPage,
    });

    this.stopLoading();
  }

  async listByDocRef () {
    this.startLoading();

    const { docRef } = this.meta;
    const params = this.makeImageSearchParameters({
      doc_ref: docRef,
      img_tag_1: this.getMetaBucketText(),
    });

    const { data } = await this.axios.get(SEARCH_IMAGES_URL, { params });

    this.setItems(data.data.search_results);

    this.setPagination({
      total: data.data.total_results,
    });

    this.stopLoading();
  }

  async listByDocRefWithoutImgTag () {
    this.startLoading();

    const { docRef } = this.meta;
    const params = this.makeImageSearchParameters({
      doc_ref: docRef,
      img_tag_1: null,
    });

    const { data } = await this.axios.get(SEARCH_IMAGES_URL, { params });

    this.setItems(data.data.search_results);

    this.setPagination({
      total: data.data.total_results,
    });

    this.stopLoading();
  }

  async listRelatedImagesByDocRef () {
    this.meta.loading = true;

    const { docRef } = this.meta;
    const params = this.makeImageSearchParameters({
      doc_ref: docRef,
      img_tag_1: this.getMetaBucketText(),
    });

    const { data } = await this.axios.get(SEARCH_IMAGES_URL, { params });

    this.setRelatedImages(data.data.search_results);

    this.setPagination({
      perPage: 10,
      total: data.data.total_results,
      itemsPerPage: this.options.itemsPerPage,
    });

    this.meta.loading = false;
  }

  async listMoreRelatedImagesByDocRef () {
    this.meta.loading = true;

    const perPage = parseInt(this.pagination.perPage, 10) || 10;
    const pageFrom = parseInt(this.pagination.itemsPerPage, 10) + perPage;

    const { docRef } = this.meta;
    const params = {
      ...this.makeImageSearchParameters({
        doc_ref: docRef,
        img_tag_1: this.getMetaBucketText(),
      }),
      page_from: pageFrom,
    };

    const { data } = await this.axios.get(SEARCH_IMAGES_URL, { params });

    this.meta.relatedImages.concat(data.data.search_results);

    this.setPagination({
      total: data.data.total_results,
      itemsPerPage: pageFrom,
    });

    this.meta.loading = false;
  }

  async listMoreByDocRef () {
    this.startLoading();

    const perPage = parseInt(this.pagination.perPage, 10);
    const pageFrom = parseInt(this.pagination.itemsPerPage, 10) + perPage;

    const { docRef } = this.meta;
    const params = this.makeImageSearchParameters({
      doc_ref: docRef,
      page_from: pageFrom,
      img_tag_1: this.getMetaBucketText(),
    });

    const { data } = await this.axios.get(SEARCH_IMAGES_URL, { params });

    this.items.concat(data.data.search_results);

    this.setPagination({
      itemsPerPage: pageFrom,
    });

    this.stopLoading();
  }

  async find (id) {
    this.startLoading();

    try {
      const imgData = await this.axios.get(FIND_IMAGE_URL(id, this.route.query.project_id));
      this.setData(this.makeItem(imgData.data.data.search_results[0]));
    } catch (error) {
      console.log('Image does not exist');
    }

    this.stopLoading();
  }

  setData (data) {
    this.data = this.observeData({ ...data, ...data.attributes });
  }

  makeImageSearchParameters (params = {}, withImgTag = true) {
    const routeQuery = { ...this.getRouteQuery(), ...params };

    return {
      q: `(${values(Object.fromEntries(Object.entries(pickBy({
        q: routeQuery.q ? `doc_text:${routeQuery.q}` : null,
        content_type: routeQuery.content_type || 'nontext',
        img_tag_1: withImgTag ? routeQuery.img_tag_1 : null,
        project_id: this.getProjectIds(),
        ...params,
      }, v => !isNil(v))).map(
        ([ k, v ]) => [ k, k === 'q' ? v : (k === 'img_tag_1' ? `${k}:"${v}"` : `${k}:${v}`) ],
      ))).join(') AND (').replace('()', '')})`,
      page_size: this.options.itemsPerPage,
      page_from: routeQuery.page_from || 0,
    };
  }

  setRelatedImages (items) {
    this.meta.relatedImages = items.map(item => this.makeItem(item));
  }

  isSupportedBucket (key) {
    return !includes(this.meta.unsupportedBuckets, key);
  }

  unsetActiveBucket () {
    this.meta.buckets.unsetActive();
    this.setQueryString({
      ...this.query,
      bucket: '',
    });

    return this;
  }

  setDefaultMetaBucket (bucket) {
    const bucketIndex = this.meta.buckets.findIndex(i => i.id === bucket?.id);

    if (bucketIndex < 0) {
      return this.selectDefaultMetaBucket();
    }

    this.meta.bucket = bucket;
    this.meta.selectedBucketTab = bucketIndex;

    this.store.dispatch('image/setCurrentBucket', this.meta.bucket.text);
    this.store.dispatch('image/setBucket', this.meta.bucket);

    return this;
  }

  selectDefaultMetaBucket () {
    return this.setActiveBucket(this.meta.buckets.getFirstBucket());
  }

  getProjectIds () {
    return this.store.getters['sourcetray/sources']?.map(i => i.id).join(',');
  }

  getProjectIdsUnjoined () {
    return this.store.getters['sourcetray/sources']?.map(i => i.id);
  }

  getMetaBucketText () {
    const bucket = this.getCurrentOrDefaultBucket();

    return bucket?.text;
  }

  getImgTagThatExistsInMeta (bucket) {
    const imgTag1 = this.meta.buckets.find(bucket => bucket.id === bucket?.id);

    if (isEmpty(bucket)) {
      return bucket;
    }

    if (isEmpty(imgTag1)) {
      return this.meta.buckets[0];
    }

    return bucket;
  }

  async downloadImage (item) {
    try {
      item = item || this.data;
      const { data } = await this.axios.get(item.attributes.download_url);
      window.open(data.data.raw_file_url);
    } catch ({ response }) {
      const { message } = response.data.errors;
      this.dialog('error', {
        color: 'accent',
        persistent: true,
        illustration: () => import('@/components/Icons/IconListOneRowError'),
        illustrationHeight: 200,
        title: 'Download Limit Reached',
        text: message,
      });
    }
  }

  moreToLoad () {
    return !this.isEmpty() && this.size() < this.pagination.total;
  }

  moreToLoadRelatedImages () {
    return this.meta.relatedImages.length < this.pagination.total;
  }

  buildQuerySearchParameters (params = {}) {
    const routeQuery = { ...this.getRouteQuery(), ...params };
    const query = routeQuery.q;
    const filters = this.buildSearchFilters();
    const updatedQuery = this.transformQueryString(query);
    const returnVal = [ routeQuery.search, query ? `(${updatedQuery})` : null, `(${filters})` ]
      .filter(i => !isEmpty(i))
      .join(' AND ').replace('() AND', '');

    return returnVal;
  }

  buildSearchFilters () {
    const { delimiter } = META_FILTERS;
    const selected = flatten(items.map(i => i.chips.map(c => c.value)));
    const filters = values(groupBy(flatten(selected.map(filter => filter.split(delimiter)))
      .map(i => ({ key: i.split(':')[0], value: i })), 'key'))
      .map(i => i.map(j => j.value).join(' OR ')).join(') AND (');

    return `(${filters})`;
  }

  setQueryString (options) {
    const supportedQuery = this.parseOptionsAsSupportedQuery(options);

    this.query = {
      ...this.getQueryString(),
      ...supportedQuery,
    };

    this.pushRouteQuery();
    this.cleanQuery();

    return this;
  }

  mergeOptions (options = {}, withRowAll = true) {
    const routeQuery = this.getRouteQueryAsOptions();
    const itemsPerPage = parseInt(routeQuery.itemsPerPage || options.itemsPerPage || 10, 10);
    const total = this.pagination.total || '-1';
    const rowAll = withRowAll ? { text: 'All', value: total } : 5;

    this.options = {
      ...this.options,
      ...options,
      itemsPerPage,
      q: routeQuery.q,
      mustSort: options.mustSort,
      multiSort: options.multiSort,
      sortBy: options.sortBy || [],
      groupBy: options.groupBy || [],
      sortDesc: options.sortDesc || [],
      groupDesc: options.groupDesc || [],
      page: options.page || this.pagination.page || 1,
      rowsPerPage: uniq(sortBy([
        itemsPerPage, 5, 10, 15, 20, 50, 100, rowAll,
      ])),
    };

    return this;
  }

  setItems (items) {
    this.items = items.map(item => this.makeItem(item));
  }

  makeItem (item) {
    return {
      ...item,
      data_index: item?.attributes?.data_index_info?.[0],
    };
  }
}
