import DataResource from '@core/resources/DataResource';
import tabs from '@/modules/Billing/config/tabs';
import moment from 'moment';
import {
  GET_BILLING_SUBSCRIPTION_URL,
  GET_BILLING_GENERATE_UPGRADE_CODE_URL,
  GET_BILLING_UPGRADE_SUBSCRIPTION_URL,
  GET_BILLING_PAYMENT_METHODS_URL,
  GET_BILLING_PAYMENT_CHECKOUT_SESSION_URL,
  GET_BILLING_PAYMENT_INTENT_URL,
  POST_BILLING_PAYMENT_INTENT_URL,
} from '@/modules/Billing/api/billing';
import {
  startCase, isEmpty, uniqBy, capitalize,
  has,
  isString,
  isArray,
} from 'lodash';
import { UPGRADABLE_SUBSCRIPTIONS } from '@/modules/Billing/config/subscriptions';
import {
  BILLING_STATUS_ACTIVE,
  BILLING_STATUS_FOR_CANCELLATION,
  BILLING_STATUS_REQUIRES_PAYMENT_METHOD,
  BILLING_STATUS_SUCCEEDED,
} from '@/modules/Billing/enums/BillingStatus';
import { ACCOUNT_TYPES_LEVELS, ACCOUNT_TYPE_PAID_SUBSCRIPTION } from '@/modules/Billing/enums/AccountTypes';

export default class Billing extends DataResource {
  constructor (attributes) {
    super(attributes);

    this.setMeta({
      tabs: this.setTabs(tabs),
      billingInfo: {},
      user: this.getUserFromStore(),
      currentPaymentMethod: {
        card: null,
      },
    });

    this.setFields({
      upgrade: {
        upgradeCode: this.route.query['upgrade-code'] ?? this.route.query.upgrade_code,
        email: this.route.query.email,
      },
    });
  }

  setTabs (tabs) {
    return {
      ...tabs,
      items: tabs.items.filter(item => !item.disabled),
    };
  }

  getUserFromStore () {
    return this.store.getters['auth/user'];
  }

  async listBillingInfo () {
    this.startLoading();
    this.disableAxiosResponseHandlers();

    try {
      const { data } = await this.axios.get(GET_BILLING_SUBSCRIPTION_URL, {
        params: {
          profile: this.meta.user.getPrimaryKey(),
        },
      });

      this.setItems(data.data);
      this.setMetaBillingInfo(this.getItems());
    } catch (e) {
      console.debug('[Billing@listBillingInfo]', 'No billing info found');
    } finally {
      this.enableAxiosResponseHandlers();
      this.stopLoading();
    }
  }

  setItems (items) {
    this.items = items.map(item => this.makeItem(item));
  }

  makeItem (item) {
    return {
      ...item,
      ...item.attributes,
      started: moment(item.attributes.start_date).format('MMM. D, YYYY LT'),
      expired: moment(item.attributes.expiry_date).format('MMM. D, YYYY LT'),
    };
  }

  hasSubscription () {
    return !isEmpty(this.items);
  }

  isSubscriptionUpgradable () {
    const { items } = this;
    const subscriptionType = uniqBy(items, 'subscription_type')
      .map(i => i.subscription_type.toLowerCase())?.[0];
    return UPGRADABLE_SUBSCRIPTIONS.includes(subscriptionType);
  }

  isPaidSubscription (code) {
    return ACCOUNT_TYPES_LEVELS?.[code] >= ACCOUNT_TYPE_PAID_SUBSCRIPTION;
  }

  setMetaBillingInfo (items) {
    const status = items?.[0]?.attributes?.status ?? null;
    const code = uniqBy(items, 'subscription_type')
      .map(i => i.subscription_type)?.[0];

    this.meta.billingInfo = {
      hasSubscription: this.hasSubscription(),
      isUpgradable: this.isSubscriptionUpgradable(),
      hasSentUpradeRequest: this.hasSentUpradeRequest(),
      subscriptionTypeCode: code,
      subscriptionType: uniqBy(items, 'subscription_type')
        .map(i => startCase(i.subscription_type))?.[0],
      subscriptionStatus: status,
      isPaidSubscription: this.isPaidSubscription(code),
      isSubscriptionActive: status === BILLING_STATUS_ACTIVE,
      isSubscriptionForCancellation: status === BILLING_STATUS_FOR_CANCELLATION,
    };

    this.store.dispatch('billing/setInfo', this.meta.billingInfo);
  }

  saveBillingInfoToLocalStorage () {
    const billingInfo = {
      ...this.meta.billingInfo,
      subscriptionType: this.meta.billingInfo?.subscriptionType ?? '',
    };

    localStorage.setItem('billing:info', JSON.stringify(billingInfo));
  }

  async listBillingInfoAndSaveToStore () {
    await this.listBillingInfo();
    await this.saveBillingInfoToLocalStorage();
  }

  async generateUpgradeCodeAndCheckout () {
    this.disableAxiosResponseHandlers();

    try {
      const { data } = await this.axios.post(GET_BILLING_GENERATE_UPGRADE_CODE_URL);

      if (isString(data.data) || isString(data.data?.[0] || false)) {
        this.showErrorMessage(data.data);
        return Promise.reject(data.data);
      }

      if (has(data.data, 'stripe.error')) {
        this.showErrorMessage(data.data.stripe.error.message, data.data.stripe.status);
        return Promise.reject(data.data);
      }

      if (has(data.data, 'upgrade_code')) {
        this.store.dispatch('billing/setUpgradeCode', data.data.upgrade_code);
      }

      if (has(data.data, 'stripe.clientSecret')) {
        this.store.dispatch('billing/setClientSecret', data.data.stripe);
      }
    } catch (err) {
      const { status } = err.response;
      this.showErrorMessage('An unhandled error occured.', status);
    } finally {
      this.enableAxiosResponseHandlers();
    }

    return Promise.resolve(this);
  }

  async sendUgradeRequest () {
    this.startLoading();

    const { data } = await this.axios.post(GET_BILLING_GENERATE_UPGRADE_CODE_URL);

    if (data.data.message) {
      this.setSentUpgradeRequest(true);
      this.store.dispatch('billing/setInfo', this.meta.billingInfo);
      setTimeout(() => {
        this.dialog('open', {
          persistent: true,
          title: 'Upgrade Subscription Requested',
          color: 'secondary',
          illustrationHeight: 240,
          illustration: () => import('@/modules/Billing/components/Icons/IconUpgrade'),
          text: [
            `<p>Your request to upgrade you subscription is being processed.
              Please wait for further instructions sent through your email.</p>`,
            '<p>Thank you.</p>',
          ],
          buttons: {
            cancel: { show: false },
          },
        });
      }, 400);
    }

    this.stopLoading();
  }

  setSentUpgradeRequest (value) {
    localStorage.setItem('billing:upgrade/request', value);
  }

  hasSentUpradeRequest () {
    return localStorage.getItem('billing:upgrade/request') === 'true';
  }

  async upgradeSubscription () {
    try {
      this.startLoading();
      this.disableAxiosResponseHandlers();
      const user = this.store.getters['auth/user'];
      const client = this.store.getters['billing/client'];
      const formData = new FormData;
      formData.append('email', user.email);
      formData.append('upgraded_subscription_id', client.subscriptionId);
      const { data } = await this.axios.post(GET_BILLING_UPGRADE_SUBSCRIPTION_URL, formData);

      if (data.data.message) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ email: [ capitalize(data.data.message) ] });
      }

      const accountTypes = data.data?.profile?.account_type ?? [];
      const userData = { ...user.data };
      userData.account_expiry = data.data?.profile?.account_expiry;
      userData.account_type = accountTypes;
      userData.currentAccountType = accountTypes?.[0];
      this.store.dispatch('auth/setUser', userData);

      this.meta.billingInfo = {
        hasSubscription: this.hasSubscription(),
        isUpgradable: this.isSubscriptionUpgradable(),
        hasSentUpradeRequest: this.hasSentUpradeRequest(),
        subscriptionTypeCode: userData.currentAccountType,
        subscriptionType: startCase(userData.currentAccountType),
        subscriptionStatus: 'active',
        isSubscriptionActive: BILLING_STATUS_ACTIVE === 'active',
        isSubscriptionForCancellation: BILLING_STATUS_FOR_CANCELLATION === 'active',
      };

      this.store.dispatch('billing/setInfo', this.meta.billingInfo);

      console.debug('upgradeSubscription.user', userData);
    } catch (e) {
      console.error('ERROR:', e, e.response);
      this.showErrorMessage('An unhandled error occured on our side', e.response.status);
      return Promise.reject(e);
    } finally {
      this.stopLoading();
      this.enableAxiosResponseHandlers();
    }

    return Promise.resolve(this);
  }

  showErrorMessage (message, status = '') {
    const supportEmail = this.getConfig('SUPPORT_EMAIL');
    message = isArray(message) ? message.join('<br>') : message;

    this.$dialog({
      color: 'error',
      persistent: true,
      illustration: () => import('@/modules/Billing/components/Icons/IconManCancelSubscription'),
      title: this.trans('Something went wrong'),
      text: [
        `<p class="small ma-0 muted--text">ERROR ${status}</p>`,
        `<p>Message: ${message}</p>`,
        `<p>You may try again later, or contact support at <a href="mailto:${supportEmail}">${supportEmail}</a>.</p>`,
      ],
      buttons: {
        cancel: { show: false },
        action: { text: 'Close' },
      },
    });
  }

  async listPaymentMethods () {
    this.startLoading();
    this.disableAxiosResponseHandlers();

    try {
      const { data } = await this.axios.get(GET_BILLING_PAYMENT_METHODS_URL);
      await this.store.dispatch('billing/setPaymentMethods', data.data.payment_methods.data);
      await this.store.dispatch('billing/setUpcomingInvoices', data.data.upcoming_invoice.data);
      this.meta.currentPaymentMethod = this.store.getters['billing/currentPaymentMethod'];
      this.meta.upcomingInvoice = this.store.getters['billing/upcomingInvoice'];
    } catch (err) {
      console.debug(err.response);
    } finally {
      this.stopLoading();
      this.enableAxiosResponseHandlers();
    }
  }

  async fetchPaymentCheckoutSession () {
    this.startLoading();

    const { data } = await this.axios.get(GET_BILLING_PAYMENT_CHECKOUT_SESSION_URL);
    this.store.dispatch('billing/setCheckoutSession', data.data);

    this.stopLoading();
  }

  setRedirectURL (redirectURL) {
    this.meta.redirectURL = redirectURL;

    return this;
  }

  async listPaymentIntents () {
    this.startLoading();
    this.disableAxiosResponseHandlers();

    try {
      const { data } = await this.axios.get(GET_BILLING_PAYMENT_INTENT_URL);

      if (data.data.message) {
        this.notify(data.data.message);
      }

      await this.store.dispatch('billing/setPaymentIntents', data.data.data);
      const currentPaymentIntent = this.store.getters['billing/paymentIntent'];

      if (currentPaymentIntent.status === BILLING_STATUS_SUCCEEDED) {
        this.meta.paymentIntentMessage = 'No pending payments';
      }

      if (currentPaymentIntent.status === BILLING_STATUS_REQUIRES_PAYMENT_METHOD) {
        this.meta.paymentIntentMessage = 'Pending payment found';
        console.log(currentPaymentIntent, data);
        this.retryPayment();
      }
    } catch (err) {
      console.log(err, err.response);
    } finally {
      this.stopLoading();
      this.enableAxiosResponseHandlers();
    }
  }

  async retryPayment () {
    this.startLoading();
    this.disableAxiosResponseHandlers();

    this.$dialog({
      persistent: true,
      color: 'secondary',
      illustration: () => import('@/modules/Billing/components/Icons/IconCreditCard'),
      title: this.trans('Pending Payment Found'),
      text: [
        '<p>Retrying pending payment. Please do not close the browser.</p>',
      ],
      buttons: {
        cancel: { show: false },
        action: { show: false },
      },
    });

    try {
      const currentPaymentIntent = this.store.getters['billing/paymentIntent'];
      const formData = new FormData;
      formData.append('payment_intent_id', currentPaymentIntent.id);
      const { data } = await this.axios.post(POST_BILLING_PAYMENT_INTENT_URL, formData);
      console.log('returned payment', data);
      if (data.data.status === BILLING_STATUS_SUCCEEDED) {
        await this.upgradeSubscription();
        this.meta.paymentIntentMessage = 'No pending payments';
        this.store.dispatch('dialog/hide');
        this.$dialog({
          persistent: true,
          color: 'success',
          illustration: () => import('@/modules/Billing/components/Icons/IconCreditCard'),
          title: this.trans('Payment Successful'),
          text: [
            '<p>Payment done. You may now close this message.</p>',
            '<p>Thank you.</p>',
          ],
          buttons: {
            cancel: { show: false },
            action: { text: 'Done' },
          },
        });
      }
    } catch (err) {
      console.log('Unhandled error: ', err, err.response);
      this.store.dispatch('dialog/hide');
      this.showErrorMessage('The card might have been declined or rejected.', err.response.status);
    } finally {
      this.enableAxiosResponseHandlers();
      this.stopLoading();
    }
  }
}
