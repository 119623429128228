import Map from '@/modules/Search/resources/Map';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import { GET_WEBGIS_LAYERS_URL } from '@/modules/Webgis/api/webgis';
import { GET_WELLS_INFO_URL } from '@/modules/Dashboard/api/wells';
import { toGeoJson } from '@/modules/Dashboard/utils/map';
import {
  DEFAULT_BASEMAP_Z_INDEX,
  MAP_CENTER, MAX_ZOOM, ZOOM, DEFAULT_BASEMAP_NAME,
} from '@/modules/Webgis/config/maps';
import { uniqBy, isEmpty } from 'lodash';
import { setTimeout } from 'core-js';
import { API_PER_PAGE_ALL } from '@/config/api';
import { GET_PIECHART_LIST_URL } from '@/modules/Webgis/api/piechart';
import { DEFAULT_FIT_BOUNDS_PADDING } from '@/modules/Dashboard/config/maps';
import Lithology from '@/modules/Webgis/resources/Lithology';
import PieChartDataViz from '@/modules/Webgis/resources/PieChartDataViz';
import ScatterPlotDataViz from '@/modules/Webgis/resources/ScatterPlotDataViz';
import BarGraphDataViz from '@/modules/Webgis/resources/BarGraphDataViz';

export default class WebGIS extends Map {
  constructor (options = {}) {
    super(options);

    this.open = false;
    this.map = undefined;
    this.id = options.id || 'map';
    this.item = options.item || {};
    this.layers = options.layers || [];
    this.layerControl = undefined;
    this.overlays = options.overlays || {};
    this.currentBasemapLayer = null;
    this.currentLayerOverlays = null;

    this.setMeta({
      basemaps: {},
      layers: {},
      ...options.meta,
    });

    this.setEvents({
      ...this.events,
      onMapLoad: map => this.onMapLoad(map),
      onMapLoaded: e => this.onMapLoaded(e),
      onBeforeDrawCreated: () => {},
      onDrawCreated: (e, map) => this.onDrawCreated(e, map),
      onMapMarkerClicked: e => this.onMapMarkerClicked(e),
      ...options.events,
    });

    this.initializeSpatialFilters();
  }

  async list () {
    this.startLoading();

    const params = {
      projects_list: this.store.getters['sourcetray/sources']?.map(i => i.id).toString(),
    };

    const { data } = await this.axios.get(GET_WEBGIS_LAYERS_URL, { params });

    this.setLayers(data.data);
    this.updateMap();
    this.selectDefaultLayerItems();
    this.setSelectedLayerByWellId(this.route.query.well_id);

    this.setPagination({
      total: data.meta.pagination.count,
      page: data.meta.pagination.page,
    });

    this.stopLoading();

    this.invalidateMapSize();
  }

  async getMapLayers () {
    const params = {
      projects_list: this.store.getters['sourcetray/sources']?.map(i => i.id).toString(),
    };

    const { data } = await this.axios.get(GET_WEBGIS_LAYERS_URL, { params });

    this.setLayers(data.data);

    return this.layers;
  }

  getWebGISLayers (layers) {
    const baseLayers = layers.filter(layer => layer.attributes.layer_type === 'base') || [];
    const overlayLayers = layers.filter(layer => layer.attributes.layer_type === 'tile_layer') || [];

    this.store.dispatch('webgis/layers', { type: 'base', items: baseLayers });
    this.store.dispatch('webgis/layers', { type: 'overlay', items: overlayLayers });

    this.setMetaBasemapLayers(baseLayers);
    this.setMetaOverlayLayers(overlayLayers);
  }

  setMetaBasemapLayers (items) {
    this.meta.basemaps = {
      current: DEFAULT_BASEMAP_NAME,
      items: items.map(item => ({
        ...item,
        text: item.attributes.name,
        value: item.attributes.name,
      })),
    };
  }

  setMetaLayers (items) {
    this.meta.layers = {
      ...this.meta.layers,
      current: this.meta?.selectedLayerControlItems || [],
      items,
    };
  }

  setMetaOverlayLayers (items) {
    this.meta.overlays = {
      items,
    };
  }

  initialize () {
    super.initialize();

    this.lithology = new Lithology(this.map);

    this.viz = {
      pieChart: new PieChartDataViz(this.map, this),
      scatterPlot: new ScatterPlotDataViz(this.map, this),
      barGraph: new BarGraphDataViz(this.map, this),
    };

    this.map.setView(MAP_CENTER, ZOOM);
  }

  eachViz () {
    return Object.keys(this.viz).map(key => this.viz[key]);
  }

  updateVizMarkers () {
    Object.keys(this.viz).forEach(async key => {
      await this.viz[key].listMapMarkers();

      if (!this.viz[key].isEnabled()) {
        this.viz[key].hideMapMarkers();
      }

      this.viz[key].listSummary();
    });
  }

  updateMapResults (items, search) {
    super.updateMap(items, search);
  }

  updateMap () {
    this.toggleLoadingState(true);

    this.getWebGISLayers(this.layers);
    this.setBaseLayers();
    this.setOverlays();

    this.toggleLoadingState(false);

    this.invalidateMapSize();
  }

  clearMap () {
    this.removeLayerControl();
    this.clearLayers();
  }

  formatItemsForLayerControl () {
    // get active source tray name and id
    const sources = this.store.getters['sourcetray/sources'];

    // create Layer control items container
    const controlItems = [];

    sources.forEach(source => {
      const item = { id: source.id, name: source.caption, children: [] };
      controlItems.push(item);
    });

    const overlays = Object.values(this.overlays);

    overlays.forEach(overlay => {
      const projects = this.getProjectIDs(overlay.options.attributes.projects);
      projects.forEach(project => {
        // get id of relationship in this.layerControlItems
        const projectIndex = controlItems.map(item => item.id).indexOf(project);
        const item = {
          id: overlay.options.attributes.id,
          name: overlay.options.attributes.name,
          layer: null,
          children: [],
        };

        if (controlItems[projectIndex]) {
          controlItems[projectIndex].children.push(item);
        }
      });
    });

    this.store.dispatch('webgis/setLayerControlItems', controlItems.filter(controlItem => controlItem.children.length > 1));
    return controlItems;
  }

  getProjectIDs (projects) {
    return projects.data.map(project => project.id);
  }

  removeLayerControl () {
    if (this.layerControl !== undefined) {
      this.layerControl.remove();
    }
  }

  setLayerControl () {
    this.layerControl = L.control.layers(null, null, { collapsed: false });
    this.layerControl.addTo(this.map);
    this.layerControl.options.collapsed = false;
  }

  clearLayers () {
    if (this.map) {
      this.map.eachLayer(layer => {
        layer.removeFrom(this.map);
      });
    }
  }

  setLayers (layers) {
    const uniqueLayers = uniqBy(layers, 'attributes.name');
    this.layers = uniqueLayers;
  }

  setBasemapLayer (basemap, map = null) {
    map = map ?? this.map;
    Object.keys(this.baseLayers)
      .forEach(baseLayer => this.baseLayers[baseLayer].removeFrom(map));

    if (map) {
      if (this.baseLayers?.[basemap]) {
        this.currentBasemapLayer = this.baseLayers[basemap];
        this.setBasemapZIndexToDefault();
        map.addLayer(this.currentBasemapLayer);
        [ this.currentBasemapLayer, this.baseLayers[basemap] ].forEach(baseLayer => {
          baseLayer.on('load', () => {
            setTimeout(() => {
              this.invalidateMapSize();
            }, 500);
          });
        });
      }
    }

    return this;
  }

  setBaseLayers () {
    const baseLayers = this.store.getters['webgis/layers'].base;

    this.baseLayers = {};

    baseLayers.forEach(baseLayer => {
      const tileLayer = L.tileLayer(
        baseLayer.attributes.url_link, {
          maxZoom: MAX_ZOOM,
          zIndex: baseLayer.attributes.layer_index,
          attribution: baseLayer.attributes.attribution,
          attributes: { ...baseLayer.attributes, ...baseLayer.relationships, id: baseLayer.id },
        },
      );

      this.baseLayers[baseLayer.attributes.name] = tileLayer;

      if (this.layerControl) {
        this.layerControl.addBaseLayer(tileLayer, baseLayer.attributes.name);
      }
    });

    this.setBasemapLayer(DEFAULT_BASEMAP_NAME);
  }

  setOverlays () {
    const overlays = this.store.getters['webgis/layers'].overlay;
    this.overlays = {};

    overlays.forEach(overlay => {
      const tileLayer = L.tileLayer(
        overlay.attributes.url_link, {
          maxZoom: MAX_ZOOM,
          zIndex: DEFAULT_BASEMAP_Z_INDEX + overlay.attributes.layer_index,
          attribution: overlay.attributes.attribution,
          attributes: { ...overlay.attributes, ...overlay.relationships, id: overlay.id },
        },
      );
      this.overlays[overlay.attributes.name] = tileLayer;
    });

    this.formatItemsForLayerControl();
  }

  selectDefaultLayerItems () {
    const defaultOverlaysFromEnv = (this.getConfig('meta.VUE_APP_WEBGIS_DEFAULT_OVERLAYS') || '')
      .split(',')
      .map(i => i.trim())
      .filter(i => !isEmpty(i));
    const items = this.getFlattenedLayerControlerItems();

    this.meta.selectedLayerControlItems = items
      .filter(i => defaultOverlaysFromEnv.find(name => name === i.name))
      .map(i => i.id);

    this.setMetaLayers(this.store.getters['webgis/layerControlItems']);
  }

  setItem (item) {
    this.item = item;
  }

  setLayerOverlays (items = null, map = null) {
    map = map ?? this.map;

    const storedOverlays = Object.keys(this.overlays).map(name => this.overlays[name]);
    const overlays = items
      ? items.map(id => storedOverlays.find(overlay => overlay.options.attributes.id === id))
      : this.overlays;

    storedOverlays.forEach(layer => layer.removeFrom(map));
    overlays.forEach(layer => layer.addTo(map));
    this.currentLayerOverlays = overlays;

    return this;
  }

  setQueryString (options) {
    const supportedQuery = this.parseOptionsAsSupportedQuery(options);

    this.query = {
      ...this.getQueryString(),
      ...supportedQuery,
    };

    this.pushRouteQuery();

    return this.mergeOptions(options);
  }

  remove () {
    if (this.map !== undefined) {
      this.map.off();
      this.map.remove();
    }

    const container = L.DomUtil.get(this.id);
    if (container != null) {
      container._leaflet_id = null;
    }
  }

  setToOpen () {
    this.open = true;
  }

  setToClose () {
    this.open = false;
  }

  getFlattenedLayerControlerItems () {
    const items = this.store.getters['webgis/layerControlItems'];
    const result = items.reduce(
      (result, { name, children }) => result
        .concat(children.map(item => ({ name, ...item }))),
      [],
    );

    return result;
  }

  invalidateMapSize () {
    if (this.map) {
      this.map.invalidateSize();
    }

    return this;
  }

  setBasemapZIndexToDefault () {
    this.currentBasemapLayer.setZIndex(DEFAULT_BASEMAP_Z_INDEX);

    return this;
  }

  async attachToCurrentMap ({ map }) {
    if (map) {
      const layers = await this.getMapLayers();

      this.setMap(map);
      this.getWebGISLayers(layers);
      this.setBaseLayers();
      this.setOverlays();
      this.setMetaLayers(this.store.getters['webgis/layerControlItems']);
    }
  }

  async setSelectedLayerByWellId (wellId) {
    if (!isEmpty(wellId)) {
      const params = { well_id_list: wellId };
      const { data } = await this.axios.get(GET_WELLS_INFO_URL, { params });
      const features = toGeoJson(data.data);

      if (!this.selectedLayer) {
        this.selectedLayer = L.featureGroup();
      }

      this.selectedLayer.clearLayers();

      features.map(feature => ({ ...feature, ...feature.properties })).forEach(feature => {
        this.setSelectedMarkerWithPopup(feature);
      });

      this.selectedLayer.addTo(this.map);
    }
  }

  clearSelectedLayers () {
    if (this.selectedLayer) {
      this.selectedLayer.clearLayers();
    }
  }

  clearDrawFilters () {
    this.setQueryString({
      geo_polygon: '',
      has_geo_polygon: 'false',
    });
    this.clearAllDrawHandlers();
    this.store.dispatch('webgis/spatial/hideSpatialButton');
    this.store.dispatch('webgis/spatial/setSpatialButtonOn', false);
    this.eachViz().forEach(v => v.onClearDrawFilters());
  }

  async onDrawCreated (e, geoPolygon) {
    await this.store.dispatch('webgis/spatial/setSpatialButtonOn');

    this.drawLayer.bringToFront();
    this.map.addLayer(this.drawLayer);

    this.setQueryString({
      geo_polygon: geoPolygon,
      has_geo_polygon: this.store.getters['webgis/spatial/isOn'].toString(),
    });

    await this.store.dispatch('webgis/spatial/showSpatialButton');
    await this.store.dispatch('webgis/spatial/triggerSpatialFilter');
  }

  onMapLoad (map) {
    map.on('load', () => {
      this.drawGeoPolygonFromRoute(map);
    });
  }

  async onMapLoaded (e) {
    super.onMapLoaded(e);
    this.fitToBoundsOf(this.drawLayer);
  }

  initializeSpatialFilters () {
    if (!isEmpty(this.route.query.geo_polygon)) {
      this.store.dispatch('map/setAsDrawn');
    }
  }

  drawGeoPolygonFromRoute () {
    const { geo_polygon: flatPolygon } = this.route.query;

    if (!isEmpty(flatPolygon)) {
      const geoPolygon = this.generateGeoFilterFromFlatPolygon(flatPolygon, true);
      const drawLayer = this.L.polygon(
        geoPolygon,
        this.getPolygonDashedHighlightOptions(),
      );
      this.addLayerToDrawLayer(drawLayer);
      this.store.dispatch('webgis/spatial/showSpatialButton');
    }
  }

  async listPieChartBackgroundMarkers () {
    const user = this.store.getters['auth/user'];

    if (user.isPermittedTo('webgis_pie_chart')) {
      this.startLoading();

      const params = {
        projects_list: this.getProjectIds(),
        order_by: 'well_name',
        page_size: API_PER_PAGE_ALL,
        page: 1,
      };
      const { data } = await this.axios.get(GET_PIECHART_LIST_URL, { params });

      const items = data.data.map(item => ({
        ...item,
        attributes: item,
      }));

      toGeoJson(items).forEach(feature => {
        this.setBackgroundMarkerWithPopup({ ...feature, ...feature.properties });
      });

      if (this.backgroundLayer) {
        this.backgroundLayer.bringToBack();

        if (!isEmpty(this.backgroundLayer.getBounds())) {
          this.map.fitBounds(this.backgroundLayer.getBounds(), {
            padding: DEFAULT_FIT_BOUNDS_PADDING,
          });
        }
      }

      this.stopLoading();
    }
  }

  toggleSpatialFilter () {
    this.store.dispatch('webgis/spatial/toggleSpatialFilter');

    return this;
  }

  onMapMarkerClicked (e) {
    this.eachViz().forEach(viz => {
      if (typeof viz.onMapMarkerClicked === 'function') {
        viz.onMapMarkerClicked(e, this);
      }
    });
  }
}
