<template>
  <app-tabs-content name="profile">
    <meta-tag :title="$trans('My Profile')"></meta-tag>

    <v-row>
      <v-col cols="10" class="mx-auto">
        <v-row>
          <v-col cols="12" lg="4" md="4">
            <v-row>
              <v-col cols="12">
                <v-avatar
                  size="130"
                  class="mr-3 app-avatar--bordered"
                ><img ref="avatar" :src="profile.avatar" width="40px"></v-avatar>
                <h2 class="mr-3 mt-2" v-text="profile.data.full_name"></h2>

                <section class="section__group">
                  <v-tooltip left transition="slide-x-reverse-transition">
                    <template v-slot:activator="{ on }">
                      <v-layout v-on="on">
                        <v-icon left small>mdi-card-account-details-outline</v-icon>
                        <span
                          class="body-1"
                          v-text="profile.data.username"
                        ></span>
                      </v-layout>
                    </template>
                    <span v-text="$trans('Username')"></span>
                  </v-tooltip>
                  <v-tooltip left transition="slide-x-reverse-transition">
                    <template v-slot:activator="{ on }">
                      <v-layout v-on="on">
                        <v-icon left small>mdi-email-outline</v-icon>
                        <a
                          :href="`mailto:${profile.data.email}`"
                          class="body-1"
                          v-text="profile.data.email"
                        ></a>
                      </v-layout>
                    </template>
                    <span v-text="$trans('Email')"></span>
                  </v-tooltip>
                </section>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h3 class="text-uppercase mb-6 mt-10" v-text="$trans('About')"></h3>
                <section class="section__group">
                  <v-layout
                    v-for="(info, i) in profile.getBackgroundInfo()" :key="i"
                    class="align-start mb-5"
                  >
                    <v-icon
                      small left
                      class="mt-1"
                      v-text="info.icon"
                    ></v-icon>
                    <div>
                      <div class="font-weight-bold" v-text="$trans(info.text)"></div>
                      <div
                        v-if="info.empty"
                        class="text--disabled font-italic"
                        v-text="$trans('Unspecified')"
                      ></div>
                      <div v-else v-html="info.value"></div>
                    </div>
                  </v-layout>
                </section>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="mb-6 mt-10">
                  <h3 class="text-uppercase mb-0" v-text="$trans('Account')"></h3>
                  <section class="section__group">
                    <verified-badge :verified="profile.data.is_verified"></verified-badge>
                  </section>
                </div>
                <section class="section__group">
                  <v-layout
                    v-for="(info, i) in profile.getAccountInfo()" :key="i"
                    class="align-start mb-5"
                  >
                    <v-icon
                      small left
                      class="mt-1"
                      v-text="info.icon"
                    ></v-icon>
                    <div>
                      <div class="font-weight-bold" v-text="$trans(info.text)"></div>
                      <div
                        v-if="info.empty"
                        class="text--disabled font-italic"
                        v-text="$trans('Unspecified')"
                      ></div>
                      <div
                        v-else
                        :class="info.class"
                        v-html="info.value"
                      ></div>
                    </div>
                  </v-layout>
                  <portal-target name="profile:account.bottom" multiple></portal-target>
                </section>
              </v-col>
            </v-row>
          </v-col>

          <v-col>
            <v-card>
              <v-card-title>
                <div>
                  <h3 v-text="$trans('Available Projects')"></h3>
                  <!-- eslint-disable-next-line max-len -->
                  <span class="body-2 muted--text" v-text="$trans('Click list of projects to view more details.')"></span>
                </div>
              </v-card-title>
              <v-card-text>
                <section class="section__group">
                  <v-layout v-for="(project, i) in profile.getProjects()" :key="i" class="mb-3">
                    <v-avatar
                      v-if="project.color" size="16"
                      class="ma-2 mr-4"
                      :color="project.color"
                    ></v-avatar>
                    <v-avatar
                      v-else size="16"
                      class="ma-2 mr-4"
                      color="grey lighten-3"
                    ></v-avatar>
                    <router-link
                      :to="{
                        name: 'datalake',
                        query: {
                          tab: 2,
                          project: project.project,
                        },
                      }"
                      v-text="project.project__name"
                    ></router-link>
                  </v-layout>
                </section>
              </v-card-text>
            </v-card>
            <portal-target name="profile:stacked" multiple></portal-target>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </app-tabs-content>
</template>

<script>
import Profile from '@/modules/User/resources/Profile';

export default {
  layout: 'admin',

  data: () => ({
    profile: new Profile,
  }),
};
</script>
