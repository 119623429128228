<template>
  <app-can-view code="report_related_documents">
    <v-autocomplete
      v-model="current"
      :items="items"
      outlined
      dense
      hide-details
      item-value="id"
      return-object
      prepend-inner-icon="mdi-file-document-outline"
      item-text="attributes.doc_name"
      @change="goToPage"
    >
      <template v-slot:prepend-item>
        <v-list-item
          :input-value="current"
          ripple
          @click.stop
        >
          <v-list-item-content>
            <v-list-item-title>
              <span v-text="report.doc_name"></span>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-chip label x-small color="accent">
              <span v-text="$trans('Current')"></span>
            </v-chip>
          </v-list-item-action>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
      <template v-slot:item="{ item: data, on, attrs }">
        <v-list-item
          :class="{ 'd-none': isCurrent(data.id) }"
          ripple
          v-bind="attrs"
          v-on="on"
        >
          <v-list-item-content>
            <v-list-item-title>
              <span v-text="data.attributes.doc_name"></span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </app-can-view>
</template>

<script>
export default {
  name: 'RelatedDocumentsDataIterator',

  props: {
    item: {
      type: [ Object, Array ],
      default: () => {},
    },
  },

  data: vm => ({
    current: vm.$route.params.slug,
  }),

  computed: {
    items () {
      return [ this.report ]
        .concat(Object.values(this.item.data.items));
    },
    report () {
      return this.item.data.current;
    },
  },

  methods: {
    isCurrent (id) {
      return id.toString() === this.current.toString();
    },
    goToPage (item) {
      this.$slug = item.id;

      this.$router.push({
        name: 'reports.single',
        params: { slug: this.$slug },
        query: {
          wellId: this.$route.query.wellId,
        },
      });
    },
  },
};
</script>
