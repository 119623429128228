<template>
  <div
    :class="{ 'search-result-card__thumbnail--active': active }"
    class="search-result-card__thumbnail mb-6"
  >
    <a :name="`item-${result.id}`"></a>
    <v-card
      :title="$trans('Click the card to locate result on the map')"
      @click="onClick"
    >
      <v-card-text class="px-4 ov-y-auto">
        <v-row>
          <v-col
            v-if="hasThumbnail"
            cols="auto" lg="5"
            class="pa-4"
          >
            <v-img
              :src="result.src"
              contain
              width="100%" height="250px"
            ></v-img>
          </v-col>
          <v-col cols="12" :lg="hasThumbnail ? 7 : 12" class="pa-4">
            <h3
              :title="result.title"
              class="title text-no-wrap text-truncate td-underline w-100 mb-6"
              @click="onTitleClick"
              v-text="result.title"
            ></h3>
            <div v-html="highlightAll(result.description, searchQuery)"></div>
            <div v-if="showTranslated">
              <v-icon x-small left color="info">mdi-information</v-icon>
              <em
                class="small info--text"
                v-html="$trans('This is the translated text')"
              ></em>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-chip
          v-if="hasType"
          :color="getItemTypeColor(result.type)"
          small label
          outlined
          class="ma-1"
          v-text="$trans(result.type)"
        ></v-chip>
        <v-chip
          v-if="hasThumbnail"
          small label
          outlined
          color="purple"
          class="ma-1"
          v-text="$trans('Image')"
        ></v-chip>
        <v-spacer></v-spacer>
        <app-mode name="ELASTICDOCS">
          <v-btn
            :active="showTranslated"
            :color="showTranslated ? 'accent' : null"
            small text
            @click="onTranslateButtonClick"
          >
            <span v-if="showTranslated" v-text="$trans('See Original')"></span>
            <span v-else v-text="$trans('See Translated')"></span>
          </v-btn>
        </app-mode>
        <v-btn
          small text
          @click="onButtonClick"
        ><span v-text="$trans('Go to page {page}', { page: result.page })"></span></v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import HighlightText from '@core/mixins/HighlightText';
import { BUCKET_TYPE_NA, items } from '@/modules/Search/config/categories';
import { isEmpty } from 'lodash';
import { VCard } from 'vuetify/lib';

export default {
  name: 'CategorySearchResultCard',

  extends: VCard,

  mixins: [ HighlightText ],

  props: {
    active: {
      type: Boolean,
      default: false,
    },

    item: {
      type: Object,
      default: () => {},
    },

    query: {
      type: String,
      default: null,
    },

    titleKey: {
      type: String,
      default: 'title',
    },
  },

  data: () => ({
    showTranslated: false,
  }),

  computed: {
    computedAttrs () {
      return {
        ...this.$attrs,
      };
    },

    computedProps () {
      return {
        hover: true,
        ...this.$props,
      };
    },

    result () {
      const item = { ...this.item };

      return {
        ...item,
        title: item[this.titleKey],
        src: item.thumbnail_url,
        description: this.showTranslated ? item.doc_text : item.doc_text_original,
        page: item.page_num,
        type: item.project_type,
        doc: item.doc_ref,
        docRef: item.doc_ref,
      };
    },

    hasThumbnail () {
      return this.result.src
        && this.result.src.toLowerCase() !== BUCKET_TYPE_NA.toLowerCase();
    },

    hasType () {
      return !isEmpty(this.result.type);
    },

    searchQuery () {
      return this.query || this.$route.query.q;
    },
  },

  methods: {
    onClick () {
      this.$emit('click', this.result);
    },

    onTitleClick () {
      this.$emit('click:title', this.result);
    },

    onTranslateButtonClick () {
      this.showTranslated = !this.showTranslated;
    },

    onButtonClick () {
      this.$emit('click:button', this.result);
    },

    getItemTypeColor (type) {
      return items.find(i => i.key === type)?.color;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-result-card__thumbnail {
  border: 3px solid transparent;
  transition: all 0.05s ease-in;

  &--active {
    border: 3px solid var(--v-primary-base);
    border-radius: 4px;
  }
}
</style>
