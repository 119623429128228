<template>
  <v-dialog
    :value="true"
    :width="width"
    persistent
    no-click-animation
  >
    <v-card>
      <div class="pa-1 text-right">
        <v-btn small icon @click="back"><v-icon small>mdi-close</v-icon></v-btn>
      </div>

      <v-card-text>
        <v-row>
          <v-col cols="12" lg="7" md="6">
            <v-layout justify-space-between align-center class="mb-4">
              <!-- Image title -->
              <h3 v-text="$trans('Image Preview')"></h3>
              <!-- Image title -->

              <!-- Image actions -->
              <v-sheet>
                <v-btn
                  small text
                  class="ml-2"
                  @click="reloadImage"
                >
                  <v-icon small left>mdi-reload</v-icon>
                  <span v-text="$trans('Reload')"></span>
                </v-btn>
                <v-btn
                  small text
                  class="ml-2"
                  @click="downloadImage(image.data)"
                >
                  <v-icon small left>mdi-download</v-icon>
                  <span v-text="$trans('Download')"></span>
                </v-btn>
                <v-btn
                  v-if="can('report_spreadsheet_viewer')"
                  :to="{
                    name: 'reports.single',
                    params: { slug: image.data.doc_ref },
                    query: {
                      page_num: image.data.page_num,
                      project_type: image.project_type,
                      back: $route.path,
                    },
                  }"
                  small text
                  class="ml-2"
                  @click="goToTab(image.data)"
                >
                  <v-icon small left>mdi-file-document-outline</v-icon>
                  <span
                    v-text="$trans('Go to page {page}', {
                      page: image.data.page_num,
                    })"
                  ></span>
                </v-btn>
                <v-btn
                  v-else
                  :to="{
                    name: 'reports.single',
                    params: { slug: image.data.doc_ref },
                    query: {
                      page_num: image.data.page_num,
                      project_type: image.project_type,
                      back: $route.path,
                    },
                  }"
                  small text
                  class="ml-2"
                >
                  <v-icon small left>mdi-file-document-outline</v-icon>
                  <span
                    v-text="$trans('Go to page {page}', {
                      page: image.data.page_num,
                    })"
                  ></span>
                </v-btn>
              </v-sheet>
              <!-- Image actions -->
            </v-layout>

            <!-- Image Previewer -->
            <panzoom-image-previewer
              ref="panzoom-image-previewer"
              :loading="image.loading"
              slider zoom-bar
              zoom-on-wheel
              zoom-on-dblclick
              height="600"
            >
              <img
                v-if="image.loaded" :src="image.data.file_url"
                width="100%"
                height="auto"
              >
            </panzoom-image-previewer>
            <!-- Image Previewer -->

            <!-- Image info -->
            <v-layout justify-space-between class="mt-3">
              <span
                class="muted--text caption"
                v-text="$trans('From {name}', {
                  name: image.data.doc_title,
                })"
              ></span>
              <span
                class="muted--text caption"
                v-text="$trans('page {page}', {
                  page: image.data.page_num,
                })"
              ></span>
            </v-layout>
            <!-- Image info -->
          </v-col>
          <v-col cols="12" lg="5" md="6">
            <v-card flat class="ov-y-auto ov-x-hidden px-3" height="644">
              <images-data-iterator
                v-model="image.selected"
                :items-per-page="image.pagination.itemsPerPage"
                :items-per-row="2"
                :items="image.meta.relatedImages"
                :loading="image.loading"
                :options.sync="image.options"
                :server-items-length="image.pagination.total"
                :single-select="image.meta.singleSelect"
                height="240"
                hide-default-footer
                @download="downloadImage"
                @update:options="updateRelatedImagesList"
              >
                <template v-slot:item="{ item, selected, height }">
                  <images-data-iterator-thumbnail
                    :height="height"
                    :src="item.attributes.thumbnail_url"
                    :active="isImageActive(item, selected)"
                    role="button"
                  >
                    <template v-slot:default="{ hover }">
                      <v-layout
                        fill-height align-center
                        align-content-center
                        justify-center
                        column
                        @click="selectImage({ item })"
                      >
                        <v-overlay :value="hover" absolute></v-overlay>
                      </v-layout>
                    </template>
                    <template v-slot:append>
                      <v-layout class="caption text-truncate text--disabled">
                        <router-link
                          :title="item.attributes.doc_title"
                          :to="{
                            name: 'reports.single',
                            params: { slug: item.attributes.doc_ref },
                            query: {
                              page_num: item.attributes.page_num,
                              project_type: item.attributes.project_type,
                              back: $route.path,
                            },
                          }"
                          class="caption text-truncate text--disabled"
                          v-text="item.attributes.doc_title"
                        ></router-link>
                        <v-spacer></v-spacer>
                        <span
                          v-text="`${$trans('Page')}: ${item.attributes.page_num}`"
                        ></span>
                      </v-layout>
                    </template>
                  </images-data-iterator-thumbnail>
                </template>

                <template v-slot:footer>
                  <div v-if="image.loaded" class="text-center caption">
                    <span
                      class="muted--text"
                      v-text="$trans_choice('{count} of {total} item', image.pagination.total, {
                        count: image.pagination.itemsPerPage,
                        total: image.pagination.total,
                      })"
                    ></span>
                  </div>
                  <v-btn
                    v-if="image.moreToLoadRelatedImages()"
                    :disabled="image.meta.loading"
                    :loading="image.meta.loading"
                    depressed
                    block large
                    class="mt-3"
                    @click="loadMoreRelatedImages"
                  >
                    <span v-text="$trans('Load more')"></span>&nbsp;
                  </v-btn>
                </template>
              </images-data-iterator>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Image from '@/modules/Image/resources/Image';
import BackToPreviousPage from '@core/mixins/BackToPreviousPage';
import { mapGetters } from 'vuex';
import CheckUserPermission from '@core/mixins/CheckUserPermission';

export default {
  layout: 'admin',

  routename: 'reports.image',

  mixins: [ BackToPreviousPage, CheckUserPermission ],

  props: {
    loading: Boolean,

    width: {
      type: [ String, Number ],
      default: 1380,
    },
  },

  data: vm => ({
    backUrl: { name: 'reports.single', params: { slug: vm.$slug } },

    image: new Image({
      loading: true,
      options: {
        q: vm.$route.query.q,
        itemsPerPage: 8,
      },
      meta: {
        loading: false,
        docRef: vm.$slug,
        relatedImages: [],
        singleSelect: true,
      },
    }),
  }),

  computed: {
    ...mapGetters({
      buckets: 'image/buckets',
    }),

    $image () {
      return this.$route.params.image;
    },
  },

  watch: {
    '$route.params': function ({ image }) {
      this.image.find(image);
    },
  },

  async created () {
    await this.image.find(this.$image);
    console.log(this.image);
    console.log(this.$image);
  },

  methods: {
    updateRelatedImagesList (options) {
      this.image.setQueryString(options).listRelatedImagesByDocRef();
    },

    loadMoreRelatedImages () {
      this.image.listMoreRelatedImages();
    },

    selectImage ({ item }) {
      this.$refs['panzoom-image-previewer'].setZoomToInitialLevel();
      this.$router.push({
        name: 'reports.image',
        params: { slug: this.$slug, image: item.id },
        query: { page_num: item.page_num },
      });
    },

    reloadImage () {
      this.image.find(this.$image);
      this.$refs['panzoom-image-previewer'].setZoomToInitialLevel();
    },

    downloadImage (item) {
      this.image.downloadImage(item);
    },

    isImageActive (item, selected) {
      return (this.$image === item.id) || selected;
    },

    goToTab () {
      this.$emit('click:goToTab', this.image);
    },
  },
};
</script>
