var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-10"},[_c('h1',{staticClass:"mb-15",domProps:{"innerHTML":_vm._s(_vm.$trans('Current Card'))}}),(_vm.billing.loading)?[_c('v-layout',{staticClass:"my-8"},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-loading mdi-spin")]),_c('p',{staticClass:"muted--text mb-0",domProps:{"textContent":_vm._s(_vm.$trans('Please wait, fetching card details'))}})],1)]:[(_vm.currentPaymentMethod.card)?[_c('p',{staticClass:"text-uppercase",domProps:{"textContent":_vm._s(_vm.$trans('{brand} ****{number}, {expM}/{expY}', {
          brand: _vm.currentPaymentMethod.card.brand,
          number: _vm.currentPaymentMethod.card.last4,
          expM: _vm.currentPaymentMethod.card.exp_month,
          expY: _vm.currentPaymentMethod.card.exp_year,
        }))}}),_c('p',{domProps:{"textContent":_vm._s(_vm.$trans('Next payment will be automatically collected from this card.'))}}),_c('div',{staticClass:"mb-15",domProps:{"innerHTML":_vm._s(_vm.$trans('Next billing is due on {date} for {price}', {
          date: _vm.upcomingInvoice.nextBillingDate,
          title: _vm.upcomingInvoice.nextBillingDateFull,
          price: _vm.upcomingInvoice.unitPrice,
        }))}})]:[_c('p',{domProps:{"textContent":_vm._s(_vm.$trans('No credit card detail saved.'))}}),_c('p',{staticClass:"font-italic muted--text",domProps:{"textContent":_vm._s(_vm.$trans('Add one by clicking the button below.'))}})]],_c('subscription-update-payment-method-dialog',{attrs:{"disabled":_vm.billing.loading}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }