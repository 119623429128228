<template>
  <v-hover v-slot="{ hover }">
    <v-navigation-drawer
      v-model="model"
      :clipped="clipped"
      :mini-variant="mini"
      app
      class="app-sidebar"
      color="sidebar"
      width="240"
      v-bind="$attrs"
    >
      <!-- Sidebar Mini Button -->
      <app-sidebar-mini-button v-show="hover"></app-sidebar-mini-button>
      <!-- Sidebar Mini Button -->

      <!-- Sidebar Header -->
      <slot name="header">
        <app-sidebar-header></app-sidebar-header>
      </slot>
      <!-- Sidebar Header -->

      <portal-target name="sidebar:prepend" multiple></portal-target>

      <app-sidebar-list dense color="transparent">
        <template v-for="(menu, i) in $_sidebar">
          <!-- Divider Menu -->
          <template v-if="has(menu, 'meta.divider')">
            <v-divider :key="i"></v-divider>
          </template>
          <!-- Divider Menu -->

          <!-- Subheader Menu -->
          <template v-else-if="has(menu, 'meta.subheader')">
            <v-subheader
              :key="i"
              class="text--muted text-capitalize"
            >
              {{ $trans(menu.title) }}
            </v-subheader>
          </template>
          <!-- Subheader Menu -->

          <!-- Child Menu -->
          <template v-else-if="menu.meta.hasChildren">
            <app-can-view :key="i" :code="menu.permission">
              <app-sidebar-list-group
                :key="i"
                :value="isActive(menu)"
                no-action
              >
                <template v-slot:prependIcon>
                  <v-icon small v-text="menu.icon"></v-icon>
                </template>
                <!-- Activator -->
                <template v-slot:activator>
                  {{ $trans(menu.title) }}
                </template>
                <!-- Activator -->

                <!-- Submenus -->
                <template v-for="(submenu, $j) in menu.children">
                  <template v-if="has(submenu, 'meta.divider')">
                    <v-divider :key="$j"></v-divider>
                  </template>

                  <!-- Submenu -->
                  <template v-else>
                    <app-can-view :key="`${i}-${$j}`" :code="submenu.permission">
                      <app-sidebar-list-item
                        :key="$j"
                        :exact="isInactive(submenu)"
                        :target="isExternal(submenu)"
                        :title="submenu.description"
                        :to="{ name: submenu.name, query: submenu.query }"
                      >
                        <app-sidebar-list-item-icon v-if="has(submenu, 'icon')">
                          <v-icon small v-text="submenu.icon"></v-icon>
                        </app-sidebar-list-item-icon>
                        <app-sidebar-list-item-title>
                          {{ $trans(submenu.title) }}
                        </app-sidebar-list-item-title>
                      </app-sidebar-list-item>
                    </app-can-view>
                  </template>
                  <!-- Submenu -->
                </template>
                <!-- Submenus -->
              </app-sidebar-list-group>
            </app-can-view>
          </template>
          <!-- Child Menu -->

          <!-- Single Menu -->
          <template v-else>
            <app-can-view :key="i" :code="menu.permission">
              <app-sidebar-list-item
                :key="i"
                :target="isExternal(menu)"
                :title="menu.description"
                :to="{ name: menu.name, query: menu.query }"
                v-bind="{ ...parseAttrs(menu.meta.attrs) }"
                link
              >
                <app-sidebar-list-item-icon v-if="has(menu, 'icon')">
                  <v-icon small v-text="menu.icon"></v-icon>
                </app-sidebar-list-item-icon>
                <app-sidebar-list-item-title>
                  {{ $trans(menu.title) }}
                </app-sidebar-list-item-title>
              </app-sidebar-list-item>
            </app-can-view>
          </template>
          <!-- Single Menu -->
        </template>
      </app-sidebar-list>

      <template v-slot:append>
        <portal-target name="sidebar:top.append" multiple></portal-target>
        <slot name="sidebar:append">
          <portal-target name="sidebar:append" multiple></portal-target>
        </slot>
      </template>
    </v-navigation-drawer>
  </v-hover>
</template>

<script>
import SidebarToggle from '@core/mixins/SidebarToggle';
import {
  includes, has,
  isNil, isEmpty,
} from 'lodash';

export default {
  mixins: [ SidebarToggle ],

  computed: {
    /**
     * The array of sidebar menus.
     *
     * @return {array}
     */
    $_sidebar () {
      return this.cleanUpSidebar(this.$options.sidebar);
    },

    /**
     * The current route name.
     *
     * @return {string}
     */
    routename () {
      return this.$route.name;
    },
  },

  methods: {
    cleanUpSidebar (items) {
      const sidebar = items.map(i => {
        if (this.has(i.meta, 'hasChildren') && this.$user) {
          i.children = i.children.map(j => ({
            ...j,
            enabled: !j.permission || (j.permission && this.$user.isPermittedTo(j.permission)),
          }));
        }

        return i;
      }).filter(i => {
        if (this.has(i.meta, 'parentOnly')) {
          return !isEmpty(i.children.filter(j => j.enabled));
        }

        return true;
      });

      return sidebar;
    },

    isActive (menu) {
      return this.has(menu, 'meta') && includes(menu.meta.children, this.routename);
    },

    isInactive (menu) {
      return !this.isActive(menu);
    },

    isExternal (menu) {
      return this.has(menu, 'meta') && menu.meta.external ? '_blank' : null;
    },

    has (item, key) {
      return has(item, key);
    },

    parseAttrs (attrs) {
      if (!isNil(attrs)) {
        return attrs;
      }

      return null;
    },
  },
};
</script>
