<template>
  <app-tabs-content name="profile">
    <meta-tag :title="$trans('My Billing')"></meta-tag>

    <v-row>
      <v-col cols="12" class="mx-auto">
        <v-tabs
          v-model="billing.meta.tabs.current"
          background-color="transparent"
          active-class="primary white--text rounded"
          hide-slider
        >
          <v-tab
            v-for="item in billing.meta.tabs.items"
            :key="item.key"
          >{{ $trans(item.text) }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="billing.meta.tabs.current">
          <v-tab-item v-for="item in billing.meta.tabs.items" :key="item.key">
            <component :is="item.component"></component>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </app-tabs-content>
</template>

<script>
import Billing from '@/modules/Billing/resources/Billing';

export default {
  layout: 'admin',

  meta: {
    permissions: [ 'payment' ],
  },

  data: () => ({
    billing: new Billing,
  }),
};
</script>
