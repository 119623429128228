<template>
  <workflow-content>
    <meta-tag :title="$trans('Petrophysics')"></meta-tag>

    <portal to="workflow:sidebar">
      <app-search-field
        v-model="petroplots.search"
        :loading="petroplots.searching"
        :label="$trans('Search a well')"
        :placeholder="$trans('Search a well')"
        solo class="mb-6"
        @click:clear="clearSearch"
        @enter:search="setSearch"
      ></app-search-field>

      <v-list
        dense nav
        two-line
        class="pa-0"
      >
        <v-list-item
          v-for="(item, i) in wells" :key="i"
          :class="{ 'grey': isActive(item) }"
          @click="setPage(item)"
        >
          <v-list-item-content>
            <strong v-text="item.text"></strong>
            <span v-text="item.subtext"></span>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <menu-pagination
        :filtered="petroplots.search"
        :page="petroplots.pagination.page"
        :length="petroplots.pagination.pageCount"
        :total="petroplots.pagination.total"
        :pagination="petroplots.pagination"
        @update:current="onUpdatePage"
        @click:unfilter="onUnfilter"
      ></menu-pagination>
    </portal>

    <v-row>
      <v-col cols="12">
        <v-layout align-center>
          <v-btn icon class="mr-3" @click="toggleMenu">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
          <template v-if="petroplots.data.well_name">
            <h2 v-text="petroplots.data.well_name"></h2>
            <v-chip
              label outlined
              color="accent"
              class="ml-3"
              v-text="$trans(petroplots.data.well_type)"
            ></v-chip>
          </template>

          <v-spacer></v-spacer>

          <v-btn-toggle
            v-if="petroplots.data.well_name"
            v-model="petroplots.meta.currentTab"
            mandatory
          >
            <v-btn small v-text="$trans('Preview')"></v-btn>
            <v-btn small @click="fetchInteractiveData" v-text="$trans('Interactive')"></v-btn>
          </v-btn-toggle>
        </v-layout>
      </v-col>
    </v-row>

    <v-row v-if="petroplots.data.well_name">
      <v-col v-if="isPreviewTab" cols="12">
        <petrophysics-preview-card-loading-state
          v-if="petroplots.loading"
        ></petrophysics-preview-card-loading-state>
        <v-card
          v-else
          fill-height height="calc(100vh - 216px)"
          class="ov-hidden"
        >
          <div class="ov-x-auto fill-height pa-3">
            <img :src="petroplots.data.thumbnail" width="100%">
          </div>
        </v-card>
      </v-col>
      <v-col v-if="isInteractiveTab" cols="12">
        <petroplots-interactive-card
          v-if="petroplots.data.bokeh"
          :item.sync="petroplots.data.bokeh"
        ></petroplots-interactive-card>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12">
        <petrophysics-interactive-card-empty-state></petrophysics-interactive-card-empty-state>
      </v-col>
    </v-row>
  </workflow-content>
</template>

<script>
import PetroPlots from '@/modules/PetroPlots/resources/PetroPlots';
import { mapActions } from 'vuex';

export default {
  layout: 'admin',

  meta: {
    permissions: [ 'pod_petropod' ],
    isWorkflow: true,
  },

  data: () => ({
    petroplots: new PetroPlots({
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      pagination: {
        page: 1,
      },
      meta: {
        currentTab: 0,
        supportedQuery: [
          'q',
          'page',
          'order_by',
          'projects_list',
          { search: 'q' },
          { sortBy: 'order_by' },
          { itemsPerPage: 'page_size' },
        ],
      },
    }),
  }),

  computed: {
    searchQuery () {
      return this.petroplots.search || '';
    },

    wells () {
      return (this.petroplots.items || []);
    },

    isPreviewTab () {
      return this.petroplots.meta.currentTab === 0;
    },

    isInteractiveTab () {
      return this.petroplots.meta.currentTab === 1;
    },
  },

  mounted () {
    this.petroplots.list();
  },

  methods: {
    ...mapActions({
      toggleMenu: 'menu/toggleMenu',
    }),

    setSearch (e) {
      this.petroplots.setSearch(e.target.value).setQueryString({ page: 1 }).list();
    },

    clearSearch () {
      this.petroplots.clearSearch().list();
    },

    setPage (item) {
      this.petroplots.unselectAll();
      this.petroplots.select(item);
      this.petroplots.findMetaDetails(item.id);
      this.petroplots.meta.currentTab = 0;
    },

    isActive (item) {
      return this.petroplots.selected.includes(item);
    },

    fetchInteractiveData () {
      this.petroplots.fetchInteractiveData();
    },

    onUpdatePage (page) {
      this.petroplots.setQueryString({ page }).list();
    },

    onUnfilter () {
      this.petroplots.clearSearch().list();
    },
  },
};
</script>
