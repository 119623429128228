<template>
  <v-dialog
    :value="true"
    :width="width"
    persistent
    no-click-animation
  >
    <v-card>
      <div class="pa-1 text-right">
        <v-btn small icon @click="back"><v-icon small>mdi-close</v-icon></v-btn>
      </div>

      <v-card-text>
        <v-row>
          <v-col cols="12" lg="12">
            <v-layout justify-space-between align-center class="mb-4">
              <!-- Image title -->
              <h3 v-text="$trans('Image Preview')"></h3>
              <!-- Image title -->

              <!-- Image actions -->
              <v-sheet>
                <v-btn
                  v-if="isImageinIntuition"
                  small text
                  class="ml-2"
                  @click="goToIntuition()"
                >
                  <v-icon small left>mdi-graph-outline</v-icon>
                  <span v-text="$trans('View in Intuition')"></span>
                </v-btn>
                <v-btn
                  small text
                  class="ml-2"
                  @click="reloadImage"
                >
                  <v-icon small left>mdi-reload</v-icon>
                  <span v-text="$trans('Reload')"></span>
                </v-btn>
                <v-btn
                  small text
                  class="ml-2"
                  @click="downloadImage(image.data)"
                >
                  <v-icon small left>mdi-download</v-icon>
                  <span v-text="$trans('Download')"></span>
                </v-btn>
                <v-btn
                  v-if="image.loaded"
                  :to="{
                    name: 'reports.single',
                    params: { slug: image.data.doc_ref },
                    query: {
                      page_num: image.data.page_num,
                      project_type: image.project_type,
                      back: $route.fullPath,
                    },
                  }"
                  small text
                  class="ml-2"
                >
                  <v-icon small left>mdi-file-document-outline</v-icon>
                  <span
                    v-text="$trans('Go to page {page}', {
                      page: image.data.page_num,
                    })"
                  ></span>
                </v-btn>
              </v-sheet>
              <!-- Image actions -->
            </v-layout>

            <!-- Image Previewer -->
            <panzoom-image-previewer
              ref="panzoom-image-previewer"
              :loading="image.loading"
              slider zoom-bar
              zoom-on-wheel
              zoom-on-dblclick
              height="600"
            >
              <img
                v-if="image.loaded" :src="image.data.file_url"
                width="100%"
                height="auto"
              >
            </panzoom-image-previewer>
            <!-- Image Previewer -->

            <!-- Image info -->
            <v-layout justify-space-between class="mt-3">
              <span
                class="muted--text caption"
                v-text="$trans('From {name}', {
                  name: image.data.doc_title,
                })"
              ></span>
              <span
                class="muted--text caption"
                v-text="$trans('page {page}', {
                  page: image.data.page_num,
                })"
              ></span>
            </v-layout>
            <!-- Image info -->
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import BackToPreviousPage from '@core/mixins/BackToPreviousPage';
import { mapGetters } from 'vuex';
import Image from '@/modules/Image/resources/Image';
import { INTUITION_MODELS_URL, INTUITION_FETCH_VECTOR } from '@/modules/Intuition/api/intuition';

export default {
  mixins: [ BackToPreviousPage ],

  layout: 'admin',

  props: {
    loading: Boolean,

    width: {
      type: [ String, Number ],
      default: 1380,
    },
  },

  data: vm => ({
    backUrl: { name: 'intuition', query: vm.$route.query },

    image: new Image({
      loading: true,
      options: {
        itemsPerPage: 16,
      },
      meta: {
        loading: false,
        relatedImages: [],
        singleSelect: true,
      },
    }),

    intuitionClasses: [],

    isImageinIntuition: false,
  }),

  computed: {
    ...mapGetters({
      buckets: 'image/buckets',
    }),

    imgId: {
      // getter
      get () {
        return this.$slug;
      },
      // setter
      set (newValue) {
        return newValue;
      },

    },

    imgClass () {
      const query = this.image.getRouteQuery();
      return query.img_tag_1;
    },
  },

  watch: {
    '$route.params': function ({ slug }) {
      this.$slug = slug;
      this.image.find(slug);
    },
  },

  created () {
    this.image.find(this.$slug);
  },

  async mounted () {
    const pointCloudData = await this.image.axios.get(INTUITION_MODELS_URL, { params: { projects_list: this.image.getProjectIdsUnjoined(), mode: 'default' } });
    this.intuitionClasses = await pointCloudData.data.data.map(model => ({ text: model.attributes.image_class, chip: model.attributes.image_count }));
    this.checkIntuitionClass(this.imgId);
  },

  methods: {
    async checkIntuitionClass (imageId) {
      const query = this.image.getRouteQuery();
      const classMatches = this.intuitionClasses.filter(i => i.text === query.img_tag_1);
      const params = {
        image_id: imageId,
        mode: 'default',
      };

      const flyVectors = await this.axios.get(INTUITION_FETCH_VECTOR, { params });

      this.isImageinIntuition = flyVectors.data.data.length > 0 && classMatches.length >= 1;

      return flyVectors.data.data.length > 0 && classMatches.length >= 1;
    },

    goToIntuition () {
      this.$router.push({
        name: 'intuition',
        query: {
          img_id: this.$slug,
          img_tag_1: this.imgClass,
          back: this.$route.fullPath,
        },
      });
    },

    async selectImage ({ item }) {
      this.$refs['panzoom-image-previewer'].setZoomToInitialLevel();
      this.$router.push({
        name: 'images.single',
        params: { slug: item.id },
        query: { ...this.$route.query, page_num: item.page_num },
      });

      this.imgId = item.id;
      await this.checkIntuitionClass(item.id);
    },

    reloadImage () {
      this.image.find(this.$slug);
      this.$refs['panzoom-image-previewer'].setZoomToInitialLevel();
    },

    downloadImage (item) {
      this.image.downloadImage(item);
    },

    isImageActive (item, selected) {
      return (this.$slug === item.id) || selected;
    },
  },
};
</script>
