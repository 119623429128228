<template>
  <div>
    <!-- DataSource Tray -->
    <data-source-tray-filter
      width="400px"
      @update:selected="changedDataSource"
    ></data-source-tray-filter>
    <!-- DataSource Tray -->

    <portal to="header:left">
      <app-search-field
        v-model.trim.lazy="datalake.search"
        :title="'search datalake'"
        :loading="datalake.searching"
        :label="$trans('Search datalake')"
        :placeholder="$trans('Search datalake')"
        autofocus solo
        @click:clear="clearSearch"
        @enter:search="setSearch"
      ></app-search-field>
    </portal>

    <div data-tour-step="welcome/onboarding.5" data-tour-overlay>
      <v-data-table
        :title="'search datalake documents results'"
        :footer-props="{ 'items-per-page-options': datalake.options.rowsPerPage }"
        :dense="datalake.meta.dense"
        :headers="datalake.headers"
        :items="datalake.items"
        :loading="datalake.loading"
        :mobile-breakpoint="datalake.meta.mobileBreakpoint"
        :server-items-length="datalake.pagination.total"
        :show-select="datalake.meta.showSelect"
        :options.sync="datalake.options"
        @update:options="updateList"
      >
        <template v-slot:top="{ pagination, options, updateOptions }">
          <app-stickybar class="bg-white">
            <app-data-table-toolbar
              :size="datalake.selectedSize()"
              @click:clear="unselectAll"
            ></app-data-table-toolbar>
            <v-spacer></v-spacer>
            <v-data-footer
              :title="'search datalake pagination'"
              class="no-border"
              :pagination="pagination"
              :options="options"
              :items-per-page-options="datalake.options.rowsPerPage"
              @update:options="updateOptions"
            ></v-data-footer>
          </app-stickybar>
          <v-divider></v-divider>
        </template>

        <template v-slot:item.doc_name="{ item }">
          <div class="text-truncate text-no-wrap" style="max-width: 420px;">
            <app-can-view code="report_viewer">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <router-link
                    :to="{
                      name: 'reports.single',
                      params: {
                        slug: item.id,
                      },
                      query: {
                        data_index: item.data_index,
                      },
                    }"
                    class="text-no-wrap"
                  >
                    <span v-on="on" v-text="item.doc_name"></span>
                  </router-link>
                </template>
                <span
                  v-text="$trans('View {name}', {
                    name: item.doc_name,
                  })"
                ></span>
              </v-tooltip>
              <template v-slot:alt>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on" v-text="item.doc_name"></span>
                  </template>
                  <span
                    v-text="$trans('View {name}', {
                      name: item.doc_name,
                    })"
                  ></span>
                </v-tooltip>
              </template>
            </app-can-view>
          </div>
        </template>

        <template v-slot:item.owner="{ item }">
          <span class="text-no-wrap text-truncate" v-text="item.owner"></span>
        </template>

        <template v-slot:item.update_date="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ item.update_date | toDateFromNow }}</span>
            </template>
            <span v-text="item.update_date"></span>
          </v-tooltip>
        </template>

        <template v-slot:item.file_type="{ item }">
          <div class="text-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-icon
                    :color="getFileTypeColor(item.file_type)"
                    left small
                  >
                    {{ getFileTypeIcon(item.file_type) }}
                  </v-icon>
                  <span class="text-uppercase" v-text="item.file_type"></span>
                </div>
              </template>
              <span class="text-uppercase" v-text="item.file_type"></span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:item.file_size="{ item }">
          <span>{{ item.file_size | toMb }}</span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import Datalake from '@/modules/Datalake/resources/Datalake';
import GetFileTypeIcon from '@core/mixins/GetFileTypeIcon';
import PrettyBytes from '@core/filters/PrettyBytes';
import PrettyDates from '@core/filters/PrettyDates';
import { mapGetters } from 'vuex';

export default {
  name: 'Documents',

  mixins: [ GetFileTypeIcon, PrettyBytes, PrettyDates ],

  props: {
    resource: {
      type: Object,
      default: () => {},
    },

    tab: {
      type: Object,
      default: () => {},
    },
  },

  data: vm => ({
    datalake: new Datalake(vm.resource),
  }),

  computed: {
    ...mapGetters({
      itemsPerPage: 'datalake/itemsPerPage',
    }),
  },

  watch: {
    itemsPerPage (value) {
      this.datalake.store.dispatch('datalake/setItemsPerPage', value);
    },
  },

  mounted () {
    this.$emit('resource:update', this.datalake);
  },

  methods: {
    changedDataSource ({ query }) {
      this.datalake.setQueryString(query).list();
    },

    updateList (options) {
      this.datalake.store.dispatch('datalake/setItemsPerPage', options.itemsPerPage);
      this.datalake.setQueryString(options).list();
    },

    setSearch (e) {
      this.datalake.setSearch(e.target.value).list();
    },

    clearSearch () {
      this.datalake.clearSearch();
    },

    unselectAll () {
      this.datalake.unselectAll();
    },
  },
};
</script>
