var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":true,"width":_vm.width,"persistent":"","no-click-animation":"","title":"dialog zoom preview"}},[_c('v-card',[_c('div',{staticClass:"pa-1 text-right"},[_c('v-btn',{attrs:{"title":"button zoom close","small":"","icon":""},on:{"click":_vm.back}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"7","md":"6"}},[_c('v-layout',{staticClass:"mb-4",attrs:{"justify-space-between":"","align-center":""}},[_c('h3',{domProps:{"textContent":_vm._s(_vm.$trans('Image Preview'))}}),_c('v-sheet',[(_vm.isImageinIntuition)?_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.goToIntuition()}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-graph-outline")]),_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('View in Intuition'))}})],1):_vm._e(),_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","text":"","title":"button zoom reload"},on:{"click":_vm.reloadImage}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-reload")]),_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Reload'))}})],1),_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","text":"","title":"button zoom download"},on:{"click":function($event){return _vm.downloadImage(_vm.image.data)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-download")]),_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Download'))}})],1),(_vm.image.loaded)?_c('v-btn',{staticClass:"ml-2",attrs:{"to":{
                  name: 'reports.single',
                  params: { slug: _vm.image.data.doc_ref },
                  query: {
                    page_num: _vm.image.data.page_num,
                    project_type: _vm.image.data.project_type,
                    data_index: _vm.image.data.data_index,
                    back: _vm.$route.fullPath,
                  },
                },"small":"","text":"","title":"button zoom goto"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-file-document-outline")]),_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Go to page {page}', {
                    page: _vm.image.data.page_num,
                  }))}})],1):_vm._e()],1)],1),_c('panzoom-image-previewer',{ref:"panzoom-image-previewer",attrs:{"loading":_vm.image.loading,"options":{
              overflow: 'auto',
              touchAction: 'pan',
              panOnlyWhenZoomed: true,
            },"always-zoom-from-center":"","slider":"","zoom-bar":"","zoom-on-dblclick":"","height":"600"},on:{"wheel:up":_vm.onWheelUp,"wheel:down":_vm.onWheelDown}},[(_vm.image.loaded)?_c('img',{attrs:{"src":_vm.image.data.file_url,"width":"100%","height":"auto"}}):_vm._e()]),_c('v-layout',{staticClass:"mt-3",attrs:{"justify-space-between":""}},[_c('span',{staticClass:"muted--text caption",domProps:{"textContent":_vm._s(_vm.$trans('From {name}', {
                name: _vm.image.data.doc_title,
              }))}}),_c('span',{staticClass:"muted--text caption",domProps:{"textContent":_vm._s(_vm.$trans('page {page}', {
                page: _vm.image.data.page_num,
              }))}})])],1),_c('v-col',{attrs:{"cols":"12","lg":"5","md":"6"}},[_c('v-card',{staticClass:"ov-y-auto ov-x-hidden px-3",attrs:{"flat":"","height":"644"}},[_c('images-data-iterator',{attrs:{"items-per-page":_vm.image.pagination.itemsPerPage,"items-per-row":2,"items":_vm.image.meta.relatedImages,"loading":_vm.image.loading,"options":_vm.image.options,"server-items-length":_vm.image.pagination.total,"single-select":_vm.image.meta.singleSelect,"height":"240","hide-default-footer":""},on:{"update:options":[function($event){return _vm.$set(_vm.image, "options", $event)},_vm.updateRelatedImagesList],"download":_vm.downloadImage},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
              var selected = ref.selected;
              var height = ref.height;
return [_c('images-data-iterator-thumbnail',{attrs:{"height":height,"src":item.attributes.thumbnail_url,"active":_vm.isImageActive(item, selected),"role":"button"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return [_c('v-layout',{attrs:{"fill-height":"","align-center":"","align-content-center":"","justify-center":"","column":""},on:{"click":function($event){return _vm.selectImage({ item: item })}}},[_c('v-overlay',{attrs:{"value":hover,"absolute":""}})],1)]}},{key:"append",fn:function(){return [_c('v-layout',{staticClass:"caption text-truncate text--disabled"},[_c('router-link',{staticClass:"caption text-truncate text--disabled",attrs:{"title":item.attributes.doc_title,"to":{
                          name: 'reports.single',
                          params: { slug: item.attributes.doc_ref },
                          query: {
                            page_num: item.attributes.page_num,
                            project_type: item.attributes.project_type,
                            data_index: item.data_index,
                            back: _vm.$route.path,
                          },
                        }},domProps:{"textContent":_vm._s(item.attributes.doc_title)}}),_c('v-spacer'),_c('span',{domProps:{"textContent":_vm._s(((_vm.$trans('Page')) + ": " + (item.attributes.page_num)))}})],1)]},proxy:true}],null,true)})]}},{key:"footer",fn:function(){return [(_vm.image.loaded)?_c('div',{staticClass:"text-center caption py-5"},[_c('span',{staticClass:"muted--text",domProps:{"textContent":_vm._s(_vm.$trans_choice('{count} of {total} item', _vm.image.pagination.total, {
                      count: _vm.image.pagination.itemsLength,
                      total: _vm.image.pagination.total,
                    }))}})]):_vm._e(),_c('v-layout',[_c('v-spacer'),(_vm.image.moreToLoadRelatedImages())?_c('v-btn',{staticClass:"mt-3",attrs:{"disabled":_vm.image.meta.loading,"loading":_vm.image.meta.loading,"large":"","text":""},on:{"click":_vm.loadMoreRelatedImages}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Load more'))}})]):_vm._e(),_c('v-spacer')],1)]},proxy:true}]),model:{value:(_vm.image.selected),callback:function ($$v) {_vm.$set(_vm.image, "selected", $$v)},expression:"image.selected"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }