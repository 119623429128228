import DataResource from '@core/resources/DataResource';
import { LIST_ROCK_PHYSICS_URL, FIND_ROCK_PHYSICS_AVO_BY_WELL_NAME_URL } from '@/modules/RockPhysics/api/rockphysics';
import { sortBy } from 'lodash';

export default class RockPhysics extends DataResource {
  constructor (options = {}) {
    super(options);
  }

  async list () {
    this.startLoading();

    const params = {
      order_by: 'well_name',
      page: 1,
      projects_list: this.getProjectIds(),
      ...this.getQueryString(),
      page_size: 10,
    };
    const { data } = await this.axios.get(LIST_ROCK_PHYSICS_URL, { params });

    this.setItems(data.data);
    this.setPagination({
      total: data.meta.pagination.count,
      page: data.meta.pagination.page,
      pageCount: data.meta.pagination.pages,
    });

    this.stopLoading();
  }

  setItems (items) {
    this.items = sortBy(items.map(item => ({
      ...item,
      ...item.attributes,
      text: item.attributes.well_name,
      subtext: item.attributes.basin,
    })), i => i.text);
  }

  async findAvoDetail (wellName) {
    this.startLoading();

    const { data } = await this.axios.get(FIND_ROCK_PHYSICS_AVO_BY_WELL_NAME_URL(wellName));

    this.setBokehData(data.data);

    this.stopLoading();
  }

  setBokehData (data) {
    const bokehId = /id="(.*?)"/g.exec(data.bokeh_script)[1];
    const bokeh = {
      ...data,
      id: bokehId,
      script: data.bokeh_script,
    };
    this.data = { ...this.data, ...bokeh, bokeh };
  }

  getProjectIds () {
    return this.store.getters['sourcetray/sources']?.map(i => i.id).join();
  }
}
