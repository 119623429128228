var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-source-tray-filter',{attrs:{"width":"400px"},on:{"update:selected":_vm.changedDataSource}}),_c('portal',{attrs:{"to":"header:left"}},[_c('app-search-field',{attrs:{"loading":_vm.datalake.searching,"label":_vm.$trans('Search datalake'),"placeholder":_vm.$trans('Search datalake'),"autofocus":"","solo":""},on:{"click:clear":_vm.clearSearch,"enter:search":_vm.setSearch},model:{value:(_vm.datalake.search),callback:function ($$v) {_vm.$set(_vm.datalake, "search", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"datalake.search"}})],1),_c('portal',{attrs:{"to":"header:right"}},[(_vm.active)?_c('has-module',{attrs:{"name":"file"}},[_c('file-data-storage-info')],1):_vm._e()],1),_c('div',{attrs:{"data-tour-step":"welcome/onboarding.4","data-tour-overlay":""}},[_c('v-data-table',{attrs:{"footer-props":{ 'items-per-page-options': _vm.datalake.options.rowsPerPage },"dense":_vm.datalake.meta.dense,"headers":_vm.datalake.headers,"items":_vm.datalake.items,"loading":_vm.datalake.loading,"mobile-breakpoint":_vm.datalake.meta.mobileBreakpoint,"server-items-length":_vm.datalake.pagination.total,"show-select":_vm.datalake.meta.showSelect,"options":_vm.datalake.options},on:{"update:options":[function($event){return _vm.$set(_vm.datalake, "options", $event)},_vm.updateList]},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('No data available.'))}}),_vm._v(" "),(_vm.hasNoSourcesSelected)?_c('a',{staticClass:"s-link small caption",domProps:{"textContent":_vm._s(_vm.$trans('Select data sources.'))},on:{"click":function($event){$event.preventDefault();return _vm.$store.dispatch('sourcetray/show')}}}):_vm._e()]},proxy:true},{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('app-stickybar',{staticClass:"bg-white align-center"},[_c('app-data-table-toolbar',{attrs:{"size":_vm.datalake.selectedSize()},on:{"click:clear":_vm.unselectAll}},[_c('file-data-table-filter-bar',{on:{"click:filter":_vm.onFilter}})],1),_c('v-spacer'),_c('v-data-footer',{staticClass:"no-border",attrs:{"pagination":pagination,"options":options,"items-per-page-options":_vm.datalake.options.rowsPerPage},on:{"update:options":updateOptions}})],1),_c('v-divider')]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
            name: 'sets.show',
            params: { id: item.id },
          }},domProps:{"textContent":_vm._s(("Set " + (item.id)))}})]}},{key:"item.added_by_full_name",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap",domProps:{"textContent":_vm._s(item.added_by_full_name)}})]}},{key:"item.storage_used",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toMb")(item.storage_used)))])]}},{key:"item.date_added",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('span',_vm._g({staticClass:"text-no-wrap"},on),[_vm._v(_vm._s(_vm._f("toDateFromNow")(item.date_added)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("toDateTime")(item.date_added)))])])]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('v-alert',{staticClass:"text-center pa-1 ma-2",staticStyle:{"line-height":"1"},attrs:{"color":item.status.color,"dense":"","outlined":"","text":"","width":"180px"}},[_c('span',{staticClass:"caption",domProps:{"textContent":_vm._s(item.status.text)}})])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }