<template>
  <v-list-item
    v-bind="$attrs" :to="to"
    :exact="exact"
    :link="link" color="primary"
  >
    <slot></slot>
  </v-list-item>
</template>

<script>
export default {
  props: {
    /**
     * The url object of the navigation link.
     *
     * @type {Object}
     */
    to: {
      type: Object,
      required: true,
    },

    /**
     * Determine if the route should match exactly.
     *
     * @type {boolean}
     */
    exact: {
      type: Boolean,
    },

    /**
     * Determine if the route is a link.
     *
     * @type {boolean}
     */
    link: {
      type: Boolean,
    },
  },
};
</script>
