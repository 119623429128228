<template>
  <div>
    <v-dialog
      v-model="uploadFormIsShown"
      persistent scrollable
      max-width="560px"
    >
      <template v-slot:activator="props">
        <slot name="activator" v-bind="props"></slot>
      </template>
      <validation-observer
        ref="observer"
        v-slot="{ handleSubmit, invalid }"
        slim
      >
        <v-form
          ref="form"
          enctype="multipart/form-data"
          @submit.prevent="handleSubmit(submit)"
        >
          <div data-tour-step="welcome/onboarding.2">
            <v-card class="file-upload-form ov-hidden">
              <v-card-title>
                <h2 class="title" v-text="$trans('Upload files')"></h2>
              </v-card-title>
              <v-card-text class="ov-y-auto">
                <file-upload-alert class="mb-10"></file-upload-alert>
                <validation-provider
                  v-slot="{ errors }" vid="project_id"
                  :name="$trans('project ID')"
                  rules="required"
                >
                  <v-autocomplete
                    v-model="uploader.fields.project_id"
                    :disabled="uploader.meta.files.disabled"
                    :error-messages="errors"
                    :items="uploader.meta.files.items"
                    :label="$trans('Select project')"
                    outlined filled
                    name="project_id"
                    class="is-required"
                    @change="fetchProjectSettings"
                  >
                    <template v-slot:selection="data">
                      <v-avatar :color="data.item.color" left size="10"></v-avatar>
                      <div class="ml-4" v-text="data.item.text"></div>
                    </template>
                  </v-autocomplete>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }" vid="file_upload"
                  :name="$trans('files')"
                  :rules="`required|totalSize:${uploader.meta.supportedFileSizeKb}`"
                >
                  <v-file-input
                    v-model="uploader.fields.file_upload"
                    :accept="uploader.meta.supportedFileExtensions"
                    :disabled="hasNoSupportedFileExtension"
                    :error-messages="errors"
                    :hint="$trans('Maximum of {size}', {
                      size: uploader.meta.supportedFileSizeMb,
                    })"
                    :label="$trans('Select files')"
                    :prepend-icon="null"
                    counter
                    multiple
                    outlined filled
                    show-size
                    persistent-hint
                    name="file_upload"
                    truncate-length="20"
                    class="mb-4 is-required"
                    prepend-inner-icon="mdi-paperclip"
                    @change="onFileChange"
                  ></v-file-input>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }" vid="remarks"
                  :name="$trans('remarks')"
                  rules="required"
                  mode="aggressive"
                >
                  <v-textarea
                    v-model="uploader.fields.remarks"
                    :error-messages="errors"
                    :label="$trans('Remarks / Comments')"
                    outlined filled
                    name="remarks"
                    class="is-required"
                  ></v-textarea>
                </validation-provider>
              </v-card-text>
              <v-card-actions class="pt-2 pa-5">
                <v-spacer></v-spacer>
                <v-btn
                  text large
                  min-width="120"
                  @click="onCancel"
                  v-text="$trans('Cancel')"
                ></v-btn>
                <v-btn
                  :loading="uploader.loading"
                  :disabled="invalid"
                  depressed large
                  color="primary"
                  min-width="120"
                  type="submit"
                  v-text="$trans('Submit')"
                ></v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-form>
      </validation-observer>
    </v-dialog>
  </div>
</template>

<script>
import Uploader from '@/modules/File/resources/Uploader';
import PrettyBytes from '@core/filters/PrettyBytes';
import { uploadTray, uploadForm } from '@/modules/File/config/upload';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'FileUploadDialog',

  mixins: [ PrettyBytes ],

  data: () => ({
    uploader: new Uploader({
      meta: {
        uploadTray,
        uploadForm,
        supportedFileSizeMb: '200 MB',
        supportedFileSizeKb: 200000,
        supportedFileExtensions: null,
        set: {},
      },
    }),
  }),

  computed: {
    ...mapGetters({
      isCancelledByUploadTray: 'file/isSetUploadCancelled',
      isSetUploadRetrying: 'file/isSetUploadRetrying',
      uploadTrayIsHidden: 'file/uploadTrayIsHidden',
      isUploadDone: 'file/isUploadDone',
    }),

    uploadFormIsShown: {
      get () {
        return this.$store.getters['file/uploadFormIsShown'];
      },
      set (isShown) {
        this.$store.dispatch('file/toggleUploadForm', isShown);
      },
    },

    hasNoSupportedFileExtension () {
      return !this.uploader.meta.supportedFileExtensions;
    },
  },

  watch: {
    'uploader.fields': {
      deep: true,
      handler (fields) {
        this.uploader.updateData(fields);
      },
    },
    isCancelledByUploadTray (isCancelled) {
      if (isCancelled) {
        this.onTrayCancelled();
      }
    },
    uploadTrayIsHidden (isClosed) {
      if (isClosed) {
        this.onTrayClose();
      }
    },
    isSetUploadRetrying (isRetrying) {
      if (isRetrying) {
        this.onRetryAll();
      }
    },
    isUploadDone (isDone) {
      if (isDone) {
        this.onUploadDone();
      }
    },
  },

  mounted () {
    this.initializeFileUploadDialog();
  },

  methods: {
    ...mapActions({
      setUploadTrayItems: 'file/setUploadTrayItems',
      showUploadForm: 'file/showUploadForm',
      hideUploadForm: 'file/hideUploadForm',
      showUploadTray: 'file/showUploadTray',
      hideUploadTray: 'file/hideUploadTray',
    }),

    initializeFileUploadDialog () {
      this.showUploadForm();
      this.hideUploadTray();
      this.resetUploaderDialogForm();
    },

    resetUploaderDialogForm (callback = null) {
      setTimeout(() => {
        if (this.$refs.form) {
          this.$refs.form.reset();
        }

        if (this.$refs.observer) {
          this.$refs.observer.reset();
        }

        this.uploader.makePristine();

        if (callback) {
          callback();
        }
      }, 300);
    },

    onFileChange (files) {
      this.setUploadTrayItems(files);
    },

    async submit () {
      try {
        this.hideUploadForm();
        this.showUploadTray();
        await this.uploader.upload();
        this.$root.$emit('file/upload.form:submit');
      } catch (errors) {
        this.showUploadForm();
        this.hideUploadTray();
        this.$refs.observer.setErrors(errors ?? []);
      }
    },

    hideUploadFormAndEmitClose () {
      this.hideUploadForm();
      setTimeout(() => this.$emit('form:close'), 200);
    },

    onAnother () {
      this.resetUploaderDialogForm();
      this.hideUploadTray();
      this.showUploadForm();
    },

    onUploadDone () {
      this.uploader.makePristine();
      this.resetUploaderDialogForm();
    },

    onTrayCancelled () {
      this.uploader.cancelAllRequests();
    },

    onTrayClose () {
      this.resetUploaderDialogForm(() => this.$emit('tray:close'));
    },

    fetchProjectSettings (projectId) {
      this.uploader.setSupportedFileExtensionsByProjectId(projectId);
    },

    onRetryAll () {
      this.submit();
    },

    onCancel () {
      if (this.uploader.isDirty()) {
        this.uploader.confirmDiscardDialogPrompt(() => this.discardUpload());
      } else {
        this.goToIndexPage();
      }
    },

    onCancelAll () {
      this.uploader.cancelAllRequests();
    },

    cancelUpload () {
      this.uploader.cancelAllRequests();
      this.hideUploadTray();
      this.emitCancel();
    },

    discardUpload () {
      this.hideUploadForm();
      this.resetUploaderDialogForm(() => this.goToIndexPage());
    },

    goToIndexPage () {
      this.hideUploadFormAndEmitClose();
    },

    emitCancel (e) {
      this.$emit('click:cancel', e);
    },

    confirmNavigateAwayOnUploading (to, from, next) {
      if (this.uploader.isDirty()) {
        this.uploader.confirmNavigateAwayOnUploading(
          () => {
            this.cancelUpload();
            next();
          },
          () => next(false),
        );
      } else {
        next();
      }
    },
  },
};
</script>
