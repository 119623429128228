<template>
  <v-card
    ref="kgCard"
    flat
    class="ov-hidden"
  >
    <wells-knowledge-graph
      v-if="tabs.isCurrentIndex('documents')" :item.sync="itemSelected"
      :width="dimensions.width" :height="dimensions.height"
    ></wells-knowledge-graph>

    <publications-knowledge-graph
      v-if="tabs.isCurrentIndex('publications')" :item.sync="itemSelected"
      :width="dimensions.width" :height="dimensions.height"
    ></publications-knowledge-graph>

    <v-layout class="kg-area__corner kg-area__corner--bottom-right">
      <slot name="kg.legend">
        <v-btn
          :title="$trans('Toggle expand')"
          min-width="32" width="32"
          min-height="32" height="32"
          class="mr-3"
          @click="expandMapArea"
        >
          <v-icon
            v-if="$store.getters['search/isMapAreaExpanded']"
            small
          >mdi-arrow-expand-right</v-icon>
          <v-icon v-else small>mdi-arrow-expand-left</v-icon>
        </v-btn>
        <v-btn height="32">
          <svg
            :width="tabs.isCurrentIndex('documents') ? 100 : 150"
            data-v-1227f3b3=""
            height="32"
          >
            <circle
              data-v-1227f3b3=""
              cx="15"
              cy="15"
              r="8"
              stroke="#FFCA28"
              stroke-width="2"
              fill="#EF6C00"
            ></circle>
            <text
              v-if="tabs.isCurrentIndex('documents')"
              data-v-1227f3b3="" x="35"
              y="20"
              font-size="13px"
            >{{ $trans('Wells') }}</text>
            <text
              v-if="tabs.isCurrentIndex('publications')"
              data-v-1227f3b3="" x="35"
              y="20"
              font-size="13px"
            >{{ $trans('Publications') }}</text>
          </svg>
        </v-btn>
      </slot>
    </v-layout>
  </v-card>
</template>

<script>
// TODO: refactor template+script code
export default {
  name: 'MapAreaKnowledgeGraphTabItem',

  model: {
    prop: 'search',
  },

  props: {
    search: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    dimensions: {
      width: 500,
      height: 420,
    },
  }),

  computed: {
    itemSelected () {
      return this.search.data;
    },

    tabs () {
      return this.search.meta.categories;
    },
  },

  mounted () {
    const containerRef = document.getElementById('search-map');

    if (containerRef) {
      this.dimensions = {
        width: containerRef.clientWidth,
        height: containerRef.clientHeight,
      };
    }
  },

  methods: {
    async expandMapArea () {
      await this.$store.dispatch('search/toggleExpandMapArea');
      await this.$store.dispatch('search/setMapAreaShown', false);
      setTimeout(() => {
        this.$nextTick(() => {
          this.$store.dispatch('search/setMapAreaShown', true);
        });
      }, 200);
    },
  },
};
</script>

<style lang="scss">
.kg-area {
  &__container {
    z-index: 1;
  }

  &__corner {
    position: absolute;
    z-index: 1;

    &--bottom-right {
      bottom: 0.75rem;
      right: 0.75rem;
    }

    &--top-center {
      display: block;
      text-align: center;
      top: 40%;
      width: 100%;
    }
  }
}
</style>
