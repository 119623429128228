<template>
  <v-row>
    <meta-tag :title="$trans('Change Password')"></meta-tag>
    <v-col
      class="mx-auto"
      cols="12"
      xl="3"
      lg="4"
      md="4"
      sm="10"
    >
      <app-brand-banner
        class="mx-auto text-center ma-4"
        width="320"
      ></app-brand-banner>

      <validation-observer ref="form:signin" v-slot="{ handleSubmit }">
        <v-form
          v-model="form.valid"
          :disabled="form.loading"
          autocomplete="off"
          method="post"
          @submit.prevent="handleSubmit(submit)"
        >
          <v-card class="mt-10">
            <v-card-title>
              <h1>{{ $trans('Change Password') }}</h1>
            </v-card-title>
            <v-card-text>
              <!-- eslint-disable-next-line max-len -->
              <p class="heading text-left">In order to protect your account, we recommend your new password:</p>
              <ul class="text-left mb-10">
                <li>Is longer than 6 characters.</li>
                <li>Is not a common password pattern.</li>
                <li>Does not match or significantly contain your username or email.</li>
              </ul>

              <validation-provider
                v-slot="{ errors }"
                name="password"
                mode="passive"
                rules="required"
              >
                <v-text-field
                  v-model="form.password"
                  :error-messages="errors"
                  :label="$trans('New Password')"
                  autocomplete="new-password"
                  autofocus
                  outlined
                  type="password"
                  name="password"
                ></v-text-field>
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="password confirmation"
                mode="passive"
                rules="required|password:@password"
              >
                <v-text-field
                  v-model="form.password_confirmation"
                  :error-messages="errors"
                  :label="$trans('Confirm New Password')"
                  autocomplete="off"
                  autofocus
                  outlined
                  type="password"
                  name="password_confirmation"
                ></v-text-field>
              </validation-provider>

              <v-btn
                color="primary"
                type="submit"
                block
                x-large
              >
                {{ $trans('Change Password') }}
              </v-btn>

              <p class="mt-6 mb-0 text-caption text-left">
                {{ $trans('Have an account?') }}&nbsp;
                <router-link
                  :to="{ name: 'login' }"
                  class="text-caption"
                >
                  {{ $trans('Sign in here.') }}
                </router-link>
              </p>
            </v-card-text>
          </v-card>
        </v-form>
      </validation-observer>

      <auth-footer></auth-footer>
    </v-col>
  </v-row>
</template>

<script>
import Auth from '@/modules/Auth/resources/Auth';

export default {
  layout: 'auth',

  data: () => ({
    form: new Auth,
  }),

  computed: {
    validator () {
      return this.$refs?.resetform;
    },
  },

  methods: {
    submit () {
      this.form.setValidator(this.validator).changePassword();
    },
  },
};
</script>
