import DataResource from '@core/resources/DataResource';
import { LIST_POST_MORTEM_WELLS_URL, FIND_POST_MORTEM_DETAILS_URL } from '@/modules/PostMortem/api/postmortem';
import { FIND_WELL_INFO_URL } from '@/modules/Dashboard/api/wells';
import { STORAGE_URL } from '@config/storage';
import {
  sortBy, isEmpty, isNil, chunk,
} from 'lodash';
import currentImages from '@/modules/PostMortem/config/currentImages';

export default class PostMortem extends DataResource {
  constructor (options = {}) {
    super(options);
  }

  async list () {
    this.startLoading();

    const params = {
      projects_list: this.getProjectIds(),
      ...this.getQueryString(),
      page_size: 10,
    };
    const { data } = await this.axios.get(LIST_POST_MORTEM_WELLS_URL, { params });

    if (!isEmpty(data.data)) {
      this.setItems(data.data);
      this.findWell(this.items?.[0].well_id);
      this.findPostMortemDetails(this.items?.[0]?.id);
      this.select(this.items?.[0]);
      this.setPagination({
        total: data.meta.pagination.count,
        page: data.meta.pagination.page,
        pageCount: data.meta.pagination.pages,
      });
    }

    this.stopLoading();
  }

  setItems (items) {
    this.items = sortBy(items.map(item => ({
      ...item,
      ...item.attributes,
      text: item.attributes.well_name,
      subtext: item.attributes.basin,
    })), o => o.text);
  }

  async findWell (id) {
    this.startLoading();

    try {
      this.disableAxiosResponseHandlers();
      if (!isNil(id)) {
        const { data } = await this.axios.get(FIND_WELL_INFO_URL(id));
        this.setMetaWell(data.data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.enableAxiosResponseHandlers();
      this.stopLoading();
    }
  }

  setMetaWell (well) {
    this.meta.well = { ...well, ...well.attributes };
  }

  async findPostMortemDetails (id) {
    if (isNil(id)) {
      return;
    }

    const { data } = await this.axios.get(FIND_POST_MORTEM_DETAILS_URL(id));

    this.setData(data.data);
    this.setPostMortemImages(this.data.post_mortem_configuration.Images);
  }

  setData (data) {
    this.data = this.observeData({ ...data, ...data.attributes });
  }

  setPostMortemImages (images, chunkCount = null) {
    const postMortemImages = currentImages.map(item => {
      const currentImages = (images[item.key] || []).map(image => ({
        ...image,
        url: `${STORAGE_URL}/${image.img_obj_name}`,
        page_num: this.getPostMortemImageJumpToPage(image.img_obj_name),
      }));

      return {
        ...item,
        hasImages: !isEmpty(currentImages),
        images: currentImages,
      };
    }).filter(item => item.hasImages);
    const postMortemImagesCount = Math.ceil(postMortemImages.length / 2);

    this.meta.currentImages = chunk(postMortemImages, chunkCount || postMortemImagesCount);
  }

  getPostMortemImageJumpToPage (item) {
    const b = item.split('/');
    const c = b[b.length - 1];
    const d = c.split('_')[0];
    const e = d.split('.');

    if (d.length >= 2) {
      return e[0];
    }

    return d;
  }

  getProjectIds () {
    return this.store.getters['sourcetray/sources']?.map(i => i.id).join();
  }
}
