<template>
  <v-alert
    outlined text
    type="error"
    class="my-8"
  >
    <div v-for="(message, i) in messages" :key="i">
      <h3 class="title mb-4" v-text="$trans(message.title)"></h3>
      <ul class="mb-3">
        <li
          v-for="(item, j) in message.items" :key="j"
          v-html="$trans(item)"
        ></li>
      </ul>
    </div>
  </v-alert>
</template>

<script>
export default {
  name: 'AlertSearchQueryEmptyResults',

  data: () => ({
    messages: [
      {
        title: "Sorry! We don't have text results for your query.",
        items: [
          'Try some terms like formation, overpressure...',
          'Click the <strong>Images</strong> tab for image results.',
          'Change the <strong>Spatial filter</strong>.',
        ],
      },
    ],
  }),
};
</script>
