import DataResource from '@core/resources/DataResource';
import priorities from '@/modules/File/config/priorities';
import reports from '@/modules/File/config/reports';
import logs from '@/modules/File/config/logs';
import phases, {
  metaInfo, hierarchy,
  APPROVED_FOR_AUDIT, PENDING_FOR_AUDIT_APPROVAL,
} from '@/modules/File/config/phases';
import { headers } from '@/modules/File/config/datalake';
import { formatFileSize } from '@core/filters/PrettyBytes';
import { statuses } from '@/modules/File/config/files';
import { details } from '@/modules/File/config/set';
import { toDateTime } from '@core/filters/PrettyDates';
import { supportedQuery } from '@/modules/File/config/queries';
import { HTTP_OK } from '@core/enums/Response';
import {
  FIND_UPLOAD_SET_URL,
  SUBMIT_FOR_APPROVAL,
  LIST_UPLOAD_SETS_URL,
  FIND_UPLOAD_SET_LOGS_URL,
  GET_SET_REPORT_URL,
} from '@/modules/File/api/uploader';
import {
  isObject, includes,
  sortBy, reverse, orderBy,
  isArray, isEmpty, size, has,
} from 'lodash';

export default class Set extends DataResource {
  constructor (options = {}) {
    super(options);

    this.setHeaders(headers);

    this.setMeta({
      supportedQuery,
      priorities,
      details,
      phases,
      logs,
      ...options.meta,
    });
  }

  async list () {
    this.startLoading();

    const params = this.getQueryString();

    if (!isEmpty(params.projects_list)) {
      const { data } = await this.axios.get(LIST_UPLOAD_SETS_URL, { params });

      this.disableDefaultSortOrder();
      this.setItems(data.data);

      this.setPagination({
        total: data.meta.pagination.count,
        page: data.meta.pagination.page,
        pageCount: data.meta.pagination.pages,
      });
    } else {
      this.unsetItems();
      this.setPagination({
        total: 0,
        page: 1,
      });
    }

    this.stopLoading();
  }

  async listManagedOnly () {
    this.startLoading();

    const params = this.getQueryStringForManageOnly();
    const { data } = await this.axios.get(LIST_UPLOAD_SETS_URL, { params });

    this.enableDefaultSortOrder();
    this.setItems(data.data);

    this.setPagination({
      total: data.meta.pagination.count,
      page: data.meta.pagination.page,
      pageCount: data.meta.pagination.pages,
    });

    this.stopLoading();
  }

  async find (id) {
    this.startLoading();

    const { data } = await this.axios.get(FIND_UPLOAD_SET_URL(id));

    this.setData(data.data);
    this.setFields(data.data);
    this.mapDetailsToData();

    this.makeReady();
    this.stopLoading();
  }

  async findWithStatus (id) {
    await this.findStatusLogs(id);
    await this.find(id);
  }

  async findStatusLogs (id) {
    this.startLoading();
    this.disableAxiosResponseHandlers();
    this.resetPhasesItems();

    try {
      const { data } = await this.axios.get(FIND_UPLOAD_SET_LOGS_URL(id));
      this.setMetaStatusLogs(data.data);
      this.setMetaPhases(data.data);
    } catch (e) {
      console.error(e?.message);
    }

    this.enableAxiosResponseHandlers();
    this.stopLoading();
  }

  getQueryStringForManageOnly () {
    return {
      ...this.getQueryString(),
      is_manager: this.store.getters['auth/user'].canManageFiles() ? 1 : 0,
    };
  }

  setItems (items) {
    const sortOrder = this.getDefaultSortOrderKeys();

    this.items = orderBy(items.map(item => ({
      ...item,
      ...item.attributes,
      title: this.trans('Set {number}', { number: item.id }),
      isSelectable: this.isPendingAuditApproval(item.attributes.status),
      isNotSelectable: !this.isPendingAuditApproval(item.attributes.status),
      status: statuses.find(item.attributes.status),
    })), sortOrder.iteratees, sortOrder.orders);

    return this;
  }

  enableDefaultSortOrder () {
    this.withDefaultSortOrder = true;
  }

  disableDefaultSortOrder () {
    this.withDefaultSortOrder = false;
  }

  getDefaultSortOrderKeys () {
    return {
      iteratees: this.withDefaultSortOrder ? [
        'isSelectable',
        this.query.order_by.replace('-', ''),
      ] : [ this.query.order_by.replace('-', '') ],
      orders: this.withDefaultSortOrder ? [
        'desc',
        this.isSortDesc(this.options.sortDesc)
          ? 'desc' : 'asc',
      ] : [ this.isSortDesc(this.options.sortDesc) ? 'desc' : 'asc' ],
    };
  }

  setQueryString (options) {
    const supportedQuery = this.parseOptionsAsSupportedQuery(options);

    this.query = {
      ...this.getQueryString(),
      ...supportedQuery,
    };

    this.query.order_by = this.prependSign(
      supportedQuery.order_by,
      this.isSortDesc(options.sortDesc),
    );

    this.pushRouteQuery();

    return this.mergeOptions(options, false);
  }

  prependSign (key, isTruthy = false) {
    key = isArray(key) ? key[0] : key;

    if (isEmpty(key)) { return ''; }

    return isTruthy ? `-${key}`.replace('--', '-') : key.replace('-', '');
  }

  isSortDesc (sortDesc = null) {
    sortDesc = sortDesc || this.options.sortDesc;

    return !isEmpty(sortDesc)
      ? sortDesc[0] === true
      : sortDesc === true;
  }

  setData (data) {
    this.data = this.observeData(this.parseFields(data));
  }

  setFields (data) {
    this.fields = this.parseFields(data);
  }

  parseFields (data) {
    return {
      ...data,
      ...data.attributes,
      title: this.trans('Set {number}', { number: data.id }),
      totalFiles: this.getTotalFilesText(data),
      usedStorage: formatFileSize(data.attributes.storage_used),
      createdBy: this.getCreatedByText(data),
      date: toDateTime(data.attributes.date_added),
      statusText: this.getStatusText(data.attributes.status),
      currentStatus: this.getCurrentStatusLog(data.attributes.status),
      priority: this.getPriorityObject(data.attributes.priority),
      downloads: this.getDownloadableReports(data),
      files: data.attributes.files.map(file => this.makeFile(file)),
    };
  }

  makeFile (file) {
    return {
      ...file,
      size: formatFileSize(file.file_size),
      status: statuses.find(file.status),
      mimeType: file.file_type,
    };
  }

  mapDetailsToData () {
    this.meta.details = details.map(detail => Object.keys(detail).map(key => ({
      meta: isObject(detail[key]) ? detail[key] : {},
      options: isObject(this.data[key]) ? this.data[key] : {},
      count: size(isObject(this.data[key]) ? this.data[key] : {}),
      key: detail[key]?.text ?? detail[key],
      value: this.data[key],
      text: this.data[key]?.text ?? this.data[key],
      permission: detail[key]?.permission,
      downloadReport: type => this.downloadReport(type),
    })).map(i => ({ ...i.meta, ...i.options, ...i })));
  }

  getTotalFilesText (data) {
    return this.transChoice(
      '{count} items ({size})', data.attributes?.total_files, {
        count: data.attributes?.total_files,
        size: formatFileSize(data.attributes.storage_used),
      },
    );
  }

  getCreatedByText (data) {
    const name = data.attributes.added_by_full_name;
    const date = toDateTime(data.attributes.date_added);
    return `${name}<br><span title="${data.attributes.date_added}">${date}</span>`;
  }

  getStatusText (status) {
    return statuses.find(status);
  }

  getPriorityObject (priority) {
    return priorities.find(priority, 'value');
  }

  getDownloadableReports ({ id, attributes }) {
    return reports.items.map(report => ({
      ...report,
      href: report.href(id),
      active: hierarchy[report.key] <= hierarchy[attributes.status],
    })).filter(i => i.active);
  }

  resetPhasesItems () {
    this.meta.phases.resetItems();
  }

  getCurrentStatusLog (status = null) {
    return this.meta.logs.find(status ?? this.data.status, 'key') ?? {};
  }

  setMetaStatusLogs (items) {
    this.meta.logs.setItems(reverse(sortBy(items, 'attributes.date_added')).map(item => {
      const phase = phases.items
        .map(i => i.statuses.map(k => ({ ...k, title: i.title }))).flat()
        .find(i => i.key === item.attributes.status);

      return {
        ...item.attributes,
        ...phase,
        hasMetaInfo: has(metaInfo, phase.key),
        metaInfo: metaInfo?.[phase.key],
        hasRemarks: item.attributes.remarks || this.data.justification,
        isSubmittedForApproval: this.isSubmittedForApproval()
          && PENDING_FOR_AUDIT_APPROVAL === phase.key,
        justification: this.data.justification,
        date: toDateTime(item.attributes.date_added, 'LL LT'),
      };
    }));
  }

  setMetaPhases (items) {
    items = sortBy(items, 'attributes.date_added');
    const itemStatuses = items.map(i => i.attributes.status);

    this.meta.phases.items = phases.items.map(phase => {
      let item = phase.statuses.filter(i => includes(itemStatuses, i.key)).pop();
      const hasItem = item;
      const statusItem = items.find(i => i.attributes.status === item?.key);

      if (!item) {
        item = phase.statuses.find(i => i.key === item);
      }

      return {
        step: phase.step,
        text: item?.text,
        color: item.color,
        title: phase.title,
        isFail: item.isFail,
        rules: [ () => !item.isFail ],
        isInProgress: item.isInProgress,
        isComplete: item.isComplete ?? false,
        date: hasItem ? toDateTime(statusItem?.attributes?.date_added, 'LL') : null,
      };
    });

    this.meta.phases.setCurrentToInProgress();
  }

  async submitForApproval (id) {
    this.startLoading();

    try {
      await this.axios.patch(
        SUBMIT_FOR_APPROVAL(id),
        this.getApprovalSubmissionFields(),
      );

      this.notify('Set was successfully submitted for Approval');

      this.router.push({
        name: 'sets.show',
        params: { id },
      });
    } catch (e) {
      this.dialog('error', {
        title: this.trans('An unexpected error occured'),
        text: e.message,
        buttons: {
          action: { text: 'Close' },
        },
      });
    }

    this.stopLoading();
  }

  async downloadReport (type) {
    const { data, status } = await this.axios.get(GET_SET_REPORT_URL(type, this.data.id));
    if (status === HTTP_OK) {
      this.store.dispatch('dialog/show', {
        illustration: () => import('@/modules/File/components/Icons/IconMailSent'),
        title: 'Report sent to inbox',
        text: [
          '<p>The report was sent to your registered email.</p>',
          `<small class="caption muted--text">${data.data}</small>`,
        ],
        buttons: { cancel: false },
      });
    }
  }

  getApprovalSubmissionFields () {
    const formData = new FormData;
    formData.append('priority', this.fields.priority?.value ?? this.fields.priority);
    formData.append('justification', this.fields.justification);

    return formData;
  }

  getStatus () {
    return this.data.status;
  }

  isPendingApproval () {
    return this.meta.phases.isPendingApproval();
  }

  isSubmittedForApproval () {
    return hierarchy[this.data.status] >= hierarchy[PENDING_FOR_AUDIT_APPROVAL];
  }

  isPendingAuditApproval (status = null) {
    return (status ?? this.data.status) === PENDING_FOR_AUDIT_APPROVAL;
  }

  isPendingAuditApprovalOrAbove (status = null) {
    return hierarchy[(status ?? this.data.status)] >= hierarchy[PENDING_FOR_AUDIT_APPROVAL];
  }

  isApprovedOrRejected () {
    return this.isReviewed();
  }

  isReviewed () {
    return hierarchy[this.data.status] >= hierarchy[APPROVED_FOR_AUDIT];
  }
}
