<template>
  <validation-provider
    v-slot="{ errors, validate }"
    name="search"
    rules="required"
    mode="passive"
  >
    <shortkey-tooltip content="/">
      <v-text-field
        ref="search"
        v-model="search"
        v-shortkey="[ 'ctrl', '/' ]"
        :label="$trans(label)"
        :placeholder="$trans(placeholder)"
        :prepend-inner-icon="loading ? 'mdi-loading mdi-spin' : 'mdi-magnify'"
        :append-icon="search ? 'mdi-close' : undefined"
        :error-messages="errors"
        outlined
        autocomplete="off"
        v-bind="$attrs"
        @click:append="clickClear"
        @keyup.enter="e => keyupEnter(e, validate)"
        @shortkey.native="focus"
      ></v-text-field>
    </shortkey-tooltip>
  </validation-provider>
</template>

<script>
import { isEmpty } from 'lodash';

export default {
  name: 'AppSearchField',

  props: {
    value: {
      type: String,
      default: null,
    },

    label: {
      type: String,
      default: 'Search',
    },

    placeholder: {
      type: String,
      default: undefined,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    search: {
      get () {
        return this.value;
      },

      set (value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    focus () {
      this.$refs.search.focus();
    },

    clickClear (e) {
      this.$emit('click:clear', e);
    },

    keyupEnter (e, validate) {
      validate();
      if (!isEmpty(this.search)) {
        this.$emit('enter:search', e);
      }
    },
  },
};
</script>
