import DataResource from '@core/resources/DataResource';
import { UPLOAD_UPLOADER_URL } from '@/modules/File/api/uploader';
import { SPACE_PROJECT_URL } from '@/modules/File/api/spaces';
import { files } from '@/modules/File/config/files';
import { HTTP_MULTI_STATUS, HTTP_OK } from '@core/enums/Response';
import { isArray, groupBy } from 'lodash';
import { isCancel } from 'axios';
import Timer from '@/modules/File/resources/Timer';

export default class Uploader extends DataResource {
  constructor (options = {}) {
    super(options);

    const fields = {
      project_id: options.fields?.project_id ?? null,
      remarks: options.fields?.remarks ?? null,
      file_upload: options.fields?.files ?? [],
    };

    this.setFields(fields);
    this.setFileSetToDefault();
    this.setData({ ...fields });

    this.setMeta(options.meta);
    this.setMetaFiles(files);
  }

  setMetaFiles (files) {
    const projects = this.store.getters['sourcetray/projects'] ?? [];
    const { permissions } = this.store.getters['auth/user'];

    files.setItemsFromProjects(projects);
    files.setItems(files.items.filter(file => file.isDivider
      ?? file.isSubheader
      ?? permissions.includes(file.value)));

    this.meta.files = files;

    return this;
  }

  setFields (fields) {
    this.fields = fields;
  }

  getFields () {
    return this.fields;
  }

  async upload () {
    try {
      Timer.start();
      this.startLoading();
      this.setFileSetToDefault();

      const response = await this.axios.post(
        UPLOAD_UPLOADER_URL,
        this.toFormData(this.getFields()),
        this.getAxiosConfig(),
      );

      if (!isCancel(response)) {
        const { data, status } = response;

        switch (status) {
        case HTTP_MULTI_STATUS:
          this.setMultiStatusMessage(data?.data);
          break;
        case HTTP_OK:
        default:
          this.setSuccessMessage(data?.data);
          break;
        }

        this.store.dispatch('file/markUploadAsDone');
      }
    } catch (e) {
      this.setErrors(e?.response?.data?.errors);
      return Promise.reject(this.getErrors());
    } finally {
      this.stopLoading();
    }

    return Promise.resolve(this);
  }

  setMultiStatusMessage (data) {
    this.setFileSet([ ...data.errors, ...data.success ]);
    this.setErrors(data.errors);
  }

  setSuccessMessage (data) {
    this.setFileSet([ ...data?.errors, ...data.success ]);
    this.setErrors(data?.errors);
    this.notifyUploadSuccess();
  }

  setFileSetToDefault () {
    const project = this.meta.files?.find(this.fields.project_id) || {};

    this.store.dispatch('file/setFileSet', {
      id: null,
      set: null,
      isDone: false,
      isUploading: true,
      files: this.fields.file_upload,
      remarks: this.fields.remarks,
      color: project.color,
      project: project.text,
      project_id: this.fields.project_id,
    });
  }

  setFileSet (data) {
    const set = groupBy(data, 'set');
    const project = this.meta.files?.find(this.fields.project_id) || {};

    this.store.dispatch('file/setFileSet', Object.keys(set).map(i => ({
      id: i,
      set: i,
      files: set[i],
      isDone: true,
      isUploading: false,
      remarks: this.fields.remarks,
      color: project.color,
      project: project.text,
      project_id: this.fields.project_id,
    })).pop());
  }

  toFormData (data) {
    const formData = new FormData;
    Object.keys(data).forEach(key => {
      const item = data[key];
      if (isArray(item)) {
        for (let i = 0; i < item.length; i++) {
          formData.append(key, item[i]);
        }
      } else {
        formData.append(key, data[key]);
      }
    });

    return formData;
  }

  getAxiosConfig () {
    this.makeCancelToken();
    this.clearProgress();

    return {
      ...this.getCancelToken(),
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 1000 * 660,
      onUploadProgress: e => {
        const { total, loaded } = e;
        const isProgressing = loaded < total;
        const progress = Math.round((loaded * 100) / total);
        const isDone = loaded >= total;
        const remaining = Timer.run(loaded, total).getRemaining();
        this.setProgress({
          isProgressing,
          remaining,
          progress,
          loaded,
          total,
          isDone,
        });
      },
    };
  }

  clearProgress () {
    this.store.dispatch('file/clearUploadProgress');
  }

  setProgress (progress) {
    this.store.dispatch('file/setUploadProgress', { isProgressing: false, ...progress });
  }

  notifyUploadFailed () {
    this.notify('An error occured while uploading', { color: 'error' });
  }

  notifyUploadSuccess () {
    this.$dialog({
      persistent: true,
      illustration: () => import('@/modules/File/components/Icons/IconTimeClock'),
      title: 'Files uploaded successfully',
      text: [
        '<p>The uploaded files are now being processed.</p>',
        '<p>This may take several hours to complete depending on file size, page count, and resource availability. After all processes are finished, you will receive an email that the extracted content are ready.</p>',
      ],
      buttons: {
        cancel: { show: false },
        action: { text: 'Done' },
      },
    });
  }

  confirmDiscardDialogPrompt (callback) {
    this.store.dispatch('dialog/show', {
      title: 'Discard Upload?',
      text: 'Are you sure you to cancel uploading the files?',
      buttons: {
        cancel: { text: 'Go Back' },
        action: {
          callback,
          text: 'Discard',
          color: 'danger',
        },
      },
    });
  }

  getUploadFormTextMessage () {
    const instances = this.store.getters['settings/instances'];
    const scheduledMaintenanceMessage = this.store.getters['settings/scheduledMaintenanceMessage'];

    return [
      (instances || [])
        ?.flatMap(i => i?.attributes?.settings?.UPLOADER_FORM
          ?.flatMap(t => t.TEXT)),
      '<br>',
      scheduledMaintenanceMessage?.flatMap(t => t.TEXT),
    ].flat();
  }

  async setSupportedFileExtensionsByProjectId (projectId) {
    const { data } = await this.axios.get(SPACE_PROJECT_URL(projectId));
    const uploaderSettings = data.data.attributes.project_settings.UPLOADER_SETTINGS;
    this.meta.supportedFileExtensions = uploaderSettings.FILES_SUPPORTED.join(',');
    this.meta.supportedFileSizeKb = this.getConfig('VUE_APP_FILE_UPLOAD_MAX_FILE_SIZE_IN_KB', 200000);
  }

  confirmNavigateAwayOnUploading (onCancel, onAction = null) {
    this.store.dispatch('dialog/show', {
      color: 'error',
      title: 'Files are still uploading',
      illustration: () => import('@/modules/File/components/Icons/IconAddFiles'),
      text: [
        '<p>Navigating away from this page will cancel the pending upload operation.</p>',
        '<p>Do you wish to cancel the upload?</p>',
      ],
      buttons: {
        cancel: {
          color: 'error',
          text: 'Cancel Upload',
          callback: store => {
            onCancel(store);
          },
        },
        action: {
          text: 'Continue Uploading',
          callback: store => {
            if (onAction) {
              onAction(store);
            }
          },
        },
      },
    });
  }
}
