<template>
  <div>
    <h2
      class="mb-3 title"
      v-text="$trans('{title} Account Information', {
        title: $app.appTitle,
      })"
    ></h2>
    <validation-observer
      ref="observer"
      v-slot="{ handleSubmit }"
      slim
    >
      <v-form
        ref="form"
        :disabled="customer.loading"
        enctype="multipart/form-data"
        autocomplete="false"
        aria-autocomplete="false"
        @submit.prevent="handleSubmit(submit)"
      >
        <validation-provider
          v-slot="{ errors }"
          vid="message"
          name="message"
        >
          <v-alert v-if="errors.length" type="error" class="mb-4">
            <div v-html="errors.join()"></div>
          </v-alert>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          vid="account_full_name"
          name="full name"
          rules="required"
        >
          <v-text-field
            v-model="customer.fields.account_full_name"
            :label="$trans('Account Name')"
            :error-messages="errors"
            outlined filled
            name="account_full_name"
            autocomplete="autocomplete_off_for_account_full_name"
            class="is-required"
          ></v-text-field>
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          vid="account_email"
          name="account email"
          rules="required|email"
        >
          <v-text-field
            v-model="customer.fields.account_email"
            :label="$trans('Account Email')"
            :error-messages="errors"
            outlined filled
            name="account_email"
            type="email"
            class="is-required"
            autocomplete="autocomplete_off_for_account_email"
            @keyup="onEmailKeyup"
          ></v-text-field>
        </validation-provider>

        <h2 v-if="withCardField" class="mb-3 title" v-text="$trans('Card Information')"></h2>
        <div v-if="withCardField">
          <icon-supported-checkout-cards width="320"></icon-supported-checkout-cards>
        </div>
        <v-stripe-card
          ref="stripeCard"
          v-model="cart.data.token"
          :class="{ 'd-none': !withCardField }"
          :api-key="cart.getPK()"
          :label="$trans('Card Information')"
          :hide-icon="false"
          hide-postal-code
          outlined
          create="token"
        ></v-stripe-card>

        <h2 class="mb-3 title" v-text="$trans('Billing Information')"></h2>
        <v-checkbox
          v-model="customer.meta.sameAsAccountEmail"
          :label="$trans('Same as {title} account email', { title: $app.appTitle })"
          single-line
          @change="onSameAccountEmailChange"
        ></v-checkbox>

        <validation-provider
          v-slot="{ errors }"
          vid="full_name"
          name="full name"
          rules="required"
        >
          <v-text-field
            v-model="customer.fields.full_name"
            :label="$trans('Account Name')"
            :error-messages="errors"
            outlined filled
            name="full_name"
            autocomplete="autocomplete_off_for_full_name"
            class="is-required"
          ></v-text-field>
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          name="email"
          rules="required|email"
        >
          <v-text-field
            v-model="customer.fields.email"
            :label="$trans('Billing email')"
            :disabled="customer.meta.sameAsAccountEmail"
            :error-messages="errors"
            outlined filled
            name="email"
            type="email"
            autocomplete="autocomplete_off_for_billing_email"
            class="is-required"
          ></v-text-field>
        </validation-provider>

        <!-- Country -->
        <validation-provider
          v-slot="{ errors }"
          name="country"
          rules="required"
        >
          <v-autocomplete
            v-model="customer.fields.country"
            :error-messages="errors"
            :items="customer.meta.countries"
            :label="$trans('Select country')"
            outlined filled
            return-object
            name="country"
            autocomplete="autocomplete_off_for_country"
            class="is-required"
            @change="onCountryChange"
          ></v-autocomplete>
        </validation-provider>
        <!-- Country -->

        <validation-provider
          v-slot="{ errors }"
          name="street 1"
          rules="required"
        >
          <v-text-field
            v-model="customer.fields.street1"
            :label="$trans('Address line 1')"
            :error-messages="errors"
            outlined filled
            name="street1"
            autocomplete="autocomplete_off_for_street1"
            class="is-required"
          ></v-text-field>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="street 2"
          rules="required"
        >
          <v-text-field
            v-model="customer.fields.street2"
            :label="$trans('Address line 2')"
            :error-messages="errors"
            outlined filled
            name="street2"
            autocomplete="autocomplete_off_for_street2"
            class="is-required"
          ></v-text-field>
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          name="city"
          rules="required"
        >
          <v-text-field
            v-model="customer.fields.city"
            :label="$trans('City')"
            :error-messages="errors"
            outlined filled
            name="city"
            autocomplete="autocomplete_off_for_city"
            class="is-required"
          ></v-text-field>
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          name="postal code"
          rules="required"
        >
          <v-text-field
            v-model="customer.fields.postal_code"
            :label="$trans('Postal Code')"
            :error-messages="errors"
            outlined filled
            name="postal_code"
            autocomplete="autocomplete_off_for_postal_code"
            class="is-required"
          ></v-text-field>
        </validation-provider>

        <!-- Province -->
        <template v-if="customer.meta.hasProvinces()">
          <validation-provider
            v-slot="{ errors }"
            name="state"
          >
            <v-autocomplete
              v-model="customer.fields.state"
              :error-messages="errors"
              :items="customer.meta.provinces"
              :label="$trans('Select province')"
              outlined filled
              return-object
              name="state"
              autocomplete="autocomplete_off_for_state"
            ></v-autocomplete>
          </validation-provider>
        </template>
        <!-- Province -->

        <validation-provider
          v-slot="{ errors }"
          name="phone"
          rules="required"
        >
          <v-text-field
            v-model="customer.fields.phone"
            :label="$trans('Contact')"
            :error-messages="errors"
            outlined filled
            name="phone"
            autocomplete="autocomplete_off_for_phone"
            class="is-required"
          ></v-text-field>
        </validation-provider>

        <v-layout class="mt-5">
          <v-spacer></v-spacer>
          <v-btn
            x-large
            depressed
            type="reset"
            class="mr-3"
            @click.prevent="onCancel"
          ><span v-text="$trans('Cancel')"></span></v-btn>
          <v-btn
            :disabled="customer.loading"
            x-large
            color="secondary"
            type="submit"
          ><span v-text="$trans(submitText)"></span></v-btn>
        </v-layout>
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
import Cart from '@/modules/Billing/resources/Cart';
import Customer from '@/modules/Billing/resources/Customer';
import VStripeCard from 'v-stripe-elements/lib/VStripeCard';

export default {
  name: 'SubscriptionUpdatePaymentForm',

  components: {
    VStripeCard,
  },

  props: {
    withCardField: {
      type: Boolean,
      default: false,
    },
    fetchUserBillingDetails: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: [ Object, Customer ],
      default: () => {},
    },
    submitText: {
      type: String,
      default: 'Update Account',
    },
  },

  data: () => ({
    cart: new Cart,
  }),

  created () {
    if (this.fetchUserBillingDetails) {
      this.customer.fetchUserBillingDetails();
    }
  },

  methods: {
    submit () {
      this.customer
        .updatePaymentDetails()
        .then(data => {
          this.onSuccess(data);
        })
        .catch(errors => {
          this.$refs.observer.setErrors(errors);
        });
    },

    onSuccess (data) {
      this.$emit('success', { data, stripe: this.$refs.stripeCard });
    },

    onCancel () {
      this.$emit('cancel');
    },

    onEmailKeyup () {
      this.customer.toggleBillingEmailSameAsAccount();
    },

    onSameAccountEmailChange () {
      this.customer.toggleBillingEmailSameAsAccount();
    },

    onCountryChange (country) {
      this.customer.setContactFromCountry(country);
    },
  },
};
</script>
