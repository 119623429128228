import UserResource from '@core/resources/UserResource';
import { UPLOADER_FEATURE_IS_MANAGER } from '@/modules/User/config/features';
import { GET_LOGGEDIN_USER_PROFILE } from '@/modules/User/api/users';
import { isArray, isEmpty, size } from 'lodash';

export default class User extends UserResource {
  constructor (options = {}) {
    super(options);
    this.displayname = options?.data?.full_name;
    this.setUserDataOrFromLocalStorage(options.data);
    this.setProjectsFromPermissions();
  }

  install (Vue) {
    Vue.prototype.$user = this;
  }

  isPermittedTo (permission) {
    const user = this.getUserFromStore();
    return user.hasFeature(permission);
  }

  setUserDataOrFromLocalStorage (data) {
    const user = data ?? this.getUserFromLocalStorage();

    if (!isEmpty(user)) {
      this.setData(data ?? this.getUserFromLocalStorage());
    }
  }

  setProjectsFromPermissions () {
    this.permissions = (this.data?.permissions ?? []).map(i => i.project);
  }

  setData (data) {
    this.data = this.observeData(this.parseData(data));
    this.avatar = this.setDefaultAvatar(this.data.full_name ?? this.data.email);
  }

  parseData (data) {
    return {
      ...data,
      currentAccountType: data.account_type?.[0],
      features: this.parseFeatures(data),
    };
  }

  parseFeatures (data) {
    const features = this.makeAdditionalFeatures(data);

    return (features ?? []).concat(data?.features?.map(i => ({
      key: i.feature__alias,
      value: i.is_active,
    })))?.filter(i => i.value)?.map(i => i.key) ?? [];
  }

  makeAdditionalFeatures (data) {
    const isManager = {
      key: 'is_manager',
      value: (data.permissions ?? []).filter(i => i.is_manager).length > 0,
    };

    return [ isManager ];
  }

  getUserFromLocalStorage () {
    return JSON.parse(localStorage.getItem('user') || '{}');
  }

  getUserFromStore () {
    return this.store.getters['auth/user'];
  }

  async getUserFromApiWithoutSaving () {
    this.disableAxiosResponseHandlers();

    try {
      const { data } = await this.axios.get(GET_LOGGEDIN_USER_PROFILE);
      let user = data.data;

      if (isArray(data.data)) {
        user = data.data.reduce(((r, c) => ({ ...r, ...c })), {});
      }

      return new User(user);
    } catch (e) {
      console.debug('User@getUserFromApiWithoutSaving', e.response);
    } finally {
      this.enableAxiosResponseHandlers();
    }

    return false;
  }

  async getUserFromApi () {
    this.disableAxiosResponseHandlers();

    try {
      const { data } = await this.axios.get(GET_LOGGEDIN_USER_PROFILE);
      let user = data.data;

      if (isArray(data.data)) {
        user = data.data.reduce(((r, c) => ({ ...r, ...c })), {});
      }

      await this.store.dispatch('auth/setUser', user);
    } catch (e) {
      await this.store.dispatch('auth/logout');
      this.router.push({ name: 'login' });
    } finally {
      this.enableAxiosResponseHandlers();
    }

    return this.getUserFromStore();
  }

  hasFeatureFrom (features) {
    return size(features.map(feature => (this.data.features ?? [])
      .includes(feature))
      .filter(i => i)) >= 1;
  }

  hasFeature (feature) {
    return (this.data.features ?? []).includes(feature);
  }

  canManageFiles () {
    return this.hasFeature(UPLOADER_FEATURE_IS_MANAGER);
  }

  getProjects () {
    const user = this.getUserFromStore();

    return (user.data.permissions ?? []).map(i => this.makeProject(i));
  }

  getManagedProjects () {
    const user = this.getUserFromStore();

    return (user.data.permissions ?? []).filter(i => i.is_manager).map(i => this.makeProject(i));
  }

  makeProject (project) {
    const sources = this.store.getters['sourcetray/sources'];

    return {
      ...sources?.find(i => i.id === project.project),
      ...project,
    };
  }

  getRedirectRouteFromEnv () {
    const homeRoute = this.getConfig('HOME_ROUTE');
    const paths = homeRoute ? homeRoute.split(',') : [];
    const route = paths
      .map(path => (path.startsWith('/') ? path : { name: path }))
      .map(path => this.router.resolve(path).resolved)
      .filter(({ name }) => !name.includes('error'))
      .filter(({ meta }) => !meta?.permissions || this.hasFeatureFrom(meta?.permissions ?? []))
      .shift();

    return route ?? { name: this.getConfig('VUE_APP_REDIRECT_ROUTENAME_AFTER_LOGIN_SUCCESS', 'profile') };
  }

  getPrimaryKey () {
    return this.data.id;
  }

  isAccountType (accountType) {
    return this.data?.currentAccountType?.name?.toLowerCase() === accountType.toLowerCase();
  }
}
