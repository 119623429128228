<template>
  <div>
    <img
      :src="$asset.getAppAsset('logos/combomark', {
        modifier: 'default.',
      })"
      :height="height"
      :width="width"
      v-bind="$attrs"
    >
  </div>
</template>

<script>
export default {
  name: 'AppBrandBanner',

  props: {
    width: {
      type: [ String, Number ],
      default: '100%',
    },
    height: {
      type: [ String, Number ],
      default: 'auto',
    },
  },
};
</script>
