<template>
  <div>
    <app-can-view code="webgis_scatter_plot">
      <v-tooltip left transition="slide-x-reverse-transition">
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <viz-scatter-plot-map-control-btn
              offset-x nudge-left
              icon="mdi-chart-scatter-plot"
              class="ma-1"
              @click:toggle="onClickToggle"
            >
              <template v-if="resource.hasErrors()">
                <v-card class="pa-5">
                  <v-card-text class="text-center muted--text">
                    <div v-html="resource.errors"></div>
                  </v-card-text>
                </v-card>
              </template>
              <template v-else>
                <viz-scatter-plot-map-control-card
                  @update:range="onRangeUpdate"
                  @click:export="onExport"
                  @change:descriptor="onDescriptorChange"
                ></viz-scatter-plot-map-control-card>
              </template>
            </viz-scatter-plot-map-control-btn>
          </div>
        </template>
        <span v-text="$trans('View Scatter Plot layer data')"></span>
      </v-tooltip>
    </app-can-view>

    <template v-if="!resource.hasErrors()">
      <viz-scatter-plot-map-control-table-card
        :resource="resource"
      ></viz-scatter-plot-map-control-table-card>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'VizScatterPlotMapControl',

  props: {
    resource: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapGetters({
      meta: 'viz/scatterplot/meta',
      isShown: 'viz/scatterplot/shown',
      isTriggered: 'webgis/spatial/isTriggered',
      highlighted: 'viz/scatterplot/highlighted',
    }),
  },

  watch: {
    'highlighted.wellId': function (wellId) {
      console.log(wellId);
      this.resource.highlightWellFromId(wellId);
    },
    isTriggered: {
      immediate: true,
      handler (isTriggered) {
        if (isTriggered) {
          this.resource.listGeoFilteredWells(this.$route.query.geo_polygon);
        }
      },
    },
    'meta.currentDescriptor': function (descriptor) {
      this.resource.unsetRange().setQueryString({ descriptor }).listMapMarkers();
    },
    isShown (isShown) {
      if (isShown) {
        this.resource.bringMapMarkersToFront();
      }
    },
  },

  mounted () {
    this.resource.listMapMarkers();
    this.resource.listSummary();
  },

  methods: {
    onRangeUpdate (range) {
      this.resource.setRange(range).listMapMarkers();
    },

    onExport (item) {
      this.resource.exportItemOrAll(item);
    },

    onDescriptorChange (data) {
      this.resource.onDescriptorChange(data);
    },

    onClickToggle () {
      this.$nextTick(() => {
        if (this.isVizCardShown) {
          this.resource.bringMapMarkersToFront();
        }
      });
    },
  },
};
</script>
