var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-source-tray-filter',{attrs:{"width":"400px"},on:{"update:selected":_vm.changedDataSource}}),_c('portal',{attrs:{"to":"header:left"}},[_c('app-search-field',{attrs:{"title":'search datalake',"loading":_vm.datalake.searching,"label":_vm.$trans('Search datalake'),"placeholder":_vm.$trans('Search datalake'),"autofocus":"","solo":""},on:{"click:clear":_vm.clearSearch,"enter:search":_vm.setSearch},model:{value:(_vm.datalake.search),callback:function ($$v) {_vm.$set(_vm.datalake, "search", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"datalake.search"}})],1),_c('v-data-table',{attrs:{"title":'search datalake publications results',"footer-props":{ 'items-per-page-options': _vm.datalake.options.rowsPerPage },"dense":_vm.datalake.meta.dense,"headers":_vm.datalake.headers,"items":_vm.datalake.items,"loading":_vm.datalake.loading,"mobile-breakpoint":_vm.datalake.meta.mobileBreakpoint,"server-items-length":_vm.datalake.pagination.total,"show-select":_vm.datalake.meta.showSelect,"options":_vm.datalake.options},on:{"update:options":[function($event){return _vm.$set(_vm.datalake, "options", $event)},_vm.updateList]},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('app-stickybar',{staticClass:"bg-white"},[_c('app-data-table-toolbar',{attrs:{"size":_vm.datalake.selectedSize()},on:{"click:clear":_vm.unselectAll}}),_c('v-spacer'),_c('v-data-footer',{staticClass:"no-border",attrs:{"pagination":pagination,"options":options,"items-per-page-options":_vm.datalake.options.rowsPerPage},on:{"update:options":updateOptions}})],1),_c('v-divider')]}},{key:"item.article_title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate text-no-wrap",staticStyle:{"max-width":"400px"}},[_c('app-can-view',{attrs:{"code":"report_viewer"},scopedSlots:_vm._u([{key:"alt",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({domProps:{"textContent":_vm._s(item.article_title)}},on))]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('View {name}', {
                  name: item.article_title,
                }))}})])]},proxy:true}],null,true)},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('router-link',{staticClass:"text-no-wrap",attrs:{"to":{ name: 'reports.single', params: { slug: item.doc_ref } }}},[_c('span',_vm._g({domProps:{"textContent":_vm._s(item.article_title)}},on))])]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('View {name}', {
                name: item.article_title,
              }))}})])],1)],1)]}},{key:"item.publication_date",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("toDateTime")(item.publication_date,'LL', 'YYYY/MM/DD')))])]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(item.publication_date)}})])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }