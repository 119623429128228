<template>
  <app-content fluid no-padding no-footer>
    <meta-tag :title="$trans('WebGIS')"></meta-tag>
    <app-back-btn :visible="hasBackUrl"></app-back-btn>

    <!-- DataSource Tray -->
    <data-source-tray-filter
      width="400px"
      @update:selected="changedDataSource"
    ></data-source-tray-filter>
    <!-- DataSource Tray -->

    <!-- Main Area -->
    <map-area
      v-model="map"
      height="calc(100vh - 64px)"
      flat zoom-bar
    >
      <template v-slot:corner.top-left>
        <app-can-view code="webgis_spatial_filter">
          <v-layout column class="my-4">
            <v-tooltip right transition="slide-x-transition">
              <template v-slot:activator="{ on }">
                <v-btn
                  min-width="32" width="32"
                  min-height="32"
                  height="32" class="ma-1"
                  v-on="on"
                  @click="filterSearchWithPolygon"
                >
                  <v-icon small>mdi-pentagon</v-icon>
                </v-btn>
              </template>
              <span
                v-if="map.isPolygonDrawHandlerEnabled()"
                v-text="$trans('Clear polygon boundary')"
              ></span>
              <span v-else v-text="$trans('Search with a polygon boundary')"></span>
            </v-tooltip>

            <v-tooltip right transition="slide-x-transition">
              <template v-slot:activator="{ on }">
                <v-btn
                  min-width="32" width="32"
                  min-height="32"
                  height="32" class="ma-1"
                  v-on="on"
                  @click="filterSearchWithRectangle"
                >
                  <v-icon small>mdi-square</v-icon>
                </v-btn>
              </template>
              <span
                v-if="map.isRectangleDrawHandlerEnabled()"
                v-text="$trans('Clear rectangular boundary')"
              ></span>
              <span v-else v-text="$trans('Search within a rectangular boundary')"></span>
            </v-tooltip>

            <v-tooltip right transition="slide-x-transition">
              <template v-slot:activator="{ on }">
                <v-btn
                  :disabled="isDeleteBtnDisabled"
                  min-width="32" width="32"
                  min-height="32"
                  height="32" class="ma-1 mt-4"
                  v-on="on"
                  @click="clearDrawFilters"
                >
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span v-text="$trans('Delete spatial filter')"></span>
            </v-tooltip>
          </v-layout>
        </app-can-view>
      </template>

      <template v-slot:corner.top-right>
        <div class="my-4 mr-4">
          <v-layout column>
            <map-measure-control-btn
              :map="map"
            ></map-measure-control-btn>
            <v-tooltip left transition="slide-x-reverse-transition">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <map-gis-layer-control-btn
                    :title="$trans('Show/hide basemaps and other overlays')"
                    offset-x nudge-left
                    class="ma-1"
                    @toggle:menu="onMapGISLayerControlToggle"
                  >
                    <map-gis-layer-control-card
                      :basemaps="map.meta.basemaps"
                      :layers="map.meta.layers"
                      width="300"
                      class="mx-2"
                    >
                      <!-- Viz Layer -->
                      <map-viz-layer-control-card></map-viz-layer-control-card>
                      <!-- Viz Layer -->
                    </map-gis-layer-control-card>
                  </map-gis-layer-control-btn>
                </div>
              </template>
              <span v-text="$trans('Toggle basemaps and other overlays')"></span>
            </v-tooltip>

            <viz-map-control-group
              v-if="map.viz"
              :viz-group="map.viz"
            ></viz-map-control-group>
          </v-layout>
        </div>
      </template>
    </map-area>
    <!-- Main Area -->
  </app-content>
</template>

<script>
import WebGIS from '@/modules/Webgis/resources/WebGIS';
import { supportedQuery } from '@/modules/Webgis/config/queries';
import { mapGetters } from 'vuex';
import { isEmpty } from 'lodash';

export default {
  layout: 'admin',

  meta: {
    permissions: [ 'webgis' ],
  },

  data: () => ({
    map: new WebGIS({
      id: 'webgis-map',
      headers: [],
      options: {
        itemsPerPage: 200,
      },
      meta: {
        basemaps: {},
        layers: {},
        dense: false,
        showSelect: true,
        mobileBreakpoint: 0,
        selectedLayerControlItems: [],
        supportedQuery,
      },
    }),
    windowSize: {
      x: 1000,
      y: 720,
    },
    items: [],
    open: [],
    caseSensitive: false,
  }),

  computed: {
    ...mapGetters({
      basemapLayers: 'webgis/basemapLayers',
      layerControlItems: 'webgis/layerControlItems',
    }),

    hasBackUrl () {
      return !isEmpty(this.$route.query.back);
    },

    selectedWellId () {
      return this.$route.query.well_id;
    },

    isDeleteBtnDisabled () {
      return !this.$store.getters['map/hasMapDrawn'];
    },
  },

  watch: {
    'map.meta.layers.current': function (items) {
      this.map.setLayerOverlays(items);
    },

    'map.meta.basemaps.current': function (basemap) {
      this.map.setBasemapLayer(basemap);
    },
  },

  created () {
    this.$store.dispatch('viz/viz/hideAllControls');
  },

  mounted () {
    this.displaySelectedLayers();
  },

  methods: {
    onResize () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },

    changedDataSource ({ query }) {
      this.map.setQueryString(query).list();
      this.map.updateVizMarkers();
    },

    displaySelectedLayers () {
      if (this.selectedWellId) {
        this.map.setSelectedLayerByWellId(this.selectedWellId);
      }
    },

    filterSearchWithPolygon () {
      this.map.togglePolygonDrawHandler();
    },

    filterSearchWithRectangle () {
      this.map.toggleRectangleDrawHandler();
    },

    clearDrawFilters () {
      this.map.clearDrawFilters();
    },

    onMapGISLayerControlToggle (isShown) {
      if (isShown) {
        this.$store.dispatch('viz/viz/hideAllControls');
      }
    },
  },
};
</script>
