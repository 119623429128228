var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-content',{attrs:{"fluid":"","no-padding":"","no-footer":""}},[_c('meta-tag',{attrs:{"title":_vm.$trans('WebGIS')}}),_c('app-back-btn',{attrs:{"visible":_vm.hasBackUrl}}),_c('data-source-tray-filter',{attrs:{"width":"400px"},on:{"update:selected":_vm.changedDataSource}}),_c('map-area',{attrs:{"height":"calc(100vh - 64px)","flat":"","zoom-bar":""},scopedSlots:_vm._u([{key:"corner.top-left",fn:function(){return [_c('app-can-view',{attrs:{"code":"webgis_spatial_filter"}},[_c('v-layout',{staticClass:"my-4",attrs:{"column":""}},[_c('v-tooltip',{attrs:{"right":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-1",attrs:{"min-width":"32","width":"32","min-height":"32","height":"32"},on:{"click":_vm.filterSearchWithPolygon}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pentagon")])],1)]}}])},[(_vm.map.isPolygonDrawHandlerEnabled())?_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Clear polygon boundary'))}}):_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Search with a polygon boundary'))}})]),_c('v-tooltip',{attrs:{"right":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-1",attrs:{"min-width":"32","width":"32","min-height":"32","height":"32"},on:{"click":_vm.filterSearchWithRectangle}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-square")])],1)]}}])},[(_vm.map.isRectangleDrawHandlerEnabled())?_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Clear rectangular boundary'))}}):_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Search within a rectangular boundary'))}})]),_c('v-tooltip',{attrs:{"right":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-1 mt-4",attrs:{"disabled":_vm.isDeleteBtnDisabled,"min-width":"32","width":"32","min-height":"32","height":"32"},on:{"click":_vm.clearDrawFilters}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Delete spatial filter'))}})])],1)],1)]},proxy:true},{key:"corner.top-right",fn:function(){return [_c('div',{staticClass:"my-4 mr-4"},[_c('v-layout',{attrs:{"column":""}},[_c('map-measure-control-btn',{attrs:{"map":_vm.map}}),_c('v-tooltip',{attrs:{"left":"","transition":"slide-x-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('map-gis-layer-control-btn',{staticClass:"ma-1",attrs:{"title":_vm.$trans('Show/hide basemaps and other overlays'),"offset-x":"","nudge-left":""},on:{"toggle:menu":_vm.onMapGISLayerControlToggle}},[_c('map-gis-layer-control-card',{staticClass:"mx-2",attrs:{"basemaps":_vm.map.meta.basemaps,"layers":_vm.map.meta.layers,"width":"300"}},[_c('map-viz-layer-control-card')],1)],1)],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Toggle basemaps and other overlays'))}})]),(_vm.map.viz)?_c('viz-map-control-group',{attrs:{"viz-group":_vm.map.viz}}):_vm._e()],1)],1)]},proxy:true}]),model:{value:(_vm.map),callback:function ($$v) {_vm.map=$$v},expression:"map"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }