<template>
  <app-content>
    <meta-tag :title="$trans('Image Explorer')"></meta-tag>

    <!-- DataSource Tray -->
    <data-source-tray-filter
      @update:selected="changedDataSource"
    ></data-source-tray-filter>
    <!-- DataSource Tray -->

    <!-- Search filter -->
    <v-row fill-height>
      <v-col cols="auto" lg="6">
        <app-search-field
          v-model="image.search"
          :title="'search images'"
          :label="$trans('Search images')"
          :loading="image.searching"
          :placeholder="$trans('Search images')"
          autofocus solo
          @click:clear="clearSearch"
          @enter:search="setSearch"
        ></app-search-field>
      </v-col>
    </v-row>
    <!-- Search filter -->

    <!-- Category tabs -->
    <buckets-tab-chip-group
      :id="'app-category-tabs'"
      v-model="image.meta.buckets.selected"
      :items="image.meta.buckets.items"
      :loading="image.loading"
      class="pb-2 pt-3"
      @click:tab="selectBucket"
    ></buckets-tab-chip-group>
    <!-- Category tabs -->

    <!-- Main display -->
    <images-data-iterator
      :dense="image.meta.dense"
      :headers="image.headers"
      :hide-default-footer="image.meta.hideDefaultFooter"
      :items-per-page="image.pagination.itemsPerPage"
      :items-per-row="image.meta.itemsPerRow"
      :items="image.items"
      :loading="image.loading"
      :mobile-breakpoint="image.meta.mobileBreakpoint"
      :options.sync="image.options"
      :server-items-length="image.pagination.total"
      :show-select="image.meta.showSelect"
      @download="downloadImage"
      @update:options="updateList"
    >
      <template v-slot:no-data>
        <template v-if="image.hasSearch()">
          <images-data-iterator-empty-state>
            <template v-slot:icon>
              <div class="secondary--text text-center mx-auto">
                <icon-landscape-image></icon-landscape-image>
              </div>
            </template>
          </images-data-iterator-empty-state>
        </template>
        <template v-else>
          <images-datalake-data-iterator-empty-state></images-datalake-data-iterator-empty-state>
        </template>
      </template>
      <template v-slot:button.link="{ item }">
        <v-btn
          :to="{
            name: 'images.single',
            params: { slug: item.id },
            query: {
              q: image.search,
              project_id: item.attributes.project_id,
              bucket: image.meta.buckets.selected,
              img_tag_1: item.attributes.img_tag_1,
            },
          }"
          exact
          dark
          small outlined
          class="mb-4"
        >
          <v-icon left small>mdi-magnify-plus-outline</v-icon>
          {{ $trans('Zoom in') }}
        </v-btn>
      </template>
      <template v-slot:footer>
        <div v-if="image.loaded" class="text-center caption mt-5">
          <span
            :title="'search images footer'"
            class="muted--text"
            v-text="$trans_choice('{count} of {total} item', image.pagination.total, {
              count: image.size(),
              total: image.pagination.total,
            })"
          ></span>
        </div>
        <v-layout>
          <v-spacer></v-spacer>
          <v-btn
            v-if="image.moreToLoad()"
            :loading="image.loading"
            :disabled="image.loading"
            large text
            class="my-5"
            :title="'load more'"
            @click="loadMoreImages"
          >
            <span v-text="$trans('Load more')"></span>&nbsp;
          </v-btn>
          <v-spacer></v-spacer>
          <fab-image-search></fab-image-search>
        </v-layout>
      </template>
    </images-data-iterator>

    <router-view></router-view>
  </app-content>
</template>

<script>
import Image from '@/modules/Image/resources/Image';
import ScrollEventHandler from '@/utilities/scroll-position';
import Paths from '@/enums/Paths';

export default {
  layout: 'admin',

  beforeRouteLeave (to, from, next) {
    this.image.cancelAllRequests();
    next();
  },

  data: () => ({
    image: new Image,
    scrollEventHandler: ScrollEventHandler,
    currentScrollPosition: 0,
    animationFrameIsTicking: false,
  }),

  async created () {
    await this.image.setQueryString(this.$route.query).listMetaBuckets();

    console.log('[images]: component created');

    // Check if scroll position was saved
    this.scrollEventHandler = new ScrollEventHandler();
    this.scrollEventHandler.pageURLPath(Paths.PATH_IMAGES);

    if (this.scrollEventHandler.getScrollPosition() > 0) {
      console.log('[images]: waiting for images to finish loading');

      const delayedScroll = () => {
        console.log('[images]: restoring previous position');
        window.scrollTo(0, this.scrollEventHandler.getScrollPosition());
      };

      // Set a timeout: need to delay scrolling to position
      // Until images finish loading
      setTimeout(delayedScroll, 3000);
    }

    // Need to register call back as a named function
    // so we can deregister when component is destroyed
    // i.e., user changes pages
    document.addEventListener('scroll', this.onScroll);
  },

  // Unregister the listener when this component is destroyed
  beforeDestroy () {
    console.log('[images]: listener unregistered');
    document.removeEventListener('scroll', this.onScroll);
  },

  destroyed () {
    console.log('[images]: component destroyed');
  },

  methods: {

    onScroll () {
      this.currentScrollPosition = window.scrollY;
      this.scrollEventHandler.setScrollPosition(this.currentScrollPosition);

      if (!this.animationFrameIsTicking) {
        window.requestAnimationFrame(() => {
          console.log('[tick]: ', window.scrollY);
          this.animationFrameIsTicking = false;
        });

        this.animationFrameIsTicking = true;
      }
    },

    changedDataSource () {
      this.image.setQueryString({ ...this.image.options, ...this.$route.query });
    },

    updateList (options) {
      this.image.setQueryString({ ...options, ...this.$route.query }).list();
    },

    selectBucket ({ item }) {
      this.image.setActiveBucketToQueryString(item).list();
    },

    setSearch (e) {
      this.image.setSearch(e.target.value).list();
    },

    clearSearch () {
      this.image.clearSearch().unsetActiveBucket().list();
    },

    downloadImage (item) {
      this.image.downloadImage(item);
    },

    loadMoreImages () {
      this.image.listMore();
    },
  },
};
</script>
