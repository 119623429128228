<template>
  <app-content>
    <meta-tag :title="$trans('Checkout')"></meta-tag>
    <app-dialogbox></app-dialogbox>

    <v-row>
      <v-col
        cols="12"
        lg="8" md="10"
        class="mx-auto"
      >
        <v-btn :to="{ name: 'billing.details' }" text>
          <v-icon left small>mdi-arrow-left</v-icon>
          <span v-text="$trans('Back')"></span>
        </v-btn>
        <h1 class="display-1 font-weight-bold mt-5 mb-10" v-text="$trans('Checkout')"></h1>

        <v-alert
          v-if="cart.isEmpty()"
          dismissible text
          type="warning"
        >
          {{ $trans('No product is selected. Please select from the list of plans available in') }}
          &nbsp;
          <app-can-view code="bolt_availability">
            <router-link
              :to="{ name:'profile.subscriptions.change' }"
              v-text="$trans('subscriptions list')"
            ></router-link>.
            <template v-slot:alt>
              <a
                href="#"
                @click.prevent="$store.dispatch('billing/openBoltAvailabilityWarningPrompt')"
                v-text="$trans('subsciptions list')"
              ></a>.
            </template>
          </app-can-view>
        </v-alert>

        <v-row>
          <v-col
            cols="12"
            lg="7" md="6"
          >
            <h2 class="mb-3" v-text="$trans('Payment Method')"></h2>
            <v-card>
              <v-card-text>
                <icon-supported-checkout-cards></icon-supported-checkout-cards>

                <v-text-field
                  v-model="cart.data.billingDetails.name"
                  :label="$trans('Card holder name')"
                  :disabled="cart.isEmpty()"
                  outlined
                  name="name"
                ></v-text-field>

                <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <v-text-field
                    v-model="cart.data.billingDetails.email"
                    :label="$trans('Billing email')"
                    :error-messages="errors"
                    outlined
                    name="email"
                    type="email"
                    class="is-required"
                  ></v-text-field>
                </validation-provider>

                <v-stripe-card
                  ref="stripeCard"
                  v-model="cart.data.token"
                  :api-key="cart.getPK()"
                  :label="$trans('Card Information')"
                  :disabled="cart.isEmpty()"
                  :hide-icon="false"
                  hide-postal-code
                  outlined
                  create="token"
                  class="is-required"
                ></v-stripe-card>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            lg="5" md="6"
          >
            <h2 class="mb-3" v-text="$trans('Order Summary')"></h2>

            <v-layout column>
              <cart-items-empty-state
                v-if="cart.isEmpty()"
              ></cart-items-empty-state>
              <template v-else>
                <v-card v-for="(item, i) in cart.items" :key="i" class="mb-4">
                  <v-card-text>
                    <div :data-id="item.id" class="text-right">
                      <div class="mb-3 title font-weight-bold" v-text="item.title"></div>
                      <div class="mb-3 font-weight-bold" v-text="item.price"></div>
                      <div class="mb-3" v-text="item.priceDetail"></div>
                    </div>
                  </v-card-text>
                </v-card>
              </template>

              <v-spacer></v-spacer>

              <v-btn
                :loading="cart.loading"
                :disabled="cart.isLoadingOr(cart.isEmpty() || !okToSubmit)"
                x-large
                block
                color="secondary"
                class="mt-5"
                @click="submit"
              ><span v-text="$trans('Subscribe')"></span></v-btn>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </app-content>
</template>

<script>
import VStripeCard from 'v-stripe-elements/lib/VStripeCard';
import Cart from '@/modules/Billing/resources/Cart';
import Billing from '@/modules/Billing/resources/Billing';
import CheckRoutePermission from '@/middleware/CheckRoutePermission';

export default {
  layout: 'web',

  meta: {
    permissions: [ 'payment' ],
  },

  middleware: [ CheckRoutePermission ],

  components: {
    VStripeCard,
  },

  data: () => ({
    billing: new Billing,
    cart: new Cart,
    okToSubmit: false,
  }),

  mounted () {
    if (this.$refs.stripeCard.card) {
      this.$refs.stripeCard.card.on('change', ({ complete }) => {
        this.okToSubmit = complete;
      });
    }
  },

  methods: {
    async submit () {
      if (this.$user.isPermittedTo('bolt_availability')) {
        this.cart.startLoading();

        await this.cart
          .setStripe(this.$refs.stripeCard.stripe)
          .confirmCardSetupIfNotExists(this.$refs.stripeCard.card);

        await this.billing.generateUpgradeCodeAndCheckout()
          .then(() => {
            this.cart
              .setStripe(this.$refs.stripeCard.stripe)
              .confirmCardPayment(this.$refs.stripeCard.card);
          }).catch(() => this.cart.stopLoading());
      } else {
        this.$store.dispatch('billing/openBoltAvailabilityWarningPrompt');
      }
    },
  },
};
</script>
