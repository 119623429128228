var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-content',[_c('meta-tag',{attrs:{"title":_vm.$trans('Change Subscription Plan')}}),_c('app-back-btn'),_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","lg":"8"}},[_c('validation-observer',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('validation-provider',{attrs:{"name":"plan","rules":"required"}},[_c('input',{staticClass:"d-none",attrs:{"name":"plan","type":"hidden"},domProps:{"value":_vm.current}})]),_c('h1',{domProps:{"textContent":_vm._s(_vm.$trans('Change Subscription Plan'))}}),_c('div',{staticClass:"my-10"},[_c('v-item-group',{model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}},_vm._l((_vm.plan.items),function(plan,i){return _c('v-item',{key:i,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('div',{staticClass:"plan__item mb-3",class:{
                    'plan__item--active': active,
                  }},[_c('v-card',{staticClass:"pa-4",attrs:{"disabled":plan.disabled},on:{"click":toggle}},[_c('v-layout',[_c('div',[_c('v-card-title',[_c('h2',{domProps:{"textContent":_vm._s(_vm.$trans_choice('billing.plan.title', plan.isCurrent, {
                              title: plan.title,
                            }))}})]),_c('v-card-text',[_c('h3',{domProps:{"innerHTML":_vm._s(plan.price)}}),_c('div',{domProps:{"innerHTML":_vm._s(plan.priceDetail)}})])],1),_c('v-spacer'),(plan.logo)?_c('div',{staticClass:"ma-4"},[_c('img',{attrs:{"src":plan.logo,"alt":plan.title,"width":"235","height":"auto"}})]):_vm._e()],1),(plan.text)?_c('v-card-text',{staticClass:"pt-0"},[_c('div',{domProps:{"innerHTML":_vm._s(plan.text)}})]):_vm._e()],1)],1)]}}],null,true)})}),1),_c('v-layout',{attrs:{"align-center":""}},[(_vm.billingInfo.isPaidSubscription)?[(_vm.billingInfo.isSubscriptionActive)?[_c('a',{staticClass:"ml-3 s-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.cancelSubscription($event)}}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Cancel subscription'))}})])]:(_vm.billingInfo.isSubscriptionForCancellation)?[_c('a',{staticClass:"ml-3 s-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.resumeSubscription($event)}}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Resume subscription'))}})])]:_vm._e()]:_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"ml-3",attrs:{"disabled":_vm.billing.isLoadingOr(invalid),"loading":_vm.billing.loading,"x-large":"","color":"secondary"},on:{"click":_vm.submit}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Continue'))}})])],2)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }