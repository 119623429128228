<template>
  <v-list v-bind="$attrs">
    <v-list-item>
      <v-list-item-content class="mx-auto">
        <img
          :key="`key_${logo}`"
          :src="logo"
          :class="{
            'app-logo__mini': mini,
            'px-8': !mini,
          }"
          width="100%"
          height="auto"
        >
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import MetaTag from '@core/mixins/MetaTag';
import logo from '@/assets/logo.png';
import logoDataAtelier from '@/assets/logo.data.atelier.svg';
import banner from '@/assets/logo.sidebar.png';
import bannerDataAtelier from '@/assets/banner.data.atelier.svg';
import { mapGetters } from 'vuex';

export default {
  mixins: [ MetaTag ],

  data: () => ({
    app: {
      logo,
      logoDataAtelier,
      banner,
      bannerDataAtelier,
    },
  }),

  computed: {
    ...mapGetters({
      mini: 'sidebar/mini',
    }),

    logo () {
      const accountType = this.$store.getters['auth/user']?.data?.currentAccountType?.name ?? 'default';

      if (this.mini) {
        return this.$asset.getAppAsset('logos/combomark', {
          modifier: 'mini.',
          ext: this.$app.isAppMode('DATA_ATELIER') ? 'svg' : 'png',
        });
      }

      return this.$asset.getAppAsset('logos/combomark', {
        modifier: `${accountType}.`,
      });
    },
  },
};
</script>

<style lang="scss">
.app-logo {
  &__mini {
    max-width: 60px;
  }
}
</style>
