import DataResource from '@core/resources/DataResource';
import {
  FIND_DATALAKE_DATA_URL,
  FIND_DATALAKE_BY_WELL_ID,
} from '@/modules/Dashboard/api/datalake';
import { SEARCH_URL } from '@/modules/Search/api/search';
import { FIND_WELL_INFO_URL, FETCH_WELL_LIST_BY_WELL_IDS } from '@/modules/Dashboard/api/wells';
import { FIND_PUBLICATION_INFO_URL } from '@/modules/Dashboard/api/publications';
import { GET_GENERAL_DASHINFO_VALUES_LIST } from '@/modules/Dashboard/api/general';
import { FIND_GEOMETRIES } from '@/modules/Dashboard/api/search';
import {
  sample, sortBy, uniq,
  size, isEmpty,
} from 'lodash';
import axios from 'axios';

export default class Datalake extends DataResource {
  constructor (data = {}) {
    super(data);

    this.meta = {
      suggestions: [], well: null, ...this.meta, ...data.meta, geometry: {},
    };
  }

  async find (id) {
    this.makeUnready();
    this.startLoading();

    const { data } = await this.axios.get(FIND_DATALAKE_DATA_URL(id));

    this.setData(data.data);

    await this.findDocuments();
    await this.findWellInfo(this.data);
    await this.findWells(this.data.wells);
    await this.findGeneralSummaryInfo();

    if (this.hasNoMetaWell()) {
      this.meta.publication = await this.findPublicationInfo(this.data.id);
    }

    await this.findRelatedDocuments(this.data);

    this.stopLoading();
    this.makeReady();
  }

  queryHasDataIndex () {
    return this.route.query.data_index;
  }

  async findDocuments () {
    this.meta.pages = this.parsePDFImagesUrl(this.data.attributes.pdf_images_url);
  }

  async findWellInfo (item) {
    if (this.hasDataWellID()) {
      const { data } = await this.axios.get(FIND_WELL_INFO_URL(item.wellId));

      this.meta.well = data.data;
      this.meta.relatedWells = { ...this.meta.relatedWells, selected: data.data };
    }
  }

  async findWells (wells) {
    if (!isEmpty(wells)) {
      const formData = new FormData;
      formData.append('well_ids', wells?.map(i => i.id).join(','));
      const { data } = await this.axios.post(FETCH_WELL_LIST_BY_WELL_IDS, formData);

      const total = data.data.length;
      this.meta.relatedWells = {
        ...this.meta.relatedWells,
        total,
        chip: total,
        chipVisible: (total - 1) > 0,
        items: data.data?.map(well => ({ ...well, ...well.attributes })),
      };
    }
  }

  async findGeneralSummaryInfo () {
    if (this.queryHasDataIndex()) {
      const { data } = await this.axios.get(GET_GENERAL_DASHINFO_VALUES_LIST, {
        params: {
          ...this.getDefaultParams(),
          data_index_id: this.route.query.data_index,
        },
      });

      if (!isEmpty(data.data)) {
        const [ item ] = data.data;
        this.meta.generalSummaryInfo = item;
      }
    }
  }

  async findPublicationInfo (docRef) {
    const params = {
      page_size: 1,
      doc_ref__id: docRef,
      projects_list: this.getProjectIDs(),
    };
    const { data } = await this.axios.get(FIND_PUBLICATION_INFO_URL, { params });
    const [ publication ] = data.data;

    return publication;
  }

  async findGeometries (id) {
    const params = {
      q: `(doc_ref:${id})`,
    };
    const { data } = await this.axios.get(FIND_GEOMETRIES, { params });

    const wells = data.data.aggregations.well_ids?.buckets.map(well => well.key);
    const basins = data.data.aggregations.basin_ids?.buckets.map(basin => basin.key);
    const countries = data.data.aggregations.country_ids?.buckets.map(country => country.key);

    this.meta.geometry.wells = wells || [];
    this.meta.geometry.basins = basins || [];
    this.meta.geometry.countries = countries || [];

    return this.meta.geometry;
  }

  async findRelatedDocuments (item) {
    if (this.hasMetaWell()) {
      await this.listByWellId(item.wellId);
      this.meta.relatedDocuments = {
        current: item,
        items: this.items,
        chip: this.items.length,
        chipVisible: (this.items.length - 1) > 0,
      };
    }
  }

  async listByWellId (wellId) {
    this.startLoading();

    const { data } = await this.axios.get(FIND_DATALAKE_BY_WELL_ID(wellId));

    this.setItems(data.data);

    this.setPagination({
      total: data.meta.pagination.count,
      page: data.meta.pagination.page,
    });

    this.stopLoading();
  }

  async searchForKeyword (keyword) {
    this.startSearching();

    const docRef = this.route.params.slug;
    const params = {
      page_size: this.meta.totalSearchResults,
      q: `${keyword} AND content_type:text AND (doc_ref:${docRef})`,
    };
    const { data } = await this.axios.get(SEARCH_URL, { params });

    this.meta.searchResults = (data.data.search_results || [])
      .map(item => ({
        text: item.attributes.doc_text_original,
        caption: item.attributes.doc_text,
        key: item.attributes.doc_ref,
        ...item.attributes,
        ...item,
      }));

    this.stopSearching();
  }

  setData (data) {
    if (!isEmpty(data)) {
      const wells = data.relationships?.well_info?.data;
      const well = wells?.find(w => w.id === this.route.query.wellId);
      const wellId = well?.id ?? wells?.[0]?.id;
      const total = data?.attributes?.page_num;

      this.data = this.observeData({
        wellId,
        total,
        wells,
        ...data,
        ...data.attributes,
      });
    }
  }

  setOptions (options = {}) {
    this.options = {
      page: options.page || 1,
      pageCount: options.pages || 0,
      total: options.total || options.count || 0,
      itemsPerPage: this.items.length || options.itemsPerPage || 10,
      rowsPerPage: uniq(sortBy([ options.itemsPerPage || 5, 5, 10, 15, 20, 100 ])),
      hideFooter: this.items.length < (options.itemsPerPage || 10),
      ...options,
    };
  }

  getRandomSuggestion () {
    return sample(this.meta.suggestions);
  }

  parsePDFImagesUrl (url) {
    const split = url.split('?');
    const pageNumber = this.data.attributes.page_num;
    const pageSize = parseInt(pageNumber, 10);
    const urlPages = [];

    for (let i = 1; i <= pageSize; i++) {
      urlPages.push({
        page: i,
        pageNum: pageNumber,
        url: `${split[0]}/${i}.png?${split[1]}`,
        ocrData: [],
        totalOCRData: 0,
      });
    }
    return urlPages;
  }

  isItemOCRDataNotYetFetched (item) {
    return !(this.meta.fetchedOCRPages || []).includes(item?.page);
  }

  async downloadItem (item = null) {
    try {
      item = item || this.data;
      const { data } = await this.axios.get(item.attributes.raw_file_url);
      window.open(data.data.raw_file_url);
    } catch ({ response }) {
      const { message } = response.data.errors;
      this.dialog('error', {
        color: 'accent',
        persistent: true,
        illustration: () => import('@/components/Icons/IconListOneRowError'),
        illustrationHeight: 200,
        title: 'Download Limit Reached',
        text: message,
      });
    }
  }

  hasMetaWell () {
    return !isEmpty(this.meta.well);
  }

  hasNoMetaWell () {
    return !this.hasMetaWell();
  }

  hasDataWellID () {
    return this.data.wellId;
  }

  getProjectIDs () {
    return this.store.getters['sourcetray/sources']?.map(i => i.id).toString();
  }

  setBboxCardText (item) {
    item.original = !item.original;
    item.text = item.original ? item.attributes.doc_text_original : item.attributes.doc_text;

    return this;
  }

  toggleOCRState () {
    this.meta.disableOCR = !this.meta.disableOCR;

    return this;
  }

  startOCRLoading () {
    this.meta.ocr.loading = true;
  }

  stopOCRLoading () {
    this.meta.ocr.loading = false;
  }

  enableOCRState () {
    this.meta.ocr.disabled = false;
  }

  disableOCRState () {
    this.meta.ocr.disabled = true;
  }

  async fetchAllOCRData () {
    this.startOCRLoading();
    this.disableOCRState();

    if (this.isOCREnabled()) {
      this.notify('Fetching OCR Data...', {
        x: 'right',
        y: 'bottom',
        timeout: 15000,
        button: { show: false },
      });

      this.meta.pages.forEach(async page => this.fetchOCRData(page));

      setTimeout(() => this.notify('Fetched all OCR Data', {
        x: 'right',
        y: 'bottom',
      }), 3000);
    }

    this.stopOCRLoading();
    this.enableOCRState();
  }

  async fetchCurrentPageOCRData () {
    const item = this.meta.pages
      .find(i => parseInt(i.page, 10) === parseInt(this.meta.currentPage, 10));

    if (!isEmpty(item)) {
      await this.fetchOCRData(item);
      this.setCurrentPage(item);
      this.setTotalOCRDataInCurrentPage(item);
    }
  }

  async fetchItemOCRData (item) {
    if (this.isOCREnabled()) {
      await this.fetchOCRData(item);
      this.setCurrentPage(item);
      this.setTotalOCRDataInCurrentPage(item);
    }
  }

  async fetchOCRData (item) {
    this.startOCRLoading();
    this.disableOCRState();

    if (this.isOCREnabled() && this.isItemOCRDataNotYetFetched(item)) {
      const i = this.meta.pages.findIndex(i => i.page === item.page);
      const len = this.meta.pages.length;
      const next = this.meta.pages[(i + 1) % len];
      const previous = this.meta.pages[(i + len - 1) % len];

      // Retrieve the neighboring items.
      [ next, previous ].forEach(item => this.findOCRData(item));

      // Retrieve and return the current item.
      const ocr = await this.findOCRData(item);
      this.meta.fetchedOCRPages.push(ocr.page);

      this.stopOCRLoading();
      this.enableOCRState();

      return ocr;
    }

    this.stopOCRLoading();
    this.enableOCRState();

    return Promise.resolve(item);
  }

  async findOCRData (item) {
    const docRef = this.route.params.slug;
    const pageNum = item.page;
    const params = {
      q: `(doc_ref:${docRef}) AND (page_num:${pageNum})`,
      bbox: true,
    };
    const { data } = await this.axios.get(SEARCH_URL, {
      params,
      ...this.getCancelToken(),
    });

    return this.setOCRData(data.data.search_results || [], item);
  }

  setOCRData (items, item) {
    return new Promise(resolve => {
      const img = new Image;
      img.onload = async () => {
        const { naturalWidth, naturalHeight } = img;

        item.ocrData = items.map(ocr => {
          const {
            height, width, x1, y1, x2, y2,
          } = ocr.attributes.bbox_parameters;
          const top = (y1 * 100) / naturalHeight;
          const left = (x1 * 100) / naturalWidth;

          let widthR = (Math.abs(x2 - x1) * 100) / naturalWidth;
          let heightR = (Math.abs(y2 - y1) * 100) / naturalHeight;

          if (width !== undefined) {
            widthR = (width * 100) / naturalWidth;
            heightR = (height * 100) / naturalHeight;
          }

          // eslint-disable-next-line no-bitwise
          const x = Math.sqrt((widthR ^ 2) + (heightR ^ 2));

          // weeding out bbox with text less than 5 chars
          const docTextOriginal = ocr.attributes.doc_text_original.trim();

          if (docTextOriginal.length >= 5) {
            return {
              x,
              left,
              top,
              naturalHeight,
              naturalWidth,
              height: heightR,
              width: widthR,
              original: false,
              text: docTextOriginal,
              isTable: ocr.attributes.img_tag_1 === 'Table',
              ...ocr,
              // TODO: add px width, height here
            };
          }

          return null;
        }).filter(i => i !== null);

        item.totalOCRData = size(item.ocrData);

        resolve(item);
      };

      img.src = item.url;
    });
  }

  setTotalOCRDataInCurrentPage ({ totalOCRData }) {
    this.meta.ocr.totalInCurrentPage = totalOCRData || '0';
  }

  setCurrentPage ({ page }) {
    this.meta.currentPage = page;
  }

  isOCREnabled () {
    return !this.isOCRDisabled();
  }

  isOCRDisabled () {
    return this.meta.disableOCR;
  }

  copyReportLinkToClipboard () {
    navigator.clipboard.writeText(window.location.href);
    this.notify('Report link copied to clipboard');
  }

  async checkHTMLSource (htmlURL, storage, slug) {
    let htmlFile;
    try {
      const fileFetchTestAxios = axios.create();
      const htmlFile = await fileFetchTestAxios.get(htmlURL);
      return true;
    } catch ({ response }) {
      return false;
    }
  }

  getDefaultParams () {
    return {
      projects_list: this.getProjectIDs(),
    };
  }
}
