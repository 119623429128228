var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-content',[_c('data-source-tray-filter',{attrs:{"width":"400px"},on:{"update:selected":_vm.changedDataSource}}),_c('quick-summary'),_c('v-row',{attrs:{"fill-height":""}},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('app-search-field',{staticClass:"mb-4",attrs:{"label":_vm.$trans('Search a well'),"placeholder":_vm.$trans('Search a well'),"loading":_vm.well.searching,"autofocus":_vm.isDesktop,"solo":""},on:{"click:clear":_vm.clearSearch,"enter:search":_vm.setSearch},model:{value:(_vm.well.search),callback:function ($$v) {_vm.$set(_vm.well, "search", $$v)},expression:"well.search"}})],1)],1),_c('v-card',[_c('app-stickybar',{staticClass:"bg-white",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var stuck = ref.stuck;
return [_c('v-layout',{staticClass:"text-no-wrap flex-no-wrap ov-x-auto",class:{ 'elevation-8': stuck }},[_c('app-data-table-toolbar',{staticClass:"flex-grow-0 flex-no-wrap",attrs:{"size":_vm.well.selectedSize(),"exportable":""},on:{"click:export":_vm.exportSelectedItems,"click:clear":_vm.unselectAll}}),_c('v-spacer'),_c('v-data-footer',{staticClass:"no-border flex-grow-1 flex-no-wrap text-no-wrap ov-x-auto",attrs:{"pagination":_vm.well.pagination,"options":_vm.well.options,"items-per-page-options":_vm.well.options.rowsPerPage},on:{"update:options":_vm.updateList}})],1)]}}])}),_c('v-divider'),_c('v-data-table',{class:{ 'v-data-table--denser': _vm.isDesktop },attrs:{"footer-props":{ 'items-per-page-options': _vm.well.options.rowsPerPage },"dense":_vm.well.meta.dense,"single-select":_vm.well.meta.singleSelect,"headers":_vm.well.headers,"items":_vm.well.items,"loading":_vm.well.loading,"mobile-breakpoint":_vm.well.meta.mobileBreakpoint,"server-items-length":_vm.well.pagination.total,"show-select":_vm.well.meta.showSelect,"options":_vm.well.options,"item-class":_vm.well.meta.itemClass},on:{"update:options":[function($event){return _vm.$set(_vm.well, "options", $event)},_vm.updateList],"click:row":_vm.onRowClick,"item-selected":_vm.toggleRowSelect,"toggle-select-all":_vm.toggleSelectAll},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"py-4",domProps:{"textContent":_vm._s(_vm.$trans('Loading items...'))}})]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"py-4",domProps:{"textContent":_vm._s(_vm.$trans('No data available'))}})]},proxy:true},_vm._l((_vm.well.headers),function(header){return {key:("header." + (header.value)),fn:function(){return [_c('span',{key:header.value,attrs:{"title":_vm.$trans(header.text)},domProps:{"textContent":_vm._s(_vm.$trans(header.text))}})]},proxy:true}}),{key:"item.well_name",fn:function(ref){
var item = ref.item;
return [_c('app-can-view',{attrs:{"code":"report_viewer"},scopedSlots:_vm._u([{key:"alt",fn:function(){return [_c('div',{staticClass:"text-no-wrap text-truncate",staticStyle:{"width":"100px"},attrs:{"title":item.well_name},domProps:{"textContent":_vm._s(item.well_name)}})]},proxy:true}],null,true)},[_c('router-link',{attrs:{"to":{
              name: 'reports.well',
              query: {
                wellId: item.id,
                noPageNum: true,
              },
            }}},[_c('div',{staticClass:"text-no-wrap text-truncate",staticStyle:{"width":"100px"},attrs:{"title":item.well_name},domProps:{"textContent":_vm._s(item.well_name)}})])],1)]}},{key:"item.basin",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap text-truncate",staticStyle:{"width":"80px"},attrs:{"title":item.basin},domProps:{"textContent":_vm._s(item.basin)}})]}},{key:"item.field_name",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap text-truncate",staticStyle:{"width":"80px"},attrs:{"title":item.field_name},domProps:{"textContent":_vm._s(item.field_name)}})]}},{key:"item.operator",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-wrap",staticStyle:{"width":"100px"},attrs:{"title":item.operator},domProps:{"textContent":_vm._s(item.operator)}})]}},{key:"item.spud",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap",domProps:{"textContent":_vm._s(item.spud)}})]}},{key:"item.tvdss",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap text-right text-truncate",staticStyle:{"width":"50px"},attrs:{"title":item.tvdss},domProps:{"textContent":_vm._s(item.tvdss)}})]}},{key:"item.water_depth",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap text-right text-truncate",staticStyle:{"width":"40px"},attrs:{"title":item.water_depth},domProps:{"textContent":_vm._s(item.water_depth)}})]}},{key:"item.kelly_bushing",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap text-right text-truncate",staticStyle:{"width":"40px"},attrs:{"title":item.kelly_bushing},domProps:{"textContent":_vm._s(item.kelly_bushing)}})]}},{key:"item.post_drill_conclusion",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-wrap text-right",staticStyle:{"width":"80px"},attrs:{"title":item.post_drill_conclusion},domProps:{"textContent":_vm._s(item.post_drill_conclusion)}})]}},{key:"item.well_type",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap text-right text-truncate",staticStyle:{"width":"80px"},attrs:{"title":item.well_type},domProps:{"textContent":_vm._s(item.well_type)}})]}}],null,true),model:{value:(_vm.well.selected),callback:function ($$v) {_vm.$set(_vm.well, "selected", $$v)},expression:"well.selected"}})],1),_c('portal',{attrs:{"to":"main:bottom"}},[_c('map-area-container',{attrs:{"resizable":""},model:{value:(_vm.webgis.open),callback:function ($$v) {_vm.$set(_vm.webgis, "open", $$v)},expression:"webgis.open"}},[_c('map-area',{attrs:{"zoom-bar":"","height":"100%"},on:{"initialized":_vm.onMapInitialized},scopedSlots:_vm._u([{key:"corner.top-right",fn:function(){return [_c('div',{staticClass:"my-4 mr-4"},[_c('v-layout',{attrs:{"column":""}},[_c('map-measure-control-btn',{attrs:{"map":_vm.webgis}}),_c('v-tooltip',{attrs:{"left":"","transition":"slide-x-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('div',_vm._g({},on),[_c('map-gis-layer-control-btn',{staticClass:"ma-1",attrs:{"title":_vm.$trans('Show/hide basemaps and other overlays'),"offset-x":"","nudge-left":""},on:{"toggle:menu":_vm.onMapGISLayerControlToggle}},[_c('map-gis-layer-control-card',{staticClass:"mx-2",attrs:{"basemaps":_vm.webgis.meta.basemaps,"layers":_vm.webgis.meta.layers,"width":"300"}})],1)],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.$trans('Toggle basemaps and other overlays'))}})])],1)],1)]},proxy:true}]),model:{value:(_vm.webgis),callback:function ($$v) {_vm.webgis=$$v},expression:"webgis"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }