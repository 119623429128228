<template>
  <v-dialog
    :value="true"
    :width="width"
    persistent
    no-click-animation
    title="dialog zoom preview"
  >
    <v-card>
      <div class="pa-1 text-right">
        <v-btn title="button zoom close" small icon @click="back"><v-icon small>mdi-close</v-icon></v-btn>
      </div>

      <v-card-text>
        <v-row>
          <v-col cols="12" lg="7" md="6">
            <v-layout justify-space-between align-center class="mb-4">
              <!-- Image title -->
              <h3 v-text="$trans('Image Preview')"></h3>
              <!-- Image title -->

              <!-- Image actions -->
              <v-sheet>
                <v-btn
                  v-if="isImageinIntuition"
                  small text
                  class="ml-2"
                  @click="goToIntuition()"
                >
                  <v-icon small left>mdi-graph-outline</v-icon>
                  <span v-text="$trans('View in Intuition')"></span>
                </v-btn>
                <v-btn
                  small text
                  class="ml-2"
                  @click="reloadImage"
                  title="button zoom reload"
                >
                  <v-icon small left>mdi-reload</v-icon>
                  <span v-text="$trans('Reload')"></span>
                </v-btn>
                <v-btn
                  small text
                  class="ml-2"
                  @click="downloadImage(image.data)"
                  title="button zoom download"
                >
                  <v-icon small left>mdi-download</v-icon>
                  <span v-text="$trans('Download')"></span>
                </v-btn>
                <v-btn
                  v-if="image.loaded"
                  :to="{
                    name: 'reports.single',
                    params: { slug: image.data.doc_ref },
                    query: {
                      page_num: image.data.page_num,
                      project_type: image.data.project_type,
                      data_index: image.data.data_index,
                      back: $route.fullPath,
                    },
                  }"
                  small text
                  class="ml-2"
                  title="button zoom goto"
                >
                  <v-icon small left>mdi-file-document-outline</v-icon>
                  <span
                    v-text="$trans('Go to page {page}', {
                      page: image.data.page_num,
                    })"
                  ></span>
                </v-btn>
              </v-sheet>
              <!-- Image actions -->
            </v-layout>

            <!-- Image Previewer -->
            <panzoom-image-previewer
              ref="panzoom-image-previewer"
              :loading="image.loading"
              :options="{
                overflow: 'auto',
                touchAction: 'pan',
                panOnlyWhenZoomed: true,
              }"
              always-zoom-from-center
              slider zoom-bar
              zoom-on-dblclick
              height="600"
              @wheel:up="onWheelUp"
              @wheel:down="onWheelDown"
            >
              <img
                v-if="image.loaded" :src="image.data.file_url"
                width="100%"
                height="auto"
              >
            </panzoom-image-previewer>
            <!-- Image Previewer -->

            <!-- Image info -->
            <v-layout justify-space-between class="mt-3">
              <span
                class="muted--text caption"
                v-text="$trans('From {name}', {
                  name: image.data.doc_title,
                })"
              ></span>
              <span
                class="muted--text caption"
                v-text="$trans('page {page}', {
                  page: image.data.page_num,
                })"
              ></span>
            </v-layout>
            <!-- Image info -->
          </v-col>
          <v-col cols="12" lg="5" md="6">
            <v-card flat class="ov-y-auto ov-x-hidden px-3" height="644">
              <images-data-iterator
                v-model="image.selected"
                :items-per-page="image.pagination.itemsPerPage"
                :items-per-row="2"
                :items="image.meta.relatedImages"
                :loading="image.loading"
                :options.sync="image.options"
                :server-items-length="image.pagination.total"
                :single-select="image.meta.singleSelect"
                height="240"
                hide-default-footer
                @download="downloadImage"
                @update:options="updateRelatedImagesList"
              >
                <template v-slot:item="{ item, selected, height }">
                  <images-data-iterator-thumbnail
                    :height="height"
                    :src="item.attributes.thumbnail_url"
                    :active="isImageActive(item, selected)"
                    role="button"
                  >
                    <template v-slot:default="{ hover }">
                      <v-layout
                        fill-height align-center
                        align-content-center
                        justify-center
                        column
                        @click="selectImage({ item })"
                      >
                        <v-overlay :value="hover" absolute></v-overlay>
                      </v-layout>
                    </template>
                    <template v-slot:append>
                      <v-layout class="caption text-truncate text--disabled">
                        <router-link
                          :title="item.attributes.doc_title"
                          :to="{
                            name: 'reports.single',
                            params: { slug: item.attributes.doc_ref },
                            query: {
                              page_num: item.attributes.page_num,
                              project_type: item.attributes.project_type,
                              data_index: item.data_index,
                              back: $route.path,
                            },
                          }"
                          class="caption text-truncate text--disabled"
                          v-text="item.attributes.doc_title"
                        ></router-link>
                        <v-spacer></v-spacer>
                        <span
                          v-text="`${$trans('Page')}: ${item.attributes.page_num}`"
                        ></span>
                      </v-layout>
                    </template>
                  </images-data-iterator-thumbnail>
                </template>

                <template v-slot:footer>
                  <div v-if="image.loaded" class="text-center caption py-5">
                    <span
                      class="muted--text"
                      v-text="$trans_choice('{count} of {total} item', image.pagination.total, {
                        count: image.pagination.itemsLength,
                        total: image.pagination.total,
                      })"
                    ></span>
                  </div>
                  <v-layout>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="image.moreToLoadRelatedImages()"
                      :disabled="image.meta.loading"
                      :loading="image.meta.loading"
                      large text
                      class="mt-3"
                      @click="loadMoreRelatedImages"
                    >
                      <span v-text="$trans('Load more')"></span>&nbsp;
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-layout>
                </template>
              </images-data-iterator>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import BackToPreviousPage from '@core/mixins/BackToPreviousPage';
import { mapGetters } from 'vuex';
import Image from '@/modules/Image/resources/Image';
import { INTUITION_MODELS_URL, INTUITION_FETCH_VECTOR } from '@/modules/Intuition/api/intuition';

export default {
  mixins: [ BackToPreviousPage ],

  layout: 'admin',

  props: {
    loading: Boolean,

    width: {
      type: [ String, Number ],
      default: 1380,
    },
  },

  data: vm => ({
    backUrl: { name: 'images', query: vm.$route.query },

    image: new Image({
      loading: true,
      options: {
        itemsPerPage: 16,
      },
      meta: {
        loading: false,
        relatedImages: [],
        singleSelect: true,
      },
    }),

    intuitionClasses: [],

    isImageinIntuition: false,
  }),

  computed: {
    ...mapGetters({
      buckets: 'image/buckets',
    }),

    imgId: {
      get () {
        return this.$slug;
      },
      set (newValue) {
        return newValue;
      },

    },

    imgClass () {
      const query = this.image.getRouteQuery();
      return query.img_tag_1;
    },
  },

  watch: {
    '$route.params': function ({ slug }) {
      this.$slug = slug;
      this.image.find(slug);
    },
  },

  created () {
    this.image.find(this.$slug);
  },

  mounted () {
    this.loadIntuitionClasses();
  },

  methods: {
    async loadIntuitionClasses () {
      const pointCloudData = await this.image.axios.get(INTUITION_MODELS_URL, {
        params: {
          projects_list: this.image.getProjectIdsUnjoined(),
          mode: 'default',
        },
      });
      this.intuitionClasses = await pointCloudData.data.data.map(model => ({
        text: model.attributes.image_class,
        chip: model.attributes.image_count,
      }));
      this.checkIntuitionClass(this.imgId);
    },

    updateRelatedImagesList (options) {
      this.image.setQueryString(options).listRelatedImagesByDocRef();
    },

    loadMoreRelatedImages () {
      this.image.listMoreRelatedImagesByDocRef();
    },

    async checkIntuitionClass (imageId) {
      const query = this.image.getRouteQuery();
      const classMatches = this.intuitionClasses.filter(i => i.text === query.img_tag_1);
      const params = {
        image_id: imageId,
        mode: 'default',
      };

      const flyVectors = await this.axios.get(INTUITION_FETCH_VECTOR, { params });

      this.isImageinIntuition = flyVectors.data.data.length > 0 && classMatches.length >= 1;

      return flyVectors.data.data.length > 0 && classMatches.length >= 1;
    },

    goToIntuition () {
      this.$router.push({
        name: 'intuition',
        query: {
          img_id: this.$slug,
          img_tag_1: this.imgClass,
          back: this.$route.fullPath,
        },
      });
    },

    async selectImage ({ item }) {
      this.$refs['panzoom-image-previewer'].setZoomToInitialLevel();
      this.$router.push({
        name: 'images.single',
        params: { slug: item.id },
        query: { ...this.$route.query, page_num: item.page_num },
      });

      this.imgId = item.id;
      await this.checkIntuitionClass(item.id);
    },

    reloadImage () {
      this.image.find(this.$slug);
      this.$refs['panzoom-image-previewer'].setZoomToInitialLevel();
    },

    downloadImage (item) {
      this.image.downloadImage(item);
    },

    isImageActive (item, selected) {
      return (this.$slug === item.id) || selected;
    },

    onWheelUp ({ event, panzoom }) {
      event.preventDefault();
      panzoom.zoomIn();
    },

    onWheelDown ({ event, panzoom }) {
      event.preventDefault();
      panzoom.zoomOut();
    },
  },
};
</script>
