var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"560px"},scopedSlots:_vm._u([{key:"activator",fn:function(props){return [_vm._t("activator",null,null,props)]}}],null,true),model:{value:(_vm.uploadFormIsShown),callback:function ($$v) {_vm.uploadFormIsShown=$$v},expression:"uploadFormIsShown"}},[_c('validation-observer',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{attrs:{"data-tour-step":"welcome/onboarding.2"}},[_c('v-card',{staticClass:"file-upload-form ov-hidden"},[_c('v-card-title',[_c('h2',{staticClass:"title",domProps:{"textContent":_vm._s(_vm.$trans('Upload files'))}})]),_c('v-card-text',{staticClass:"ov-y-auto"},[_c('file-upload-alert',{staticClass:"mb-10"}),_c('validation-provider',{attrs:{"vid":"project_id","name":_vm.$trans('project ID'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"is-required",attrs:{"disabled":_vm.uploader.meta.files.disabled,"error-messages":errors,"items":_vm.uploader.meta.files.items,"label":_vm.$trans('Select project'),"outlined":"","filled":"","name":"project_id"},on:{"change":_vm.fetchProjectSettings},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-avatar',{attrs:{"color":data.item.color,"left":"","size":"10"}}),_c('div',{staticClass:"ml-4",domProps:{"textContent":_vm._s(data.item.text)}})]}}],null,true),model:{value:(_vm.uploader.fields.project_id),callback:function ($$v) {_vm.$set(_vm.uploader.fields, "project_id", $$v)},expression:"uploader.fields.project_id"}})]}}],null,true)}),_c('validation-provider',{attrs:{"vid":"file_upload","name":_vm.$trans('files'),"rules":("required|totalSize:" + (_vm.uploader.meta.supportedFileSizeKb))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{staticClass:"mb-4 is-required",attrs:{"accept":_vm.uploader.meta.supportedFileExtensions,"disabled":_vm.hasNoSupportedFileExtension,"error-messages":errors,"hint":_vm.$trans('Maximum of {size}', {
                    size: _vm.uploader.meta.supportedFileSizeMb,
                  }),"label":_vm.$trans('Select files'),"prepend-icon":null,"counter":"","multiple":"","outlined":"","filled":"","show-size":"","persistent-hint":"","name":"file_upload","truncate-length":"20","prepend-inner-icon":"mdi-paperclip"},on:{"change":_vm.onFileChange},model:{value:(_vm.uploader.fields.file_upload),callback:function ($$v) {_vm.$set(_vm.uploader.fields, "file_upload", $$v)},expression:"uploader.fields.file_upload"}})]}}],null,true)}),_c('validation-provider',{attrs:{"vid":"remarks","name":_vm.$trans('remarks'),"rules":"required","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-textarea',{staticClass:"is-required",attrs:{"error-messages":errors,"label":_vm.$trans('Remarks / Comments'),"outlined":"","filled":"","name":"remarks"},model:{value:(_vm.uploader.fields.remarks),callback:function ($$v) {_vm.$set(_vm.uploader.fields, "remarks", $$v)},expression:"uploader.fields.remarks"}})]}}],null,true)})],1),_c('v-card-actions',{staticClass:"pt-2 pa-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","large":"","min-width":"120"},domProps:{"textContent":_vm._s(_vm.$trans('Cancel'))},on:{"click":_vm.onCancel}}),_c('v-btn',{attrs:{"loading":_vm.uploader.loading,"disabled":invalid,"depressed":"","large":"","color":"primary","min-width":"120","type":"submit"},domProps:{"textContent":_vm._s(_vm.$trans('Submit'))}})],1)],1)],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }