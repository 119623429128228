import {
  HTTP_FORBIDDEN,
  HTTP_NOT_FOUND,
  HTTP_BAD_REQUEST,
  HTTP_UNAUTHORIZED,
  HTTP_INTERNAL_SERVER_ERROR,
} from '@core/enums/Response';
import { HOME_REDIRECT } from '@/config/app';
import { isCancel } from 'axios';

export default {
  created () {
    window.axios.interceptors.response.use(response => response, err => {
      const message = (err.response && err.response.data) || err.message;
      console.error(message, err);

      if (err.response && err.response.status === HTTP_INTERNAL_SERVER_ERROR) {
        this.$store.dispatch('auth/logout');
        this.$store.dispatch('errors/set', message);
        return this.$router.replace({ name: 'error:500', params: [ this.$route.path ], replace: true });
      }

      if (err.response && err.response.status === HTTP_BAD_REQUEST) {
        return Promise.reject(err);
      }

      if (err.response && err.response.status === HTTP_UNAUTHORIZED
        && err.config && !err.config.__isRetryRequest
      ) {
        this.$store.dispatch('auth/logout');
        this.$store.dispatch('errors/set', message);

        return this.$router.replace({ name: HOME_REDIRECT, query: this.getRouterQuery() });
      }

      if (err.response && err.response.status === HTTP_NOT_FOUND) {
        return this.$router.replace({ name: 'error:404', params: [ this.$route.path ], replace: true });
      }

      if (err.response && err.response.status === HTTP_FORBIDDEN) {
        return Promise.reject(err);
      }

      if (isCancel(err)) {
        this.$store.dispatch('errors/set', message);

        return Promise.reject(err);
      }

      if (err.code === 'ECONNABORTED') {
        this.$store.dispatch('errors/set', message);
        this.showAbortedErrorDialog(err);

        return Promise.reject(err);
      }

      this.$store.dispatch('auth/logout');
      this.$store.dispatch('errors/set', message);
      this.$router.replace({ name: HOME_REDIRECT, query: this.getRouterQuery() });

      return Promise.reject(err);
    });
  },

  methods: {
    getRouterQuery () {
      return {
        redirect: this.$route.fullPath,
      };
    },

    showAbortedErrorDialog ({ config }) {
      this.$store.dispatch('dialog/error', {
        title: 'Loading in progress.',
        text: [ '', '<p>At the moment, we\'re still fetching and rendering previously fetched data.<br> If the page appears broken or incomplete, please refresh it.</p>' ],
        buttons: {
          cancel: { show: true },
          action: {
            text: 'Try again',
            callback: () => {
              this.$store.dispatch('dialog/hide');
              window.axios.request({ ...config, timeout: 90000 });
            },
          },
        },
      });
    },
  },
};
