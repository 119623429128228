<template>
  <v-data-iterator
    v-model="search.selected"
    :footer-props="{ 'items-per-page-options': search.options.rowsPerPage }"
    :items-per-page="search.pagination.itemsPerPage"
    :items="search.items"
    :loading="search.loading"
    :options.sync="search.options"
    :server-items-length="search.pagination.total"
    :single-select="search.meta.singleSelect"
    @update:page="goToAndSlideFirstItem"
    @update:options="updateList"
  >
    <template v-slot:no-results>
      <category-search-tab-item-empty-state></category-search-tab-item-empty-state>
    </template>
    <template v-slot:no-data>
      <category-search-tab-item-empty-state></category-search-tab-item-empty-state>
    </template>
    <template v-slot:loading>
      <category-search-tabs-items-loading-state
        class="mt-3"
      ></category-search-tabs-items-loading-state>
    </template>

    <template v-slot:default="{ items, isSelected, select }">
      <template v-for="(result, i) in items">
        <category-search-result-card
          :key="i"
          :active="isSelected(result)"
          :item="result"
          @click="selectItem({ select, isSelected, item: result })"
          @click:title="onResultClick"
          @click:button="onResultClick"
        ></category-search-result-card>
      </template>
    </template>
  </v-data-iterator>
</template>

<script>
import Search from '@/modules/Search/resources/Search';
import Publication from '@/modules/Search/resources/Publication';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CategorySearchPublicationsTabItem',

  props: {
    tab: {
      type: [ Object ],
      default: () => {},
    },

    item: {
      type: [ Object, Search ],
      required: true,
    },
  },

  data: vm => ({
    search: new Publication({ map: vm.item.map }),
  }),

  computed: {
    ...mapGetters({
      isSpatialButtonOn: 'search/isSpatialButtonOn',
    }),
  },

  watch: {
    'item.searching': function (isSearching) {
      if (isSearching) {
        this.setSearch(this.item.search);
      }
    },

    isSpatialButtonOn () {
      this.fetchList(this.$route.query);
    },
  },

  mounted () {
    this.showMapArea();
    this.showExportButton();
    this.showKnowledgeGraphArea();
    this.showOrHideSpatialFilterButton();
  },

  methods: {
    ...mapActions({
      showMapArea: 'search/showMapArea',
      showExportButton: 'search/showExportButton',
      showKnowledgeGraphArea: 'search/showKnowledgeGraphArea',
    }),

    showOrHideSpatialFilterButton () {
      const isShown = this.tab.showMap && this.$route.query.geo_polygon;
      this.$store.dispatch('search/setSpatialButtonShown', isShown);
    },

    async setSearch (value) {
      await this.search.setSearch(value).list();
      this.search.selectFirstItem();
      this.item.stopLoading();
      this.emitSelectedItem(this.search.getFirstItem());
    },

    parseOptions (options) {
      return {
        ...options,
        ...this.$route.query,
        page: options.page ?? this.$route.query.page,
      };
    },

    async fetchList (query) {
      if (this.search.hasSearch()) {
        this.item.startLoading();
        await this.search.setQueryString(query).list();
        this.item.stopLoading();
      }
    },

    async updateList (options) {
      await this.fetchList(this.parseOptions(options));
      this.selectAndSlideToPreviousOrFirstItem();
    },

    selectAndSlideToPreviousOrFirstItem () {
      this.search.selectAndSlideToPreviousOrFirstItem();
    },

    goToAndSlideFirstItem () {
      this.$vuetify.goTo(0);
    },

    onResultClick (item) {
      this.emitSelectedItem(item);
      this.$router.push({
        name: 'reports.single',
        params: { slug: item.docRef },
        query: {
          page_num: item.page,
          project_type: item.type,
          data_index: item.data_index,
          back: this.$route.fullPath,
        },
      });
    },

    selectItem ({ item, select }) {
      select(item, true);
      this.emitSelectedItem(item);
    },

    emitSelectedItem (item) {
      this.$emit('click:item', item);
    },
  },
};
</script>
