<template>
  <portal to="profile:stacked">
    <!-- eslint-disable max-len -->
    <v-card class="mt-6">
      <v-card-title>
        <h3 v-text="$trans('Subscription Details')"></h3>
        <v-spacer></v-spacer>
        <app-mode name="DATA_ATELIER">
          <app-can-view code="bolt_availability">
            <router-link
              :to="{ name: 'profile.subscriptions.change' }"
              class="s-link font-weight-normal"
            >{{ $trans('Change plan') }}</router-link>
            <template v-slot:alt>
              <a
                href="#"
                class="s-link font-weight-normal"
                @click.prevent="openBoltAvailabilityPrompt"
                v-text="$trans('Change plan')"
              ></a>
            </template>
          </app-can-view>
        </app-mode>
      </v-card-title>
      <v-card-text>
        <div
          v-html="$trans('Currently subscribed to <strong>{title} {type}</strong>', {
            title: $app.appTitle,
            type: billingInfo.subscriptionType || '',
          })"
        ></div>
      </v-card-text>
    </v-card>
  </portal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ProfileUpgradeCardSystemTray',

  computed: mapGetters({
    billingInfo: 'billing/billingInfo',
  }),

  methods: {
    openBoltAvailabilityPrompt () {
      this.$store.dispatch('billing/openBoltAvailabilityWarningPrompt');
    },
  },
};
</script>
