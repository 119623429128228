import { endsWith, isNil, isNumber } from 'lodash';

export function toGeoJson (data) {
  const items = [];

  if (!data) {
    return [];
  }

  for (let i = 0; i < data.length; i++) {
    const current = data[i];
    let lat = '0';
    let lng = '0';

    if (current?.attributes?.latitude?.indexOf('S') > -1) {
      lat = `-${current.attributes.latitude.replace('S', '')}`;
    } else {
      lat = current.attributes.latitude.replace('N', '');
    }

    if (current?.attributes?.latitude?.indexOf('W') > -1) {
      lng = `-${current.attributes.latitude.replace('W', '')}`;
    } else {
      lng = current.attributes.latitude.replace('E', '');
    }

    items.push({
      id: current.id,
      properties: current.attributes,
      coords: {
        lat: parseFloat(lat) || 0,
        lng: parseFloat(lng) || 0,
      },
    });
  }

  return items;
}

export function coordsToGeoJson (items) {
  return items.map(item => ({
    id: item?.id ?? item?.well_id,
    properties: item.attributes,
    coords: {
      lat: parseFloat(item.latitude) || 0,
      lng: parseFloat(item.longitude) || 0,
    },
  }));
}

export function getCoordinateFromCardinalCoordinate (coordinate, decimalPlace = 4) {
  if (Number.isNaN(coordinate) || isNil(coordinate)) {
    return '';
  }

  if (isNumber(coordinate)) {
    return coordinate;
  }

  if (coordinate?.toLowerCase() === 'n/a') {
    return '';
  }

  if (endsWith(coordinate, 'S') || endsWith(coordinate, 'W')) {
    coordinate = `-${coordinate}`;
  }

  return parseFloat(coordinate.replace(/\w$/, '')).toFixed(decimalPlace);
}

export default {
  toGeoJson,
  coordsToGeoJson,
  getCoordinateFromCardinalCoordinate,
};
