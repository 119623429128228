import Config from '@/config/server';


if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
  Config.VUE_APP_TITLE = process.env.VUE_APP_TITLE;
  Config.VUE_APP_SHORT_NAME = process.env.VUE_APP_SHORT_NAME;
  Config.VUE_APP_VERSION = process.env.VUE_APP_VERSION;
  Config.VUE_APP_MODE = process.env.VUE_APP_MODE;
}

const CURRENT_YEAR = (new Date()).getFullYear().toString();
const YEAR = process.env.VUE_APP_YEAR || CURRENT_YEAR;
const VERSION = Config.VUE_APP_VERSION || process.env.VUE_APP_VERSION;
const SHORT_NAME = Config.VUE_APP_SHORT_NAME || process.env.VUE_APP_SHORT_NAME;
const COPYRIGHT_YEAR = YEAR >= CURRENT_YEAR ? YEAR : `${YEAR} - ${CURRENT_YEAR}`;
const TITLE = Config.VUE_APP_TITLE || process.env.VUE_APP_TITLE;
const MODE = Config.VUE_APP_MODE || process.env.VUE_APP_MODE;

export default Object.freeze({
  YEAR,
  MODE,
  TITLE,
  VERSION,
  SHORT_NAME,
  CURRENT_YEAR,
  COPYRIGHT_YEAR,
});
